import React from "react";
import { Bar } from "react-chartjs-2";
import "./Moneyinsights.css";
import MonryInsightCategoryPopOver from "./MonryInsightCategoryPopOver";
import { categoryBackgroundCss, categoryColorForAll } from "../../utilities/Statics";
import { formatValue } from "../../utilities/helper";

const BarChartSection = ({
  activeTab,
  transaction,
  togglePopover,
  expenseCategories,
  incomeCategories,
  popoverOpen,
  data,
  options,
  selectedCategories,
  setSelectedCategories,
  handleTagClick,
  type,
  totalAmount = 0,
  selectedBarTitle
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  let displayAmount = totalAmount
  const handleCategories = (selectedCategories) => {

      const selectedCategoriesData = type === "expense"
      ? expenseCategories.find((e) => e.userCategoryId === selectedCategories[0])
      : incomeCategories.find((e) => e.userCategoryId === selectedCategories[0]);

      if (!selectedCategoriesData) {
        return null;
      }
      const { name, colour, emoji } = selectedCategoriesData;
  
      return (
        <>
          {name !== "Uncategorised" && (
            <div
              className={`icon-transaction-img ${
                isHexColor(colour) ? "" : categoryBackgroundCss(colour)
              }`}
              style={{
                background: categoryColorForAll(colour),
              }}
            >
              {emoji}
            </div>
          )}
          <div className="content">
            <h6 className="icon-content">{name}</h6>
          </div>
        </>
      );
  
  };
  return (
    <div
      className="chart-container mt-2"
      style={{
        height: "470px",
        width: "100%",
      }}
    >
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>
          {selectedBarTitle}
          </h2>
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: 500
          }}
        >
          {activeTab === "Expenses" ? "Expenses:" : "Income:"}{" "}
          <span
            style={{
              color: activeTab === "Expenses" ? "#FF0A0A" : "#469B88",
            }}
          >
            ${formatValue(displayAmount)}
          </span>
        </div>
      </div>
      <div className="row mt-2">
        <div className="button-blck">
          <div
            className="icon-tag"
            id={`Popover-${transaction.transactionUuid}`} // Add id for Popover target
            onClick={() => togglePopover(type)}
          >
            {selectedCategories.length === 0
              ? (
              <div className="all-categorie-title ">All categories</div>
              )
              : handleCategories(selectedCategories)}
          </div>
          <div className="categorie-count">
          {selectedCategories.length <= 1 
            ? '' 
            : `+ ${selectedCategories.length - 1} other${selectedCategories.length - 1 > 1 ? 's' : ''}`}
          </div>
        </div>

        <MonryInsightCategoryPopOver
          categories={
            activeTab === "Expenses" ? expenseCategories : incomeCategories
          }
          transactionId={transaction.transactionUuid}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          transactionType={activeTab === "Expenses" ? "Expense" : "Income"}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          handleTagClick={handleTagClick}
          type={type}
        />
      </div>
      {/* Chart goes here */}
      <div
        className="bar-chart"
        style={{
          height: "355px",
          width: "100%",
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartSection;