import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Refresh } from "../../assests/images/refresh.svg";
import { ReactComponent as Akahu } from "../../assests/images/icons/akahuIcon.svg";
import { mapAkahuAccountsData } from "../../utilities/helper.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import ConnectAkahuModal from "../../ModalForm/Popup/ConnectAkahuModal.jsx";
import PopupManageConnection from "../../ModalForm/Popup/PopupManageConnection.jsx";
import PopupRevokeConnections from "../../ModalForm/Popup/PopupRevokeConnections.jsx";
import weburls from "../../Weburls/weburls.jsx";
import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import {
  iconCategories,
  moduleInfoPopUpData,
} from "../../utilities/Statics/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import { BankConnectionList } from "../../Components/BankConnectionList/index.jsx";
import { planType } from "../../utilities/helper.jsx";
import {
  PopUpDeleteCutomAll,
  PopUpWhoops,
  PopUpWhoopsAccount,
} from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";

const Account = () => {
  const [data, setData] = useState([]);
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [trnasLoad, setTrnasLoad] = useState(() => {
    return JSON.parse(localStorage.getItem("trnas-load"));
  });
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [oneTimeOTP, setOneTimeOTP] = useState();
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [manageConnectionModalData, setManageConnectionModalData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [accountSingleId, setAccountSingleId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [whoopsPopUp, setWhoopsPopUp] = useState(false);
  const [whoopsAccountPopUp, setWhoopsAccountPopUp] = useState(false);
  const [plan, setPlan] = useState("");
  const navigate = useNavigate();
  const [planDetails, setPlanDetails] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.username;
  let planNameForUrl = "";
  const [modals, setModals] = useState({
    Otp: false,
    manageConnections: false,
    revokeModal: false,
  });

  const fetchedPlanData = async () => {
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Get_Plan_Details, body, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setPlanDetails(response.data);
      if (response.data) {
        const planName = planType(response.data.planType);
        setPlan(planName);
        planNameForUrl = planName;
      }
    } catch (error) {
      console.error("Error fetching plan details:", error);
    }
  };

  useEffect(() => {
    oneTimeLogin();
  }, []);

  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const handleAkahuModal = () => {
    setIsOpen(!isOpen);
  };

  const handleAccountDisconnect = async (val) => {
    if (!Array.isArray(val.accounts)) {
      console.error("Invalid input: val should be an array.");
      return;
    } else {
      try {
        for (const account of val.accounts) {
          await revokeSingleAccountIntoLoop(account.akahuAccountId);
        }
        await fetchCashAccount();
      } catch (error) {
        console.error(
          "Error occurred during account disconnection process:",
          error
        );
      }
    }
  };

  const upgradePlan = () => {
    window.open(
      `https://account.budgetbuddie.com/manageAccountLogin?userToken=${oneTimeOTP}&userUuid=${uuid}`,
      "_blank"
    );
  };

  const openModal = (modal, data) => {
    setModals({ ...modals, [modal]: true });
    setManageConnectionModalData(data);
  };

  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);
    await fetchedPlanData();
    try {
      const response = await axios.get(`${weburls.Get_Aku_Accounts}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      if (response.data.code === "0") {
        setData([]);
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data) {
        const mappedData = mapAkahuAccountsData(response?.data?.banks);
        const bankCount = mappedData.length ?? 0;
        if (
          (planNameForUrl === "Standard" && bankCount > 5) ||
          (planNameForUrl === "Basic" && bankCount > 2)
          // (planNameForUrl === "Pro" && bankCount > 2)
        ) {
          setData(mappedData);
          setWhoopsAccountPopUp(true);
        } else {
          setWhoopsAccountPopUp(false);
          setData(mappedData);
        }

        setLoading(false);
      }
    } catch (error) {
      setData([]);
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const oneTimeLogin = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(weburls.OneTime_Login + `${uuid}`);
    if (response?.data) {
      setOneTimeOTP(response?.data.oneTimeToken);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  const revokeAllConnections = async () => {
    try {
      const response = await axios.put(
        `${weburls.Revoke_Akahu_Accounts}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.code === 1) {
        await fetchCashAccount();
        toast.success(
          response?.data?.message ||
            "All Akahu accounts have been revoked sucessfully",
          {
            position: "top-right",
            autoClose: 2000,
          }
        );
        window.location.reload();
      }
      if (response.data.code === 0) {
        toast.error(response?.data?.message || "No Role Found", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      setModals({ ...modals, revokeModal: false });
      fetchCashAccount();
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const refreshAllConnections = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${weburls.Refresh_All_Account}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.code === 1) {
        setLoading(false);
        toast.success(
          response?.data?.message || "All Akahu accounts refresh sucessfully",
          {
            position: "top-right",
            autoClose: 2000,
          }
        );
      }
      if (response.data.code === 0) {
        toast.error(response?.data?.message || "Something went wrong", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchCashAccount();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const revokeSingleAccount = async (akahuAccountId, akahuAccountName) => {
    try {
      const response = await axios.put(
        `${weburls.Revoke_Single_Account}${uuid}/${akahuAccountId}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      fetchCashAccount();
      if (response.data.code === 1) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      setModals({ ...modals, manageConnections: false });

      // let filteredArray = data.filter(obj =>
      //   obj.akahuAccountName == akahuAccountName);
      // setManageConnectionModalData(filteredArray[0].accounts)
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const revokeConfirmButton = () => {
    setShowDeleteModal(!showDeleteModal);
    revokeSingleAccount(accountSingleId);
  };

  const revokeAccountButton = (akahuAccountId, akahuAccountName) => {
    setShowDeleteModal(true);
    setAccountSingleId(akahuAccountId);
  };

  const revokeSingleAccountIntoLoop = async (akahuAccountId) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${weburls.Revoke_Single_Account}${uuid}/${akahuAccountId}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedTrnasLoad = JSON.parse(localStorage.getItem("trnas-load"));
      setTrnasLoad(updatedTrnasLoad);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const firstFiveTransactionLoadAPI = useCallback(async () => {
    toast.info("Loading your transactions, please wait...", {
      position: "top-right",
      autoClose: false,
      toastId: "transactionLoading",
    });
    setLoading(true);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(3000);
    try {
      const responseData = await axios.get(
        `${weburls.Akahu_First_Five_Day_Transactions}${uuid}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (responseData.data.code === 1) {
        localStorage.setItem("startDate", responseData.data.startDate);
        localStorage.setItem("trnas-load", false);
        setTrnasLoad(false);
        toast.update("transactionLoading", {
          render: "Transactions loaded successfully!",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.update("transactionLoading", {
        render: "Failed to load transactions. Please try again.",
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    if (trnasLoad) {
      firstFiveTransactionLoadAPI();
    }
  }, [trnasLoad]);

  return (
    <>
      <div className="app-main__outer connect-your-accounts-main-wrapper">
        <div className="row">
          <PageTitle
            title={"Connect your accounts"}
            description={
              "Lets get your accounts connected so we can crunch the numbers for you."
            }
          />
          <UserImageInfoToggle
            isPopupOpenInfo={isPopupOpenInfo}
            togglePopupInfo={togglePopupInfo}
            title={moduleInfoPopUpData.connectAccounts.title}
            description={moduleInfoPopUpData.connectAccounts.description}
          />
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 connect-bank-wrp connect-your-account-bank-connection bnk-connection">
            <SectionTitle title={"Add a bank connection"} />
            <BankConnectionList
              connections={iconCategories.bankConnections}
              onIconClick={handleAkahuModal}
            />
            <div className="mt-4">
              <SectionTitle title={"Add a Kiwisaver connection"} />
              <BankConnectionList
                connections={iconCategories.kiwiSaverConnections}
                onIconClick={handleAkahuModal}
              />
            </div>
            <div className="inves-flex">
              <div className="add-an-investment-conn-wrapper">
                <SectionTitle title={"Add an investment connection"} />
                <BankConnectionList
                  connections={iconCategories.addInvestmentConnections}
                  onIconClick={handleAkahuModal}
                />
              </div>
              <div className="add-an-investment-conn-wrapper">
                <SectionTitle title={"Other Connections"} />
                <BankConnectionList
                  connections={iconCategories.otherConnections}
                  onIconClick={handleAkahuModal}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4 connect-account-akahu-box-wrp">
            <div className="akahu-box">
              <div className="d-flex align-items-center mb-3 akahu-gap">
                <div className="bank-connection-img">
                  <Akahu />
                </div>
                <div className="bold who-akahu">Who is Akahu?</div>
              </div>
              <div className="setting_text_color mt-2">
                Akahu is New Zealand’s open finance provider.
              </div>
              <div className="setting_text_color mt-4">
                Akahu makes it simple to securely access your financial
                information and to provide that data with platforms like
                BudgetBuddie.
              </div>
              <div className="setting_text_color mt-3">
                Akahu uses 256-bit bank-grade encryption to keep your data safe
                and secure.
              </div>
              <div className="setting_text_color mt-3">
                With your consent, the secure connection with Akahu will pull
                through your bank balances, transactional information, and
                account details.
              </div>
              <div className="setting_text_color mt-3">
                You’ll be redirected to Akahu’s website to establish the
                connection.
              </div>
              <div className="setting_text_color mt-3">
                To connect your accounts, tap the below button.
              </div>
              <div className="flex-content-center" onClick={handleAkahuModal}>
                <div className="inves-btn text-white d-flex align-items-center justify-content-center text-center fs-15 fw-500">
                  Manage your connection
                </div>
              </div>
            </div>

            <div className="live-connections-box live-connection-main-wrapper">
              <div className="d-flex justify-content-between">
                <div className="black account-live fs-20 fw-500">Live connections</div>
                <div></div>
              </div>

              {data?.length > 0 &&
                data?.map((it) => (
                  <div
                    className="merchant-box"
                    onClick={() => openModal("manageConnections", it?.accounts)}
                  >
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <div
                        className="investment-connection-img"
                        style={{ width: "40px" }}
                      >
                        <img
                          src={it?.accounts[0]?.img}
                          alt=""
                          style={{
                            objectFit: "contain",
                            width: "49px",
                            borderRadius: "100%",
                          }}
                        />
                      </div>
                      <div className="ms-3 lcs-inner">
                        <div className="live-connection">
                          {it?.akahuAccountName}
                        </div>
                        <div className="setting_text_color-2">
                          Updated:{" "}
                          {moment(it?.accounts[0]?.refreshDate)
                            .utcOffset(+1)
                            .format("DD/MM/YYYY hh:mm A")}
                        </div>
                      </div>
                    </div>
                    <div className="account-number">{`${
                      it?.accounts[0]?.status === "ACTIVE"
                        ? "Active"
                        : "Inactive"
                    }`}</div>
                  </div>
                ))}
            </div>
            <div className="right-section re-sync-connection-section w-100 mt-4">
              <div className="ms-0">
                <button
                  className="comn-btn account-btn-text d-flex align-items-center fs-15 fw-500"
                  onClick={refreshAllConnections}
                >
                  Refresh connections
                  <Refresh />
                </button>
              </div>
            </div>
            <div
              className="revoke-con"
              onClick={() => openModal("revokeModal")}
            >
              Revoke all connections
            </div>
          </div>
        </div>
      </div>
      {whoopsAccountPopUp && (
        <PopUpWhoopsAccount
          plan={plan}
          data={data}
          handleAccountDisconnect={handleAccountDisconnect}
          upgradePlan={upgradePlan}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
      {isOpen && (
        <ConnectAkahuModal
          modalOpen={isOpen}
          closeModal={handleAkahuModal}
          plan={plan}
          setWhoopsPopUp={setWhoopsPopUp}
        />
      )}
      {modals.manageConnections && (
        <PopupManageConnection
          modalOpen={modals.manageConnections}
          closeModal={closeModal}
          data={manageConnectionModalData}
          revokeConnection={revokeAccountButton}
        />
      )}
      {modals.revokeModal && (
        <PopupRevokeConnections
          isOpen={modals.revokeModal}
          onClose={closeModal}
          handleRevokeConnections={revokeAllConnections}
        />
      )}
      {whoopsPopUp && (
        <PopUpWhoops
          planDetails={planDetails}
          oneTimeOTP={oneTimeOTP}
          onClose={() => {
            setWhoopsPopUp(false);
            navigate(`/budget/${uuid}`);
          }}
          uuid={uuid}
        />
      )}
      {showDeleteModal && (
        <PopUpDeleteCutomAll
          handleDeleteConfirm={revokeConfirmButton}
          title={"Are you sure?"}
          subTitle={"This cannot be un-done."}
          deleteButtonName={"Revoke account"}
          handleDeleteCancel={() => setShowDeleteModal(!showDeleteModal)}
        />
      )}
    </>
  );
};
export default Account;
