import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";

const PopupModalDialog = ({
  isOpen,
  onClose,
  options,
  selectedOption,
  handleOptionClick,
}) => {
  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Handle option selection
  const handleCustomDateClick = (option) => {
    if (option === "Custom date range") {
      setIsCustomDateOpen(true);
    } else {
      handleOptionClick(option);
    }
  };

  // Handle date selection from date picker
  const handleDateSelect = (ranges) => {
    console.log("dateRange", ranges);

    setDateRange([ranges.selection]);
  };

  // Handle manual date input change
  const handleInputChange = (e, type) => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate)) {
      setDateRange((prev) => [
        {
          ...prev[0],
          [type]: newDate,
        },
      ]);
    }
  };

  // Apply selected date range
  const handleApply = () => {
    const formattedRange = `${formatDateWithSuffix(dateRange[0].startDate)} - ${formatDateWithSuffix(dateRange[0].endDate)}`;

    handleOptionClick(
      "Custom date range",
      formattedRange,
      dateRange[0].startDate,
      dateRange[0].endDate
    );
    setIsCustomDateOpen(false);
  };

  const formatDateWithSuffix = (date) => {
    const day = format(date, "d");
    const month = format(date, "MMM");
  
    let suffix = "th";
    if (day.endsWith("1") && day !== "11") suffix = "st";
    else if (day.endsWith("2") && day !== "12") suffix = "nd";
    else if (day.endsWith("3") && day !== "13") suffix = "rd";
  
    return `${day}${suffix} ${month}`;
  };
console.log('Custom date range', selectedOption);

  return (
    <>
      <div className={`modal-background ${isOpen ? "open-modal" : ""}`}>
        {isCustomDateOpen ? (
          <div className="modal-background open-modal">
            <div className="modal-content-calendar">
              <DateRange
                ranges={dateRange}
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={false}
                rangeColors={["#469B88"]}
                showPreview={false}
                showDateDisplay={false}
                className="custom-calendar-size"
              />
              <div className="button-container">
                <button
                  className="text-button"
                  onClick={() => setIsCustomDateOpen(false)}
                >
                  Cancel
                </button>
                <button className="text-button" onClick={handleApply}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-content add-rule-width-bottom" >
            <button onClick={onClose} className="close-pass-button">
              <CrossIcon />
            </button>
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                <span className="bold black password-heading">Date Range</span>
              </div>
            </div>
            <div>
              <div className="bold black mb-2" style={{ fontSize: "14px" }}>
                Periods
              </div>
              <div
                className="group-btn green-btn popup-padding-zero"
                style={{ width: "100%" }}
              >
                {options.length > 0 &&
                  options.map((option) => (
                    <div key={option} className="button-blck">
                      <a
                        className="group-button"
                        onClick={() => handleCustomDateClick(option)}
                        style={{
                          backgroundColor:
                            (selectedOption.includes('-') ? "Custom date range": selectedOption) === option ? "#469B88" : "",
                          color: (selectedOption.includes('-') ? "Custom date range": selectedOption) === option ? "white" : "black",
                          border: "1px solid #E1E1E1",
                          margin: "4px 4px 4px 0px",
                        }}
                      >
                        {option}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PopupModalDialog;
