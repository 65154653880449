import React from "react";
import { Bar } from "react-chartjs-2";
import "./Moneyinsights.css";
import { formatValue } from "../../utilities/helper";
import MerchantListPopover from "./MerchantListPopover";

const MerchantBarChartSection = ({
  togglePopover,
  merchatsList,
  popoverOpen,
  data,
  options,
  selectedCategories,
  setSelectedCategories,
  handleTagClick,
  totalAmount = 0,
  selectedBarTitle,
}) => {
  let displayAmount = totalAmount;

  const handleCategories = (selectedCategories) => {
    const selectedCategoriesData = merchatsList.find(
      (e) => e.id === selectedCategories[0]
    );

    if (!selectedCategoriesData) {
      return null;
    }
    const { name, logo } = selectedCategoriesData;

    return (
      <>
        {
          <div className="img-box-merchant-small">
            <img src={logo} alt="" style={{ borderRadius: "50%" }} />
          </div>
        }
        <div className="content">
          <h6 className="icon-content">{name}</h6>
        </div>
      </>
    );
  };

  return (
    <div
      className="chart-container mt-2"
      style={{
        height: "470px",
        width: "100%",
      }}
    >
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ margin: 0 }}>
            {selectedBarTitle}
          </h2>
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          <span
            style={{
              color: "#FF0A0A",
            }}
          >
            ${formatValue(displayAmount)}
          </span>
        </div>
      </div>
      <div className="row mt-2">
        <div className="button-blck">
          <div
            className="icon-tag"
            id={`Popover-`}
            onClick={() => togglePopover()}
          >
            {selectedCategories.length === 0 ? (
              <div className="all-categorie-title ">All merchants</div>
            ) : (
              handleCategories(selectedCategories)
            )}
          </div>
          <div className="categorie-count">
            {selectedCategories.length <= 1
              ? ""
              : `+ ${selectedCategories.length - 1} other${
                  selectedCategories.length - 1 > 1 ? "s" : ""
                }`}
          </div>
        </div>
        <MerchantListPopover
          merchatsList={merchatsList}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          handleTagClick={handleTagClick}
        />
      </div>
      <div
        className="bar-chart"
        style={{
          height: "355px",
          width: "100%",
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default MerchantBarChartSection;
