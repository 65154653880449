import { useState } from "react";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";

const MerchantListPopover = ({
  merchatsList,
  togglePopover,
  popoverOpen,
  selectedCategories,
  setSelectedCategories,
  handleTagClick,
}) => {
  const [merchantSearch, setMerchantSearch] = useState("");
  const [searchMerchatsList, setSearchMerchatsList] = useState(merchatsList);

  return (
    <div className={`modal-background ${popoverOpen ? "open-modal" : ""}`}>
      <div
        className="modal-content"
        style={{
          width: "900px",
          height: "470px",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <div className="transaction-categories">
          <div
            className="bold popover-live"
            style={{
              paddingBottom: "10px",
            }}
          >
            Merchant
          </div>
          <button
            onClick={() => togglePopover()}
            className="category-close-button"
          >
            <i className="fa fa-times"></i>
          </button>
          <div
            className="search-merchant your-merchant-search"
            style={{
              width: "100%",
            }}
          >
            <form>
              <input
                type="text"
                value={merchantSearch}
                className="search-box-input py-2 inter add-goal full-width-transaction"
                onChange={(e) => {
                  setMerchantSearch(e.target.value);
                  const data = merchatsList.filter((val) =>
                    val.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  console.log('data', data);
                  
                  setSearchMerchatsList(data);
                }}
              />
              <Search className="search-merchant" />
            </form>
          </div>
          <div
            className="categories-scroll-container"
            style={{
              maxHeight: "315px",
              overflowY: "auto",
              marginTop: "10px",
              paddingRight: "0px",
            }}
          >
            <div className="categories group-btn green-btn">
              <div className="group-btn green-btn">
                <div className="button-blck" style={{ marginRight: "10px" }}>
                  <button
                    className={`all-btn ${
                      selectedCategories.length === 0 ? "green" : ""
                    }`}
                    onClick={() =>
                      setSelectedCategories(
                        selectedCategories.length === 0 ? [] : []
                      )
                    }
                  >
                    All
                  </button>
                </div>
                {searchMerchatsList.length > 0 &&
                  searchMerchatsList.map((category) => (
                    <div
                      key={category.id}
                      className="button-blck"
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className={`icon-tag ${
                          selectedCategories.includes(category.id)
                            ? "green"
                            : ""
                        }`}
                        onClick={() => handleTagClick(category.id)}
                      >
                        {
                          <div className="img-box-merchant-small">
                            <img
                              src={category.logo}
                              alt=""
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                        }
                        <div className="content">
                          <h6
                            className={`icon-content ${
                              selectedCategories.includes(category.id)
                                ? "icon-white"
                                : ""
                            }`}
                          >
                            {category.name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantListPopover;
