import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useAddTransaction = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [merchant, setMerchants] = useState([]);
  const [value, setValue] = useState("");
  const [transactionAvatar, setTransactionAvatar] = useState();
  const [transName, setTransName] = useState("");
  const [transAmount, setTransAmount] = useState("");
  const [transDate, setTransDate] = useState(null);

  const [merchantName, setMerchantName] = useState("");
  const [merchantImg, setMerchantImg] = useState("");
  const [merchantId, setMerchantId] = useState("");

  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [accountImg, setAccountImg] = useState("");
  const [accountId, setAccountId] = useState("");

  const [transactionType, setTransactionType] = useState("Income");
  const [categoryName, setCategoryName] = useState("Insurance");
  const [categoryIcon, setCategoryIcon] = useState("💰");
  const [categoryColor, setCategoryColor] = useState("insurance-img");

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const togglecategoryInfo = (name, icon, color) => {
    setCategoryName(name);
    setCategoryIcon(icon);
    setCategoryColor(color);
  };

  const getMerchantsByClientBy = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_MERCHANTS_BY_CLIENT_ID}${uuid}`
      );
      setMerchants(response.data?.merchants);
      if (response.data?.merchants.length > 0) {
        setMerchantName(response.data?.merchants[0].name);
        setMerchantImg(response.data?.merchants[0].logo);
        setMerchantId(response.data?.merchants[0].id);
      }
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_CASH_ACCOUNT}${uuid}`
      );
      setAccounts(response?.data?.accounts);
      if (response?.data?.accounts.length > 0) {
        setAccountName(response?.data?.accounts[0].accountName);
        setAccountImg(response?.data?.accounts[0].avatar);
        setAccountId(response?.data?.accounts[0].id);
      }
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  useEffect(() => {
    getMerchantsByClientBy();
    fetchCashAccount();
  }, [getMerchantsByClientBy, fetchCashAccount]);

  const updateFilteredMerchants = () => {
    const sortedMerchants =
      merchant &&
      merchant?.length > 0 &&
      merchant.slice().sort((a, b) => b.amount - a.amount);

    return (
      sortedMerchants?.length > 0 &&
      sortedMerchants?.filter((it) => {
        const filterMerchants = it.name
          .toLowerCase()
          .includes(value.toLowerCase());

        return filterMerchants;
      })
    );
  };

  const filteredMerchant = updateFilteredMerchants();

  const toggleMerchantInfo = (id) => {
    const selected = filteredMerchant.filter((it) => it.id === id);
    setMerchantName(selected[0].name);
    setMerchantImg(selected[0].logo);
    setMerchantId(selected[0].id);
  };

  const toggleAccountInfo = (id) => {
    const selected = accounts.filter((it) => it.id === id);
    setAccountName(selected[0].accountName);
    setAccountImg(selected[0].avatar);
    setAccountId(selected[0].id);
  };

  const upload = (file) => {
    setTransactionAvatar(file.target.files[0]);
  };

  const saveTransaction = async () => {
    const formData = new FormData();
    formData.append("userUuid", uuid);
    formData.append("trans_name", transName);
    formData.append("trans_date", transDate);
    formData.append("trans_amount", Number(transAmount));
    formData.append("merchant_id", merchantId);
    formData.append("accound_id", accountId);
    formData.append("avatar", transactionAvatar);
    formData.append("trans_type", transactionType.toUpperCase());
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.ADD_TRANSACTION,
        formData
      );
      if (response.status === 200) {
        toast.success("Transaction successfully..", {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/Transactions/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Transaction details not saved. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return {
    uuid,
    togglePopupInfo,
    isPopupOpenInfo,
    transName,
    setTransName,
    transDate,
    setTransDate,
    transAmount,
    setTransAmount,
    categoryColor,
    categoryIcon,
    categoryName,
    merchantImg,
    merchantName,
    accountName,
    accountImg,
    transactionType,
    upload,
    saveTransaction,
    togglecategoryInfo,
    value,
    setValue,
    merchant,
    filteredMerchant,
    toggleMerchantInfo,
    accounts,
    toggleAccountInfo,
    setTransactionType,
  };
};

export default useAddTransaction;
