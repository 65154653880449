import React, { useState } from "react";
import MerchantImage1 from "../../assests/images/dashboard/countdown.png";
import MerchantImage2 from "../../assests/images/dashboard/trademe.png";
import MerchantImage3 from "../../assests/images/dashboard/torpedeo.png";
import MerchantImage4 from "../../assests/images/dashboard/mightyape.png";
import MerchantImage5 from "../../assests/images/dashboard/kdc.png";
import PopupModal from "../../ModalForm/Popup/PopupModel";

const topMerchents = [
  {
    id: 1,
    img: MerchantImage1,
    name: "Countdown",
    value: "$560.53",
  },
  {
    id: 2,
    img: MerchantImage2,
    name: "Trademe",
    value: "$560.53",
  },
  {
    id: 3,
    img: MerchantImage3,
    name: "Torpedeo 7",
    value: "$560.53",
  },
  {
    id: 4,
    img: MerchantImage4,
    name: "MightyApe",
    value: "$560.53",
  },
  {
    id: 5,
    img: MerchantImage5,
    name: "KFC",
    value: "$560.53",
  },
];

const TopMerchents = () => {
  const [selectedTopMerchentsOption, setSelectedTopMerchentsOption] =
    useState("This Week");

  const [merchentsPopOver, setMerchentsPopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectedTopMerchentsOption(option);
    setMerchentsPopOver(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal">Top 5 Merchants</h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ width: "93px", height: "25px", borderRadius: "20px" }}
          id="TopMerchentsPopover"
          onClick={() => setMerchentsPopOver(!merchentsPopOver)}
          aria-haspopup="true"
          aria-expanded={merchentsPopOver}
        >
          {selectedTopMerchentsOption}
        </span>
        {merchentsPopOver && (
          <PopupModal
            isOpen={merchentsPopOver}
            onClose={() => setMerchentsPopOver(!merchentsPopOver)}
            targetId="TopMerchentsPopover"
            title="Date range"
            options={[
              "This week",
              "This month",
              "This year",
              "Last week",
              "Last month",
            ]}
            selectedOption={merchentsPopOver}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>

      {topMerchents?.map((item) => {
        return (
          <>
            <div
              key={item.id}
              className="br-10 bg-white border p-2 d-flex justify-content-between align-items-center mb-1"
            >
              <div className="d-flex align-items-center justify-content-center">
                <img src={item?.img} className="img-fluid" alt="" />
                <div className="d-flex flex-column ms-2">
                  <span className="fs-16 fw-600 inter lh-23">{item?.name}</span>
                  <span className="fs-14 fw-400 inter lh-20 text-medium-gray">
                    Transactions
                  </span>
                </div>
              </div>
              <span className="text-soft-red fs-16 fw-600 inter ">
                {item?.value}
              </span>
            </div>
          </>
        );
      })}
    </>
  );
};

export default TopMerchents;
