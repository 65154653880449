import React from "react";
import "./Moneyinsights.css";
import PopupModalDialog from "./PopupModalDialog";

const MerchantHistorySection = ({
  togglePopupMonthly,
  isPopupOpenMonthly,
  selectedMonthlyOption,
  handleOptionMonthlyClick,
  heading,
}) => {
  const fullOptions = ["Last 3 months", "Last 6 months", "Last 12 months"];
  
  return (
    <div className="row mt-4">
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginTop: "15px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              fontSize: "22px",
              fontweight: "500",
              marginBottom: "4px",
            }}
          >
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="">
            <button
              className="group-button green"
              onClick={togglePopupMonthly}
              aria-haspopup="true"
              aria-expanded={isPopupOpenMonthly}
              id="Open_Popup_Modal"
            >
              {selectedMonthlyOption}
            </button>
            {isPopupOpenMonthly && (
              <PopupModalDialog
                targetId="Open_Popup_Modal"
                isOpen={isPopupOpenMonthly}
                onClose={togglePopupMonthly}
                title="Date Range"
                options={fullOptions}
                selectedOption={selectedMonthlyOption}
                handleOptionClick={handleOptionMonthlyClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantHistorySection;
