import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../../ModalForm/ChangePassword/index.css";
import "./index.css";
import moment from "moment";
import { ReactComponent as Split } from "../../assests/images/split.svg";
import { ReactComponent as SplitIcon } from "../../assests/images/splitIcon.svg";
import { ReactComponent as RevertSplit } from "../../assests/images/revert-split.svg";
import { ReactComponent as SplitTrash } from "../../assests/images/split-trash.svg";
import { ReactComponent as Rules } from "../../assests/images/rule-trns-icon.svg";
import { ReactComponent as AddSplit } from "../../assests/images/add-split.svg";
import { ReactComponent as DeleteIcon } from "../../assests/images/custom-trns-delete.svg";
import { getOrdinalSuffix } from "../../utilities/helper.jsx";
import CategoryTag from "../../Components/CategoryTag/index.jsx";
import { toast } from "react-toastify";
import Amount from "../../Components/Amount.jsx/index.jsx";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import AddTransactionRuleModel from "./AddTransactionRuleModel.jsx";
import { PopUpForDeleteWithName } from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";
import AccountBalance from "./TransactionAcount.jsx";

const TransactionViewModal = ({
  modalOpen,
  closeModal,
  data,
  splitTransaction,
  expenseCategories,
  incomeCategories,
  oneOffCategory,
  updateSingleCategory,
  deleteCustomTransaction,
  splitbackToMainTransaction,
  splitSingleBackToMainTransaction,
  categoryTitle,
  categorySubTitle,
  customPopoverClassName,
}) => {
  const [splitClicked, setSplitCliked] = useState(false);
  const [remainingSplit, setRemainingSplit] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const currencyCode = localStorage.getItem("currencyCode");
  const [additionalCards, setAdditionalCards] = useState([
    {
      id: 1,
      amount: "",
      userCategoryId: "",
      emoji: data?.masterCategoryEmoji,
      colour: data?.masterCategoryColour,
      name: data?.masterCategoryName,
    },
    {
      id: 2,
      amount: "",
      userCategoryId: "",
      emoji: data?.masterCategoryEmoji,
      colour: data?.masterCategoryColour,
      name: data?.masterCategoryName,
    },
  ]);

  const [modalsRule, setModalsRule] = useState({
    addTransactionRules: false,
  });
  const openModalRule = (modal) => {
    setModalsRule({ ...modalsRule, [modal]: true });
  };
  const closeModalRules = (modal) => {
    setModalsRule({ ...modalsRule, [modal]: false });
  };

  const defaultCard = {
    amount: "",
    userCategoryId: "",
    emoji: data?.masterCategoryEmoji,
    colour: data?.masterCategoryColour,
    name: data?.masterCategoryName,
  };

  const addCard = () => {
    const newId =
      additionalCards.length > 0
        ? Math.max(...additionalCards.map((card) => card.id)) + 1
        : 1;
    setAdditionalCards([...additionalCards, { id: newId, ...defaultCard }]);
  };

  const handleChange = (index, field, value) => {
    const updatedCards = [...additionalCards];
    updatedCards[index] = {
      ...updatedCards[index],
      [field]: value,
      userCategoryId: data?.transactionCategoryId,
    };
    setAdditionalCards(updatedCards);
  };

  const splitAmount = Math.abs(data?.amount);
  useEffect(() => {
    const totalAmount = additionalCards.reduce(
      (sum, card) => sum + (parseFloat(card.amount) || 0),
      0
    );

    setRemainingSplit((splitAmount - totalAmount).toFixed(2));
  }, [additionalCards, splitAmount]);

  const onCategoryChangeForSplitting = (data) => {
    const { id, userCategoryId, emoji, colour, name } = data;
    setAdditionalCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, userCategoryId, emoji, colour, name } : card
      )
    );
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };
  const handleDeleteConfirm = async () => {
    deleteCustomTransaction(Number(data.transactionId));
  };
  const onSplitTransaction = () => {
    const { transactionUuid } = data;
    let transactionType = data?.amount < 0 ? "Expense" : "Income";

    const invalidSplits = additionalCards.some(
      (card) => !card.amount || parseFloat(card.amount) <= 0
    );
  
    if (invalidSplits) {
      toast.error("All split amounts must be filled and above $0");
      return;
    }

    const totalSplitsAmount = additionalCards.reduce(
      (sum, card) => sum + parseFloat(card.amount || 0),
      0
    );
    const totalTransactionAmount = Math.abs(parseFloat(data?.amount));
    if (totalSplitsAmount > totalTransactionAmount) {
      toast.error(
        "It looks like your split amounts don’t match the total transaction amount. Simply adjust your split amounts, and then you’re good to go."
      );
      return;
    }
    closeModal("viewTransaction");
    const formattedData = {
      transactions: additionalCards.map((card) => ({
        amount: parseFloat(card.amount),
        userCategoryId: parseInt(card.userCategoryId),
      })),
    };
    splitTransaction(formattedData, transactionUuid, transactionType);
  };
  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (!modalOpen) {
      setSplitCliked(false);
    }
    setRemainingSplit(Math.abs(data?.amount));
  }, [modalOpen]);

  return (
    <div
      className={`modal-background ${modalOpen ? "open-modal" : ""} `}
      onClick={() => closeModal("viewTransaction")}
    >
      <div
        className={`modal-content  ${
          splitClicked ? "split-modal" : "add-modal-width add-modal-padding"
        }`}
        onClick={handleContentClick}
      >
        {!data.isAkahu && !splitClicked && data.status !== "Pending" && (
          <button
            onClick={() => setShowDeletePopup(true)}
            className="delete-button-trans"
          >
            <DeleteIcon />
          </button>
        )}

        <button
          onClick={() => closeModal("viewTransaction")}
          className={!splitClicked ? "close-pass-button" : "close-split-button"}
        >
          <CrossIcon />
        </button>
        <div className="">
          <div
            className={`d-flex justify-content-between ${
              splitClicked && "split-pd pt-3"
            }`}
          >
            <div className="fs-3">
              {splitClicked ? (
                <>
                  <span className="bold black password-heading">
                    Split transaction
                  </span>
                </>
              ) : (
                <>
                  <span className="bold black password-heading">
                    Transaction details
                  </span>
                  <p className="trans-modal-para">
                    Here’s the details for your transaction
                  </p>
                </>
              )}
            </div>
          </div>
          {!splitClicked ? (
            <>
              <div className="d-flex align-items-center flex-column gap-1">
                <img
                  src={data?.merchantLogo ?? data?.accountAvatar}
                  className="transaction-view-img"
                />
                <div className="black account-bal-title mt-4 bold">
                  {data?.transactionName}
                </div>
                <span className="small-text-merchant">
                  <Amount amount={data?.amount} />
                  <span className="view-date ms-1">
                    <span className="black">|&nbsp;</span>
                    {moment(data?.dateTime).format("DD/MM/YYYY")}
                  </span>
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CategoryTag
                    emoji={data?.masterCategoryEmoji}
                    name={data?.masterCategoryName}
                    colour={data?.masterCategoryColour}
                    expenseCategories={expenseCategories}
                    incomeCategories={incomeCategories}
                    oneOffCategory={oneOffCategory}
                    amount={data?.amount}
                    uniqueID={data?.transactionUuid}
                    updateCategory={updateSingleCategory}
                    status={data.status}
                    categoryTitle={categoryTitle}
                    categorySubTitle={categorySubTitle}
                    customPopoverClassName={customPopoverClassName}
                  />
                  {data.isSplit && (
                    <div
                      style={{
                        backgroundColor: "#469B88",
                        borderRadius: "50px",
                        height: "25px",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        display: "flex",
                        marginLeft: "5px",
                        marginBottom: "8px",
                        alignItems: "center",
                      }}
                    >
                      <SplitIcon
                        style={{
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      {data.splitIndex > 0 && (
                        <span
                          style={{
                            color: "white",
                            fontSize: "10px",
                            marginLeft: "5px",
                          }}
                        >
                          {data.splitIndex}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {data?.transactionBalance && (
                  <>
                    <span className="small-text-detail">
                      <Amount amount={data?.transactionBalance} />
                      <span className="view-date-sm ms-1">
                        available after transaction
                      </span>
                    </span>
                  </>
                )}
                {data?.subCategoryEmoji && data?.subCategoryName && (
                  <>
                    <span className="subcategory-text-detail">
                      {data?.subCategoryEmoji} {data?.subCategoryName}
                    </span>
                  </>
                )}
              </div>
              <div>
                <div className="black account-title mt-4">Account</div>
                <div
                  className="detail-account-container"
                  // style={{ width: "100%", height: "64px", minHeight: "unset" }}
                >
                  <div
                    className="d-flex align-items-center flex-row"
                    style={{ gap: "12px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "40px" }}
                    >
                      <img
                        src={data?.accountAvatar}
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: "40px",
                          borderRadius: "100%",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className="lcs-inner">
                      <div className="detail-account-title">
                        {data?.accountName}
                      </div>
                      {data?.akuAccountNumber && (
                        <div className="setting_text_color-2">
                          {data?.akuAccountNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <AccountBalance
                      accountType={data?.accountType ?? ""}
                      currency={data?.currency ?? currencyCode}
                      convertedAmount={data?.convertedAmount ?? 0.0}
                      accountBalance={data?.accountBalance ?? 0.0}
                      userCurrencyCode={currencyCode}
                    />
                  </div>
                </div>
              </div>
              {data.status !== "Pending" &&
                data.isSplit &&
                data.splitIndex === 0 && (
                  <>
                    <div className="black split-title mt-4 ">
                      Revert all splits back to transaction
                    </div>
                    <div
                      className="split-btn"
                      onClick={() =>
                        splitbackToMainTransaction(data.transactionUuid)
                      }
                    >
                      <RevertSplit />
                      <span className="split-here">Revert all splits back</span>
                    </div>
                  </>
                )}
              {data.status !== "Pending" &&
                data.isSplit &&
                data.splitIndex === 1 && (
                  <>
                    <div className="black split-title mt-4 ">
                      Add back to the main transaction?
                    </div>
                    <div
                      className="split-btn"
                      onClick={() =>
                        splitSingleBackToMainTransaction(data.transactionUuid)
                      }
                    >
                      <RevertSplit />
                      <span className="split-here">
                        Add back to main transaction
                      </span>
                    </div>
                  </>
                )}
              {data.status !== "Pending" && !data.isSplit && (
                <>
                  <div className="black split-title mt-4 ">
                    Want to split this transaction?
                  </div>
                  <div
                    className="split-btn"
                    onClick={() => setSplitCliked(!splitClicked)}
                  >
                    <Split />
                    <span className="split-here">Split transaction here</span>
                  </div>
                </>
              )}
              {data.status !== "Pending" && (
                <>
                  <div className="black split-title mt-4 ">
                    Create transaction rule
                  </div>
                  <div
                    className="split-btn"
                    onClick={() => openModalRule("addTransactionRules")}
                  >
                    <Rules />
                    <span className="split-here">
                      Create transaction rule here
                    </span>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div>
                <div className="split-pd mt-3">
                  <div className="account-box" style={{ width: "100%" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "16px" }}
                    >
                      <div className="">
                        <img
                          src={data?.merchantLogo || data?.accountAvatar}
                          alt=""
                          style={{
                            objectFit: "contain",
                            width: "40px",
                            borderRadius: "100%",
                            maxHeight: "60px",
                          }}
                        />
                      </div>
                      <div className="lcs-inner">
                        <div className="split-account-title">
                          {data?.transactionName
                            ? data.transactionName.slice(0, 25)
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className={`account-number`}>
                      <Amount amount={data?.amount} />
                    </div>
                  </div>
                </div>
                <div className="sp-container">
                  {additionalCards.map((card, index) => (
                    <div className="split-box">
                      <div className="d-flex justify-content-between mt-2 align-items-center">
                        <div className="split-amount">
                          {getOrdinalSuffix(index)} split amount
                        </div>
                        <input
                          type="number"
                          name="currentBalance"
                          value={card.amount}
                          onChange={(e) => {
                            const value = e.target.value;

                            if (/^\d*\.?\d{0,2}$/.test(value)) {
                              handleChange(index, "amount", value);
                            }
                          }}
                          className="edit-goal-house py-2 inter add-goal"
                          style={{ border: "none", width: `${index >= 2 ? "210px" : "240px"}`, marginLeft: `${index >= 2 ? "30px" : "0px"}`}}
                        />
                         {/* Show delete icon only for splits 3 and beyond */}
                          {index >= 2 && (
                            <button
                              className="delete-split-icon"
                              onClick={() => {
                                const updatedCards = additionalCards.filter((_, i) => i !== index);
                                setAdditionalCards(updatedCards);
                              }}
                            >
                              <SplitTrash />
                            </button>
                          )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <>
                          <div className="current-bal">
                            <div className="split-amount">Category</div>
                            <div>
                              <CategoryTag
                                emoji={card?.emoji}
                                name={card?.name}
                                colour={card?.colour}
                                expenseCategories={expenseCategories}
                                incomeCategories={incomeCategories}
                                amount={data?.amount}
                                uniqueID={card.id}
                                updatingForSplitTransaction={true}
                                onCategoryChangeForSplitting={
                                  onCategoryChangeForSplitting
                                }
                                customPopoverClassName={customPopoverClassName}
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="split-pd pb-4">
                <button className="add-split" onClick={addCard}>
                  <AddSplit />
                  Add another split
                </button>

                <div className="split-btn-container">
                  <p className="split-transaction-text">
                    {additionalCards.length} Splits | Total remaining to split:
                    <span className="split-transaction-text-amount">
                      {" "}
                      ${remainingSplit}
                    </span>
                  </p>
                  <button
                    className="split-transac"
                    onClick={onSplitTransaction}
                  >
                    Split Transaction
                  </button>
                </div>
              </div>
            </>
          )}
          {modalsRule.addTransactionRules && (
            <AddTransactionRuleModel
              modalOpen={modalsRule.addTransactionRules}
              closeModal={closeModalRules}
              expenseCategories={expenseCategories}
              incomeCategories={incomeCategories}
              name={data?.transactionName}
              type={data?.amount < 0 ? "Expense" : "Income"}
            />
          )}
          {showDeletePopup && (
            <PopUpForDeleteWithName
              deleteButtonName="Delete transaction"
              handleDeleteCancel={handleDeleteCancel}
              handleDeleteConfirm={handleDeleteConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionViewModal;
