import axiosAkahuService from "./AxiosAkahuInstance";


export class HttpAkahuClient {
  static  get(url, params = {}) {
    try {
      const response = axiosAkahuService.get(url, { params });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static post(url, data, options = {}) {
    try {
      const response = axiosAkahuService.post(url, data, options);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static put(url, data) {
    try {
      const response = axiosAkahuService.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static delete(url, data = null) {
    try {
      const config = data ?  data  : {};
      const response = axiosAkahuService.delete(url, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default HttpAkahuClient;