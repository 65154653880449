import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import weburls from "../../Weburls/weburls";
import { toast, ToastContainer } from "react-toastify";
import UserImageInfoToggle from "../UserImageInfoToggle";
import { moduleInfoPopUpData } from "../../utilities/Statics";
import TransactionsAmi from "../../assests/images/dashboard/ami.png";
import DollarSvg from "../../assests/images/dashboard/dollar.svg";
import GoalIcon1 from "../../assests/images/dashboard/icon-goal-1.png";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import moment from "moment";
import BreakDownDoughnut from "../Dashboard/BreakDownDoughnut.jsx";
import BreakDownExpensesCard from "../Dashboard/BreakDownExpensesCard.jsx";
import AccountBalancesCards from "../Dashboard/AccountBalances.jsx";
import IncomeBarChart from "../Dashboard/IncomeBarChart.jsx";
import CashFlowBarChart from "../Dashboard/CashFlowBarChart.jsx";
import IncomeExpenses from "../Dashboard/IncomeExpenses.jsx";
import TopExpenses from "../Dashboard/TopExpenses.jsx";
import TopMerchents from "../Dashboard/TopMerchents.jsx";
import SpentBudget from "../Dashboard/SpentBudget.jsx";
import MyBalancePopOver from "../Dashboard/MyBalancePopOver.jsx";
import MenuIcon from "../../assests/images/icons/menu-icon.svg";
import InfoIcon from "../../assests/images/icons/info-icon.svg";
import { adjustLightness } from "../../utilities/color-generator.jsx";
import PopupModal from "../../ModalForm/Popup/PopupModel.jsx";
import { Icon } from "@iconify/react";
import RemoveWidgets from "../Dashboard/RemoveWidghets.jsx";

const doughnutBreakDownData = {
  labels: ["Rent", "Insurance", "Appearance", "Car", "Education"],
  datasets: [
    {
      data: [2, 12, 18, 7, 56],
      backgroundColor: ["#FAE588", "#ACD3A0", "#E3C29D", "#E79E9A", "#DAB2FF"],
      hoverBackgroundColor: [
        "#FAE588",
        "#ACD3A0",
        "#E3C29D",
        "#E79E9A",
        "#DAB2FF",
      ],
      borderWidth: 0,
    },
  ],
};

const dummyBreakDownData = [
  {
    avatar: Logo,
    iconbg: "#EEEFC6",
    accountName: "Rent",
    accountNumber: "Up 10% vs last week",
    value: 1200.87, // Remove the "$" and comma, pass number directly
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#EFDFC6",
    accountName: "Insurance",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#C6D2EF",
    accountName: "Insurance",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#C6EFD2",
    accountName: "Insurance",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#F7D4CE",
    accountName: "Insurance",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#F7D4CE",
    accountName: "Appearance",
    accountNumber: "Up 10% vs last week",
    value: 349.58, // Just the number
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
];
const dummyIncomeData = [
  {
    avatar: Logo,
    iconbg: "#EEEFC6",
    accountName: "Wages",
    accountNumber: "Up 10% vs last week",
    value: 1200.87, // Remove the "$" and comma, pass number directly
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#EFDFC6",
    accountName: "Investments",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
  {
    avatar: Logo,
    iconbg: "#C6D2EF",
    accountName: "Rental Income",
    accountNumber: "Up 10% vs last week",
    value: 480.9, // No "$" symbol
    details: [
      { label: "Attractions", value: 250.87 },
      { label: "Child clothing", value: 220.87 },
      { label: "Clothing repair", value: 200.87 },
      { label: "Apparel stores", value: 195.87 },
    ],
  },
];

const doughnutIncomeData = {
  labels: ["Wages", "Investments", "Rental Income"],
  datasets: [
    {
      data: [2, 12, 18],
      backgroundColor: ["#FAE588", "#ACD3A0", "#E3C29D"],
      hoverBackgroundColor: ["#FAE588", "#ACD3A0", "#E3C29D"],
      borderWidth: 0,
    },
  ],
};

const goalDummy = [
  {
    id: 1,
    colour: "#E0533D",
    iconbg: "#FFA294",
    progressBarcolour: "#B51800",
    goalName: "Holiday",
    currentBalance: "300",
    value: "699",
    icon: GoalIcon1,
  },
  {
    id: 2,
    colour: "#E78C9D",
    iconbg: "#FF9494",
    progressBarcolour: "#D9556E",
    goalName: "New Laptop",
    currentBalance: "300",
    value: "699",
    icon: GoalIcon1,
  },
  {
    id: 3,
    colour: "#EED868",
    iconbg: "#FFE794",
    progressBarcolour: "#C1AD45",
    goalName: "Car",
    currentBalance: "300",
    value: "699",
    icon: GoalIcon1,
  },
  {
    id: 4,
    colour: "#377CC8",
    iconbg: "#94B2FF",
    progressBarcolour: "#00438D",
    goalName: "House",
    currentBalance: "300",
    value: "699",
    icon: GoalIcon1,
  },
];

const Dashboard = () => {
  // states
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const companyId = JSON.parse(localStorage.getItem("userData"));
  const [tableFilter, setTableFilter] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [groupClients, setGroupWithClient] = useState([]);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [balancePopOver, setBalancePopOver] = useState(false);
  const [removeWidgets, setRemoveWidgets] = useState(false);
  const [selectedExpensesOption, setSelectedExpensesOption] =
    useState("This Week");
  const [expensesPopOver, setExpensesPopOver] = useState(false);

  const handleExpensesClick = (option) => {
    setSelectedExpensesOption(option);
    setExpensesPopOver(false);
  };

  //params
  const { uuid } = useParams();

  // functions
  const settingApi = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(weburls.Get_clients_by_uuid + `${uuid}`);
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  // hooks
  useEffect(() => {
    settingApi();
  }, [settingApi]);

  const activeChange = () => {
    setActive(true);
  };
  const fetchedData = useCallback(async () => {
    try {
      const response = await axios.get(
        weburls.Get_clients + `${companyId.companyId}`
      );
      localStorage.setItem(
        "client-userUuid",
        JSON.stringify(response.data.clients)
      );
      if (response?.data?.clients?.length > 0) {
        setTableFilter(response.data.clients);
      }
    } catch (error) {
      console.error(error);
    }
  }, [companyId.companyId]);

  const userDetailsApi = useCallback(async () => {
    try {
      const response = await axios.get(
        weburls.Advisor_Details + `${companyId.userUuid}`
      );
      if (response?.data) {
        setUserDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [companyId.userUuid]);

  const groupDetailsWithClients = async () => {
    const response = await axios.get(
      `${weburls.Get_Group_With_Clients}?companyId=${companyId.companyId}`
    );
    if (response?.data) {
      setGroupWithClient(response.data);
    }
  };
  const topGroups =
    groupClients.length > 0 &&
    groupClients
      .map((group) => {
        const activeClientsCount = (
          group.client_details?.filter(
            (client) => client.client_status === 1
          ) ?? []
        ).length;
        const inActiveClientsCount = (
          group.client_details?.filter(
            (client) => client.client_status === 2
          ) ?? []
        ).length;
        const notActivatedClientsCount = (
          group.client_details?.filter(
            (client) => client.client_status === 3
          ) ?? []
        ).length;
        return {
          group_name: group.group_name,
          group_logo: group.group_logo,
          activeClientsCount,
          inActiveClientsCount,
          notActivatedClientsCount,
        };
      })
      .sort((a, b) => {
        // Customize the sorting logic based on your requirements
        // For example, sorting by activeClientsCount and then inActiveClientsCount
        if (b.activeClientsCount !== a.activeClientsCount) {
          return b.activeClientsCount - a.activeClientsCount;
        } else {
          return b.inActiveClientsCount - a.inActiveClientsCount;
        }
      })
      .slice(0, 3);

  const activeCount = tableFilter?.filter((item) => item.status === 1).length;
  const inactiveCount = tableFilter?.filter((item) => item.status === 2).length;
  const notActivatedCount = tableFilter?.filter(
    (item) => item.status === 3
  ).length;
  useEffect(() => {
    fetchedData();
    userDetailsApi();
    groupDetailsWithClients();
  }, [fetchedData, userDetailsApi]);
  const topGroup = groupClients.length > 0 && topGroups.shift();
  return (
    <>
      <div className="app-main__outer mt-2">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fs-32 fw-400 text-dark-blue">
              Good Morning, <span className="fw-700">{data?.firstName}</span> 👋
            </h5>
            <p className="fs-14 fw-400 text-muted-blue">
              Here’s an update on your financial position.
            </p>
          </div>
          <div>
            {/* <img
              className="me-3 cursor-pointer"
              style={{ width: "30px" }}
              src={MenuIcon}
              alt="menu icon"
            /> */}
            <button className="btn-green fs-15 fw-500 h-43">
              Add Widgets <Icon icon="ic:outline-plus" className="w-21 h-21" />
            </button>
            <button
              className="btn-rose-red fs-15 fw-500 mx-2 h-43"
              onClick={() => setRemoveWidgets(true)}
            >
              Remove Widgets <Icon className="w-21 h-21" icon="charm:cross" />
            </button>
            <img
              className="me-4 cursor-pointer"
              src={InfoIcon}
              style={{ width: "30px" }}
              alt="info icon"
            />
            <UserImageInfoToggle
              title={moduleInfoPopUpData.goals.title}
              description={moduleInfoPopUpData.goals.description}
              isPopupOpenInfo={isPopupOpenInfo}
              togglePopupInfo={togglePopupInfo}
              customStyle={{ display: "flex" }}
            />
          </div>
        </div>

        <div class="row g-4">
          <div class="col-md-6">
            <div className="amount-dashboard-wrp bg-deep-blue">
              <div className="balance-wrp-heading">
                <h4 className="fs-25 white-text fw-400 lh-36">
                  Current Net Worth
                </h4>
                <h2 className="big-head">
                  <h3 className="fs-50 fw-500 lh-62">$25,000.40</h3>
                </h2>
                <div className="position-absolute bottom-0 end-0 mb-3 mx-2">
                  <span
                    className="white-text d-flex align-items-center fs-12 fw-400 lh-18"
                    id="exampleModalToggle"
                    onClick={() => setBalancePopOver(true)}
                  >
                    My Balances
                    <i className="cursor-pointer d-flex justify-content-center align-items-center w-32 h-32 fa-solid fa-arrow-right bg-white rounded-circle p-2 border-none mx-2 text-dark-gray"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <IncomeExpenses />
        </div>

        <div class="row g-4 mt-2">
          <div class="col-md-6">
            <IncomeBarChart />
          </div>

          <div class="col-md-3">
            <TopExpenses />
          </div>

          <div class="col-md-3">
            <TopMerchents />
          </div>
        </div>

        <div className="mt-2">
          <SpentBudget />

          <div className="row">
            <div className="col-md-6">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <h4 className="fs-18 fw-500 lh-34 text-charcoal">
                  Recent Transactions
                </h4>
                <Link to={`/transactions/${uuid}`}>
                  <span
                    className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                    style={{
                      width: "93px",
                      height: "25px",
                      borderRadius: "50px",
                    }}
                  >
                    See all
                  </span>
                </Link>
              </div>

              {[1, 2, 3, 4, 5].map((item) => {
                return (
                  <div
                    className="merchant-box mt-2"
                    // key={merchant.merchantsUuid}
                  >
                    <div className="d-flex w-75" style={{ cursor: "pointer" }}>
                      <div className="img-box" style={{ marginRight: "5px" }}>
                        <img
                          src={TransactionsAmi}
                          alt=""
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                      <div className="ms-3">
                        <div
                          className="text-merchant bold black"
                          style={{
                            fontWeight: "bold",
                            color: "black",
                            marginBottom: "0px",
                          }}
                        >
                          <span className="fs-16 fw-700 lh-22 text-dark-gray">
                            Ami insurance
                          </span>
                        </div>
                        {/* Updated Amount and Transaction Count Section */}
                        <div
                          className="small-text-merchant"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            fontSize: "14px",
                          }}
                        >
                          <span className={"text-fresh-green fs-12 fw-500"}>
                            $12000
                          </span>
                          <span className="black">|</span>
                          <span className="fs-12 fw-500 lh-16 text-grayish">
                            14/05/2024
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="button-blck">
                      <div className="icon-tag" style={{ marginBottom: "0px" }}>
                        <div
                          className={`icon-transaction-img`}
                          style={{
                            background: "#EBF0B3",
                          }}
                        >
                          <img
                            style={{ padding: "1px" }}
                            src={DollarSvg}
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="icon-content text-grayish">
                            Insurance
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-6">
              <CashFlowBarChart />
            </div>
          </div>

          {/* Account Balances */}
          <AccountBalancesCards />

          <div className="row mt-2">
            <div className="col-md-12">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <h4 className="fs-18 fw-500 lh-34 text-charcoal">Goals</h4>
                <span
                  className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                  style={{ width: "93px", height: "25px" }}
                >
                  See all
                </span>
              </div>
            </div>

            {goalDummy?.map((item) => {
              return (
                <div className="col-md-6 mb-3">
                  <div className="" key={item.id}>
                    <Link
                      style={{
                        color: "white",
                      }}
                    >
                      <div
                        className={`financial-holiday financial cursor-pointer text-white`}
                        style={{
                          backgroundColor: item?.colour,
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
                                style={{
                                  backgroundColor: adjustLightness(
                                    item?.colour,
                                    12
                                  ),
                                  // backgroundColor: item?.iconbg,
                                  borderRadius: "50px",
                                  padding: "8px",
                                }}
                              >
                                <img
                                  className="img-fluid"
                                  style={{
                                    width: "25px",
                                  }}
                                  src={item?.icon}
                                  alt=""
                                />
                              </div>
                              <div className="ms-2 fw-600 fs-18 lh-24 inter text-white">
                                {item.goalName}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            {/* <div className="financial-icon">
                          <Editnw onClick={() => handleEditGoal(it)} />
                        </div> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                          <div className="fs-12 fw-400 lh-18 inter text-white">
                            Balance
                          </div>
                          <div className="financial-text">
                            {(
                              (item.currentBalance / item.value).toFixed(2) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                        </div>
                        <div
                          className={`progress`}
                          style={{
                            height: "7px",
                            backgroundColor: "rgba(255, 255, 255, 0.4)",
                          }}
                        >
                          <div
                            className={`progress-bar`}
                            role="progressbar"
                            aria-valuenow={(
                              (item.currentBalance / item.value) *
                              100
                            ).toFixed(2)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: `${(
                                (item.currentBalance / item.value) *
                                100
                              ).toFixed(2)}%`,
                              opacity: "2 !important",
                              backgroundColor: adjustLightness(
                                item?.colour,
                                -16
                              ),
                            }}
                          ></div>
                        </div>
                        <div className="mt-2 d-flex justify-content-between align-items-start">
                          <div>
                            <span className="fs-16 fw-600 lh-23 fw-normal inter text-white">
                              ${item?.currentBalance}
                            </span>
                            <span className="fs-12 fw-400 inter lh-18 text-white">
                              &nbsp; of &nbsp;${item.value}
                            </span>

                            <div className="mt-3 inter lh-18 fs-12 fw-600 text-white">
                              See detail
                            </div>
                          </div>
                          <div className="fs-12 fw-400 inter lh-18 text-white">
                            {item?.endDate
                              ? moment(item?.endDate).format("Do MMMM YYYY")
                              : ""}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>

          {/*  Expense breakdown */}
          <div className="row mt-2">
            <div className="col-md-12">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <h4 className="fs-18 fw-500 lh-34 text-charcoal">
                  Expense breakdown
                </h4>
                <span
                  className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                  style={{
                    width: "93px",
                    height: "25px",
                    borderRadius: "20px",
                  }}
                  id="dashboardExpensesPopOver"
                  onClick={() => setExpensesPopOver(!expensesPopOver)}
                  aria-haspopup="true"
                  aria-expanded={expensesPopOver}
                >
                  This Week
                </span>
                {expensesPopOver && (
                  <PopupModal
                    isOpen={expensesPopOver}
                    onClose={() => setExpensesPopOver(!expensesPopOver)}
                    targetId="dashboardExpensesPopOver"
                    title="Date range"
                    options={[
                      "This week",
                      "This month",
                      "This year",
                      "Last weak",
                      "Last month",
                    ]}
                    selectedOption={selectedExpensesOption}
                    handleOptionClick={handleExpensesClick}
                  />
                )}
              </div>
            </div>

            <div className="col-md-4 doughnut-card">
              <BreakDownDoughnut data={doughnutBreakDownData} />
            </div>
            <div className="col-md-8">
              <BreakDownExpensesCard data={dummyBreakDownData} />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <h4 className="fs-18 fw-500 lh-34 text-charcoal">
                  Income breakdown
                </h4>
                <span
                  className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                  style={{ width: "93px", height: "25px" }}
                >
                  This Week
                </span>
              </div>
            </div>

            <div className="col-md-4 doughnut-card">
              <BreakDownDoughnut data={doughnutIncomeData} />
            </div>
            <div className="col-md-8">
              <BreakDownExpensesCard data={dummyIncomeData} />
            </div>
          </div>
        </div>
      </div>

      {balancePopOver && (
        <MyBalancePopOver
          isOpen={balancePopOver}
          onClose={() => setBalancePopOver(false)}
        />
      )}

      {removeWidgets && (
        <RemoveWidgets
          isOpen={removeWidgets}
          onClose={() => setRemoveWidgets(false)}
        />
      )}
    </>
  );
};
export default Dashboard;