import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { toast } from "react-toastify";
import weburls from "../../Weburls/weburls.jsx";
import {
  formatNumberWithCommas,
  formatValue,
  groupTransactionsByDate,
  groupTransactionsByMonth,
  pieChartColor,
} from "../../utilities/helper.jsx";

import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal.jsx";

import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";

import { TransactionListing } from "../../Views/Transactions/TransactionListing.jsx";
import CategoryBreakdownSection from "./CategoryBreakdownSection.jsx";
import { CategoryBreakDownDetailsSingle } from "./AccordionMoneyInsights.jsx";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics/index.jsx";
import { Tooltip } from "chart.js";
import moment from "moment";

const CategoryBreakDownDetails = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const { item, activeTab } = location.state || {};

  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [isExpensesBreakDown, setExpensesBreakDown] = useState("This month");
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("budget-token");
  const [category, setCategory] = useState([]);
  const [showCategory, setShowCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [spliModalData, setSplitModalData] = useState();
  const [itemData, setItemData] = useState(item);
  const [selectedHistoryData, setSelectedHistoryData] = useState([]);
  const [selectedBarAmount, setSelectedBarAmount] = useState(0);
  const [selectedBarTitle, setSelectedBarTitle] = useState("Last 6 months");
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [selectedCategoriesId, setSelectedCategoriesId] = useState(
    item.user_category_id
  );
  const [modals, setModals] = useState({
    viewTransaction: false,
  });
  const [search, setSearch] = useState("");
  const [allTransactionData, setAllTransactionData] = useState({});
  let isStartDate = "";
  let isEndDate = "";
  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  const handleOptionTopExpensesClick = async (
    option,
    name,
    startDate,
    endDate
  ) => {
    setPopupOpenTopExpenses(false);
    const matchedCategory = showCategory.find(
      (category) => category.userCategoryId === selectedCategoriesId
    );
    selectedCategoryData = matchedCategory;
    if (option === "Custom date range") {
      isStartDate = startDate;
      isEndDate = endDate;
      setExpensesBreakDown(name);
      await fetchCategoryDetailsData(option);
    } else {
      await fetchCategoryDetailsData(option);
      setExpensesBreakDown(option);
    }
  };

  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  let selectedCategoryData = {
    masterCategoryId: 0,
    userCategoryId: item.user_category_id,
    name: item.master_category_name,
    colour: item.master_category_colour,
    emoji: item.master_category_emoji,
  };

  const fetchCategoryDetailsData = async (option) => {
    setLoading(true);

    try {
      const payload =
        option.toLowerCase().replace(/\s+/g, "") === "customdaterange"
          ? {
              filter:
                option.toLowerCase().replace(/\s+/g, "") === "customdaterange"
                  ? "custom"
                  : option.toLowerCase().replace(/\s+/g, ""),
              startDate: moment(isStartDate).format("YYYY-MM-DD"),
              endDate: moment(isEndDate).format("YYYY-MM-DD"),
            }
          : {
              filter: option.toLowerCase().replace(/\s+/g, ""),
            };

      const response = await axios.post(
        activeTab === "Expenses"
          ? `${weburls.Get_Money_Trends_Expense_Category_Breakdown_Categoty_Details}${uuid}/${selectedCategoryData.userCategoryId}`
          : `${weburls.Get_Money_Trends_Income_Category_Breakdown_Categoty_Details}${uuid}/${selectedCategoryData.userCategoryId}`,
        payload,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const categoryBreakDownData = response?.data?.categoryBreakDownData || [];

      if (categoryBreakDownData.length > 0) {
        if (activeTab === "Expenses") {
          categoryBreakDownData[0].subcategories.sort(
            (a, b) => b.total - a.total
          );
        }
        setItemData(categoryBreakDownData[0]);
      } else {
        const val = {
          master_category_name: selectedCategoryData.name,
          master_category_emoji: selectedCategoryData.emoji,
          master_category_colour: selectedCategoryData.colour,
          transaction_count: "0",
          total: 0,
          subcategories: [],
          lastTotal: 0,
        };
        setItemData(val);
      }

      const totalAmount = response?.data?.lastSixMonth.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedBarAmount(totalAmount);
      setSelectedBarTitle("Last 6 months");

      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.transactions?.remainingMonths
      );
      setSelectedHistoryData(response?.data?.lastSixMonth);
      setAllTransactionData(response?.data?.transactions);
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
    fetchCategoryDetailsData(isExpensesBreakDown);
  }, []);

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      const excludeNames = ["Transfer", "One off", "Ignore"];
      if (activeTab === "Expenses") {
        const filteredExpenses =
          response?.data?.data?.transaction?.Expense.filter(
            (item) => !excludeNames.includes(item.name)
          );

        setShowCategory(filteredExpenses);
      } else {
        const filteredExpenses =
          response?.data?.data?.transaction?.INCOME.filter(
            (item) => !excludeNames.includes(item.name)
          );

        setShowCategory(filteredExpenses);
      }
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
      setLoading(false);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      await axios.post(`${weburls.Update_Category}${uuid}`, data, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      // toast.success(`${response?.data?.message}`);
      fetchCategoryDetailsData(isExpensesBreakDown);
    } catch (error) {}
  };
  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  useEffect(() => {
    searchTransaction();
  }, [search, allTransactionData]);

  const searchTransaction = () => {
    if (search) {
      const filterTransactions = (transactions) => {
        return transactions.filter(
          (transaction) =>
            transaction.transactionName?.toLowerCase().includes(search) ||
            transaction.merchantName?.toLowerCase().includes(search)
        );
      };

      const thisMonthTransactions = filterTransactions(
        allTransactionData?.thisMonth || []
      );
      const remainingMonthsTransactions = filterTransactions(
        allTransactionData?.remainingMonths || []
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        thisMonthTransactions
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        remainingMonthsTransactions
      );
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
    } else {
      const groupedCurrentMonth = groupTransactionsByDate(
        allTransactionData?.thisMonth || []
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        allTransactionData?.remainingMonths || []
      );

      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
    }
  };

  const splitbackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Back_To_Main_Transaction}${uuid}/${transactionUUid}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchCategoryDetailsData(isExpensesBreakDown);
    } catch (error) {}
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchCategoryDetailsData(isExpensesBreakDown);
    } catch (error) {}
  };

  const splitSingleBackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Single_Back_To_Main_Transaction}${uuid}/${transactionUUid}`,
        {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchCategoryDetailsData(isExpensesBreakDown);
    } catch (error) {}
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchCategoryDetailsData(isExpensesBreakDown);
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const handleBarClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const dataIndex = chartElements[0].index;
      const dataToProcess = selectedHistoryData;

      const totalAmount = dataToProcess[dataIndex].total_income_amount;
      setSelectedBarAmount(totalAmount);
      setSelectedBarTitle(dataToProcess[dataIndex].date);
    } else {
      const dataToProcess = selectedHistoryData;
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedBarAmount(totalAmount);
      setSelectedBarTitle("Last 6 months");
    }
  };
  Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    return {
      x: eventPosition.x,
      y: eventPosition.y,
      xAlign: "right",
      yAlign: "center",
    };
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, chartElements) => handleBarClick(event, chartElements),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        position: "myCustomPositioner",
        backgroundColor: "#ffffff",
        bodyColor: "#000000",
        titleColor: "#707070",
        displayColors: false,
        bodyFont: {
          size: 15,
          weight: "700",
          family: "Neurialgrotesk",
        },
        labelFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },
        titleFont: {
          size: 12,
          weight: "bold",
          family: "Neurialgrotesk",
        },
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;

            return `${selectedHistoryData[index].month || ""} total`;
          },

          label: function (tooltipItems) {
            return `$${formatNumberWithCommas(tooltipItems.raw)}`; /// GOPAL
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: "Arial, sans-serif",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  const barThicknessValue = () => {
    const labelCount = labelValue().length;
    if (labelCount === 1) return 500;

    const availableWidthPercentage = 600;
    const barWidth = availableWidthPercentage / labelCount;
    return barWidth;
  };

  const labelValue = () => {
    let value = [];
    const dataToProcess = selectedHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => {
        const monthName = e.month;
        const year = 2024;
        const date = new Date(`${monthName} ${year}`);

        if (!isNaN(date.getTime())) {
          const monthShortName = date.toLocaleString("default", {
            month: "short",
          });
          value.push(monthShortName);
        } else {
          console.error("Invalid date:", `${monthName} ${year}`);
        }
      });
    }
    return value;
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const barAmountValue = () => {
    let value = [];
    const dataToProcess = selectedHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => value.push(e.total_income_amount));
    }
    return value;
  };

  const dataBarChart = {
    labels: labelValue(),
    datasets: [
      {
        label: activeTab,
        data: barAmountValue(),
        backgroundColor: pieChartColor({
          colorCode: itemData.master_category_colour || "",
          name: itemData.master_category_name || "",
        }),
        borderColor: pieChartColor({
          colorCode: itemData.master_category_colour || "",
          name: itemData.master_category_name || "",
        }),
        borderWidth: 1,
        borderRadius: 5,
        barThickness: barThicknessValue(),
        maxBarThickness: barThicknessValue(),
        minBarLength: 2,
      },
    ],
  };

  return (
    <>
      <div className="app-main__outer your-merchants-main-wrapper">
        <div className="row">
          <PageTitle
            title={itemData.master_category_name}
            description={
              "Here’s a full breakdown to see whats happening with your money."
            }
          />
          <UserImageInfoToggle />
        </div>

        <div className="d-flex gap-4">
          <div className="money-insight-detail-left">
            <CategoryBreakdownSection
              togglePopupTopExpenses={togglePopupTopExpenses}
              isPopupOpenTopExpenses={isPopupOpenTopExpenses}
              selectedTopExpensesOption={isExpensesBreakDown}
              handleOptionTopExpensesClick={handleOptionTopExpensesClick}
              heading={"Category breakdown"}
            />
            <div
              className="acccount-balances-wrappper-left-side"
              style={{
                width: "100%",
                padding: "10px",
              }}
            >
              <CategoryBreakDownDetailsSingle
                item={itemData}
                isExpensesBreakDown={isExpensesBreakDown}
                activeTab={activeTab}
              />
            </div>
            <div
              className="chart-container mt-3"
              style={{
                height: "450px",
                width: "100%",
              }}
            >
              <div
                className="expense-summary-section"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2 style={{ margin: 0, fontSize: "22px" }}>
                    {selectedBarTitle}
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        backgroundColor: pieChartColor({
                          colorCode: itemData.master_category_colour || "",
                          name: itemData.master_category_name || "",
                        }),
                        marginRight: "8px",
                      }}
                    ></div>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      {itemData.master_category_name} spend
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                >
                  <span
                    style={{
                      color: activeTab === "Expenses" ? "#d04f4f" : "#469B88",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    ${formatValue(selectedBarAmount)}
                  </span>
                </div>
              </div>

              {/* Chart goes here */}
              <div
                className="bar-chart"
                style={{
                  height: "355px",
                  width: "100%",
                  paddingTop: "28px",
                }}
              >
                <Bar data={dataBarChart} options={options} />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <SectionTitle title={"Transactions"} />
            </div>
            <div className="account-detail-left-wrapper">
              {!isEmpty(currentMonthGrouped) ||
              !isEmpty(remainingMonthsGrouped) ? (
                <TransactionListing
                  data={{ ...currentMonthGrouped, ...remainingMonthsGrouped }}
                  modalOpen={openModal}
                  expenseCategories={category}
                  updateTransactionCategory={updateTransactionCategory}
                  //   addNewCategory={addNewCategory}
                  incomeCategories={icomecat}
                  oneOffCategory={oneOffCategory}
                  setSelectionMode={setSelectionMode}
                  isSelectionMode={isSelectionMode}
                  setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  //   selectedForBulkUpdate={selectedForBulkUpdate}
                  //   updateCategoriesInBulk={updateCategoriesInBulk}
                  categoryTitle={"Merchant categories"}
                  categorySubTitle={"Category"}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
          <div className="money-insight-detail-right sticky-sidebar">
            <div className="search-spacing filter-merchants-wrp-side">
              <div className="row">
                <div
                  className="col-md-7 col-lg-12 mt-4"
                  style={{ paddingTop: "4px" }}
                >
                  <SectionTitle title={"Search for transactions"} />
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search search-color-white"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <SectionTitle title={"Other categories"} />
              </div>
              <div className="merchants-background-color mt-1">
                {showCategory.length > 0 &&
                  showCategory.map((value) => (
                    <div
                      key={value.userCategoryId}
                      className="button-blck"
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className={`icon-tag ${
                          selectedCategoriesId === value.userCategoryId
                            ? "green"
                            : ""
                        }`}
                        onClick={() => {
                          selectedCategoryData = value;
                          setSelectedCategoriesId(value.userCategoryId);
                          fetchCategoryDetailsData(isExpensesBreakDown);
                        }}
                      >
                        {value.name !== "Uncategorised" && (
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(value.colour)
                                ? ""
                                : categoryBackgroundCss(value.colour)
                            }`}
                            style={{
                              background: categoryColorForAll(value.colour),
                            }}
                          >
                            {value.emoji}
                          </div>
                        )}
                        <div className="content">
                          <h6
                            className={`icon-content 
                              ${
                                selectedCategoriesId === value.userCategoryId
                                  ? "icon-white"
                                  : ""
                              }
                            `}
                          >
                            {value.name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          //   addTransaction={addTransaction}
          // accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          updateSingleCategory={updateSingleCategory}
          splitbackToMainTransaction={splitbackToMainTransaction}
          splitSingleBackToMainTransaction={splitSingleBackToMainTransaction}
          categoryTitle={"Merchant categories"}
          categorySubTitle={"Category"}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default CategoryBreakDownDetails;
