import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as UnselectedCheck } from "../../../assests/images/icons/unselected-check.svg";
import "./help_us_screen.css";
import weburls from "../../../Weburls/weburls";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { motion } from "framer-motion";

const HelpUsScreen = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const options = [
    { id: "plan-for-retirement", label: "Plan for retirement 🏝️" },
    { id: "manage-spending-budget", label: "Manage my spending & budget ⚖️" },
    {
      id: "build-savings-emergency-fund",
      label: "Build up my savings or emergency fund 🏠",
    },
    {
      id: "simplify-automate-finances",
      label: "Simplify & automate my finances 😎",
    },
    { id: "stay-on-top-of-cashflow", label: "Stay on top of my cashflow 💸" },
    { id: "track-my-net-worth", label: "Track my net worth 📈" },
    {
      id: "view-everything-in-one-place",
      label: "View everything in one place 📱",
    },
  ];

  const getRanking = (id) => selectedOptions.indexOf(id) + 1;

  const handleOptionClick = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      const newSelectedOptions = [...selectedOptions, id];
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleSubmit = async () => {
    if (selectedOptions.length !== options.length) {
      toast.warning("Please select all options before proceeding.");
      return;
    }
    setLoading(true);
    try {
      const userUuid = localStorage.getItem("userUuid");
      let tokenUser = localStorage.getItem("budget-token");
      if (!userUuid) {
        throw new Error("User  UUID not found");
      }

      const helpUsPayload = {
        helpus: selectedOptions.map((id, index) => {
          const option = options.find((option) => option.id === id);
          return {
            no: index + 1,
            // details: option.label.replace(/\s*[^\w\s&]*$/, ""), // Remove emoji
            details: option.label,
          };
        }),
      };

      const helpUsResponse = await axios.post(
        `${weburls.Create_Help_Us_Help_You}${userUuid}`,
        helpUsPayload,
        {
          headers: {
            authorization: `Basic ${tokenUser}`,
          },
        }
      );

      if (helpUsResponse.data.code === 1) {
        const setupResponse = await axios.put(
          `${weburls.Set_Setup}${userUuid}`,
          {},
          {
            headers: {
              authorization: `Basic ${tokenUser}`,
            },
          }
        );

        navigate("/sign-up/connect-accounts");
      } else {
        throw new Error(
          helpUsResponse.data.message || "Failed to submit help us data"
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Error in submission:", error);
      toast.error("Failed to submit your preferences. Please try again.");
    }
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{
                  borderRadius: "20px",
                  padding: "20px 30px 20px 30px",
                  width: "450px",
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                <div className="transaction-categories mt-2">
                  <p
                    style={{
                      fontSize: "26px",
                      marginBottom: "10px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "#240F51",
                    }}
                  >
                    {"Help us, help you 🙌🏻"}
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "20px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {
                      "Rank your financial priorities from 1-7 below and we'll help you achieve them. "
                    }
                  </p>
                  <div
                    className="feedback-options"
                    style={{
                      maxHeight: "600px",
                      overflow: "auto",
                    }}
                  >
                    {options.map((option) => (
                      <div
                        key={option.id}
                        className="feedback-option"
                        onClick={() => handleOptionClick(option.id)}
                        style={{
                          border: `2px solid ${
                            selectedOptions.includes(option.id)
                              ? "#14B8A6"
                              : "#E4E4E7"
                          }`,
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className=""
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#18181B",
                          }}
                        >
                          {option.label}
                        </p>
                        {selectedOptions.includes(option.id) ? (
                          <span
                            style={{
                              fontWeight: "600",
                              color: "white",
                              background: "#14B8A6",
                              borderRadius: "10px",
                              width: "20px",
                              height: "20px",
                              textAlign: "center",
                              fontSize: "12px",
                              justifyContent: "center",
                              display: "flex",
                              paddingTop: "1px",
                            }}
                          >
                            {getRanking(option.id)}
                          </span>
                        ) : (
                          <UnselectedCheck />
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="modal-popup-btn-wrp">
                    <button
                      className={`cursor-pointer change-password-btn`}
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      {loading ? (
                        <ClipLoader size={15} color={"#ffffff"} />
                      ) : (
                        "Connect your accounts"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpUsScreen;
