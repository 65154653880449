import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import weburls from "../../Weburls/weburls";
import { timeRangeOptions } from "../../utilities/Statics";
import axiosService from "../../client/AxiosInstance";

const useExpenseCard = ({ selectedExpensesOption, uuid }) => {
  console.log(selectedExpensesOption, uuid, "data");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getExpenseStats = useCallback(async () => {
    const rangeValue = timeRangeOptions?.find(
      (item) => item.label === selectedExpensesOption
    );
    const payload = {
      filter: selectedExpensesOption,
      startDate: "2024-12-01",
      endDate: "2024-12-18",
    };

    try {
      const response = await axiosService.post(
        `${weburls.Get_Expenses_Total}${uuid}`,
        payload
        // {
        //   filter: "custom",
        //   startDate: "2024-12-01",
        //   endDate: "2024-12-18",
        // }
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getExpenseStats();
  }, [getExpenseStats, selectedExpensesOption]);

  return { data, loading, error };
};

export default useExpenseCard;
