import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import weburls from "../../Weburls/weburls";
import { parseMerchantsData, planType } from "../../utilities/helper";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import PageTitle from "../../Components/PageTitle";
import { MerchantListing } from "./MerchantListing";
import MerchantDetail from "../../ModalForm/MerchantDetail/MerchantDetail";
import {
  merchantCategoriesOpts,
  mertchantSortOpts,
  moduleInfoPopUpData,
} from "../../utilities/Statics";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import FilterTags from "../../Components/FilterTags";
import SectionTitle from "../../Components/SectionTitle.jsx";
import { PopUpWhoops } from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";

const Merchants = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [merchant, setMerchants] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterBySpend, setFilterBySpend] = useState("All");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [merchantCategorized, setMerchantCategorized] = useState(0);
  const [merchantUnCategorized, setMerchantUnCategorized] = useState(0);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [sortedBy, setSortedBy] = useState("");
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [loading, setLoading] = useState(false);
  const [oneTimeOTP, setOneTimeOTP] = useState();
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [modals, setModals] = useState({
    merchantDetails: false,
    viewTransaction: false,
    addCategory: false,
  });
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [merchantTransactions, setMerchantTransactions] = useState([]);
  const [search, setSearch] = useState("");
  const [whoopsPopUp, setWhoopsPopUp] = useState(false);
  const [filteredMerchants, setFilteredMerchants] = useState([]);
  const navigate = useNavigate();
  const [planDetails, setPlanDetails] = useState(null);
  const userId = localStorage.getItem("userUuid");
  const [initialMerchants, setInitialMerchants] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.username;

  const fetchedPlanData = async () => {
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Get_Plan_Details, body, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setPlanDetails(response.data);

      if (response.data) {
        const planCategory = planType(response.data.planType);

        if (planCategory === "Basic" || planCategory === "Freemium") {
          setWhoopsPopUp(true);
        }
      }
    } catch (error) {
      console.error("Error fetching plan details:", error);
    }
  };

  const oneTimeLogin = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(weburls.OneTime_Login + `${uuid}`);
    if (response?.data) {
      setOneTimeOTP(response?.data.oneTimeToken);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  useEffect(() => {
    oneTimeLogin();
    fetchedPlanData();
  }, []);

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const openModal = (modal) => {
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const handleTransactionClick = (data) => {
    setSelectedTransaction(data);
    openModal("viewTransaction");
  };

  const handleMerchantDetailModal = (data) => {
    setSelectedMerchant(data);
    fetchMerchantTransactions();
  };

  const getMerchantsByClientBy = useCallback(async () => {
    setLoading(true);
    const data = {
      categoryIds: [...selectedExpenseCategories],
      isSort: sortedBy,
      categories: filterBySpend,
    };

    try {
      const response = await axios.post(
        `${weburls.Get_Merchats_By_ClientId}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const parsedData = parseMerchantsData(response?.data?.merchants);
      const sortedData = applySorting(parsedData);
      setMerchants(sortedData);
      setMerchantUnCategorized(response?.data?.merchantUncategorized);
      setMerchantCategorized(response?.data?.merchantCategorized);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  }, [uuid, selectedExpenseCategories, sortedBy, filterBySpend]);

  const fetchMerchantTransactions = async () => {
    setLoading(true);
    const { merchantId } = selectedMerchant;
    try {
      const response = await axios.get(
        `${weburls.Get_Transaction_By_Merchants_Id}${uuid}/${merchantId}/100/1?name=`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const { remainingMonths, thisMonth } = response.data.data.transactions;
      const combineTransactionData = [...remainingMonths, ...thisMonth];
      setMerchantTransactions(combineTransactionData);
      openModal("merchantDetails");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setCategory(response?.data?.data?.transaction.Expense);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  useEffect(() => {
    getMerchantsByClientBy();
    getCategory();
  }, [getMerchantsByClientBy, getCategory]);

  useEffect(() => {
    if (merchant.length && !initialMerchants.length) {
      setInitialMerchants(merchant);
    }

    if (search) {
      const filtered = merchant.filter((merch) =>
        merch.name.toLowerCase().includes(search.toLowerCase())
      );
      const sortedMerchants = applySorting(filtered);
      setFilteredMerchants(sortedMerchants);
    } else {
      const sortedMerchants = applySorting(merchant);
      setFilteredMerchants(sortedMerchants);
    }
  }, [search, merchant, sortedBy]);

  const applySorting = (merchants) => {
    switch (sortedBy) {
      case "Name A-Z":
        return [...merchants].sort((a, b) => a.name.localeCompare(b.name));
      case "Spend lowest to highest":
        return [...merchants].sort((a, b) => b.amount - a.amount);
      case "Spend highest to lowest":
        return [...merchants].sort((a, b) => a.amount - b.amount);
      default:
        return merchants;
    }
  };

  const handleSortToggle = (option) => {
    if (sortedBy === option) {
      setSortedBy("");
      setFilteredMerchants(initialMerchants);
    } else {
      setSortedBy(option);
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchMerchantTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const updateMerchantCategory = async (transactionId, categoryId) => {
    const data = {
      merchantUuid: transactionId,
      categoryId: categoryId,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Merchant_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      getMerchantsByClientBy();
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong while updating category...");
      setLoading(false);
    }
  };

  const addNewCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Something went wrong while adding expense category");
    }
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSelectedExpenseCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id)
          : [...prev, id];

        return newSelection.length > 0 ? newSelection : [];
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="app-main__outer your-merchants-main-wrapper">
        <div className="row">
          <PageTitle
            title={"Your Merchants"}
            description={
              "Group your spending into categories to get an more confidence with your expenses."
            }
          />
          <UserImageInfoToggle
            isPopupOpenInfo={isPopupOpenInfo}
            togglePopupInfo={togglePopupInfo}
            title={moduleInfoPopUpData.merchants.title}
            description={moduleInfoPopUpData.merchants.description}
          />
        </div>
        <div className="d-flex gap-4 mt-5">
          <div className="account-detail-left">
            <div className="d-flex align-items-center justify-content-between">
              <SectionTitle title={"Merchants"} />
              <div className="d-flex gap-1">
                <span className="transaction-count transaction-count-green d-flex align-items-center justify-content-center">
                  {merchantCategorized}
                </span>
                <span className="transaction-count transaction-count-red  d-flex align-items-center justify-content-center">
                  {merchantUnCategorized}
                </span>
              </div>
            </div>
            <div className="account-detail-left-wrapper">
              {filteredMerchants.map((merchant) => (
                <MerchantListing
                  key={merchant.merchantsUuid}
                  merchant={merchant}
                  onClick={handleMerchantDetailModal}
                  merchantCategories={category}
                  oneOffCategory={oneOffCategory}
                  updateMerchantCategory={updateMerchantCategory}
                  addNewCategory={addNewCategory}
                />
              ))}
            </div>
          </div>
          <div className="account-detail-right sticky-sidebar">
            <div className="search-spacing filter-merchants-wrp-side">
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Search for merchants"} />
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search search-color-white"
                          onChange={(e) => handleSearch(e)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <SectionTitle title={"Filter & sort merchants"} />
              </div>

              <div className="merchants-background-color mt-1">
                <FilterTags
                  title={"Merchant categories"}
                  categories={category}
                  selectedCategories={selectedExpenseCategories}
                  setSelectedCategories={setSelectedExpenseCategories}
                  handleTagClick={handleTagClick}
                  type={"expense"}
                />
                <div className="bold live-connection mt-3">Categories</div>
                <div className="account-date-group green-btn">
                  {merchantCategoriesOpts.map((option) => (
                    <div className="button-blck" key={option}>
                      <button
                        className={`account-date-btn ${
                          filterBySpend === option ? "green" : ""
                        }`}
                        onClick={() => setFilterBySpend(option)}
                      >
                        {option}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="bold live-connection mt-3">Sort Merchants</div>
                <div className="account-date-group green-btn">
                  {mertchantSortOpts.map((option) => (
                    <div className="button-blck" key={option}>
                      <button
                        className={`account-date-btn ${
                          sortedBy === option ? "green" : ""
                        }`}
                        onClick={() => handleSortToggle(option)}
                      >
                        {option === "Un-categorised"
                          ? "Un Categorised"
                          : option}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {whoopsPopUp && (
        <PopUpWhoops
          planDetails={planDetails}
          oneTimeOTP={oneTimeOTP}
          onClose={() => {
            setWhoopsPopUp(false);
            navigate(`/budget/${uuid}`);
          }}
          uuid={uuid}
        />
      )}
      {modals.merchantDetails && !loading && (
        <MerchantDetail
          modalOpen={modals.merchantDetails}
          closeModal={closeModal}
          merchant={selectedMerchant}
          merchantTransactions={merchantTransactions}
          handleTransactionClick={handleTransactionClick}
        />
      )}
      {modals.viewTransaction && (
        <TransactionViewModal
          modalOpen={modals.viewTransaction}
          data={selectedTransaction}
          closeModal={closeModal}
          splitTransaction={splitTransaction}
        />
      )}

      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Merchants;
