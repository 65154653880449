import React, { useState } from "react";
import { motion } from "framer-motion";
import PopupModal from "../../ModalForm/Popup/PopupModel";

const spendBudgetDummy = [
  {
    id: 1,
    name: "Groceries",
    value: "$699",
    left: "$575",
    color: "#E0533D",
    opacity: "rgba(224, 83, 61, .3)",
  },
  {
    id: 2,
    name: "Clothes",
    value: "$199",
    left: "$272",
    color: "#E78C9D",
    opacity: "rgba(231, 140, 157, .3)",
  },
  {
    id: 3,
    name: "House",
    value: "$800",
    left: "$453",
    color: "#377CC8",
    opacity: "rgba(55, 124, 200, .3)",
  },
  {
    id: 4,
    name: "House",
    value: "$800",
    left: "$453",
    color: "#377CC8",
    opacity: "rgba(55, 124, 200, .3)",
  },
  {
    id: 5,
    name: "Car",
    value: "$200",
    left: "$575",
    color: "#EED868",
    opacity: "rgba(238, 216, 104, .3)",
  },
  {
    id: 6,
    name: "Car",
    value: "$200",
    left: "$575",
    color: "#68EE85",
    opacity: "rgba(104, 238, 133, .3)",
  },
  {
    id: 7,
    name: "Car",
    value: "$200",
    left: "$575",
    color: "#EE68E1",
    opacity: "rgba(238, 104, 225, .3)",
  },
  {
    id: 8,
    name: "Car",
    value: "$200",
    left: "$575",
    color: "#EE6868",
    opacity: "rgba(238, 104, 104, .3)",
  },
];

const SpentBudget = () => {
  const [selectSpentOption, setSelectSpentOption] = useState("This Week");

  const [spentPopOver, setSpentsPopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectSpentOption(option);
    setSpentsPopOver(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal">Spend vs budget</h4>
        <span
          className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ width: "100px", height: "25px", borderRadius: "50px" }}
          id="SpentPopover"
          onClick={() => setSpentsPopOver(!spentPopOver)}
          aria-haspopup="true"
          aria-expanded={spentPopOver}
        >
          This week
        </span>
        {spentPopOver && (
          <PopupModal
            isOpen={spentPopOver}
            onClose={() => setSpentsPopOver(!spentPopOver)}
            targetId="SpentPopover"
            title="Date range"
            options={[
              "This week",
              "This month",
              "This year",
              "Last week",
              "Last month",
            ]}
            selectedOption={spentPopOver}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>

      <div className="row">
        {spendBudgetDummy?.map((item) => {
          return (
            <>
              <div key={item?.id} className="col-md-3 mb-3">
                <div className="budget-item br-10 bg-white">
                  <div className="budget-label d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                      <div>
                        <h6 className="fs-16 fw-400 lh-24 text-medium-gray">
                          {item?.name}
                        </h6>
                        <h4 className="fs-18 fw-700 lh-25 text-dark-gray">
                          {item?.value}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="budget-remaining d-flex justify-content-end fs-12 fw-400">
                    <>
                      <span className="fs-14 fw-400 lh-20 text-dark-gray">
                        {item?.left} left
                      </span>
                    </>
                  </div>
                  <div
                    className={`budget-progress-container w-100 position-relative`}
                    style={{
                      backgroundColor: item?.opacity,
                    }}
                  >
                    <motion.div
                      className={`budget-progress `}
                      style={{
                        width: `${12}%`,
                        backgroundColor: item?.color,
                      }}
                      initial={{ width: 0 }}
                      animate={{ width: `${25}%` }}
                      transition={{ duration: 0.5 }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></motion.div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SpentBudget;
