import React from "react";
import { Link } from "react-router-dom";
import Bleached from "../../assests/images/icons/Bleached Cafe.png";
import moment from "moment";
import { formatNumberWithCommas } from "../../utilities/helper";

const InfoCard = ({ amount, name, avatar, isEmoji, isLiability }) => {
  return (
    <div className="info-card-container">
      <Link>
        <div className="d-flex align-items-center">
          {isEmoji ? (
            <div className="fs-30">{avatar}</div>
          ) : (
            <div className="img-box">
              <img
                src={Bleached}
                alt=""
                style={{
                  borderRadius: "50%",
                }}
              />
            </div>
          )}
          <div className="ms-3">
            <div className="text-merchant black">{name}</div>
            {/* <div className="small-text-merchant">
            <span className="number-merchant">
              ${"100,00"}
            </span>
            <span className="black">|</span>
            <h4 className="small-text-merchant space black">
              <div className="setting_text_color-2">
                {moment("2024-07-24T14:07:41.656Z").format(
                  "DD/MM/YYYY"
                )}
              </div>
            </h4>
          </div> */}
          </div>
        </div>
      </Link>

      <div className="button-blck">
        <div
          className={`selected-amount ${
            isLiability ? "amount-red" : "amount-green"
          }`}
        >
          ${formatNumberWithCommas(amount)}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
