import React from "react";
import { Doughnut } from "react-chartjs-2";

const doughnutOptions = {
  responsive: true,
  cutout: "70%",
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        font: {
          size: 14,
        },
        color: "#333",
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: "#333",
      titleColor: "#fff",
    },
    datalabels: {
      color: "#fff",
      formatter: (value) => `${value}%`,
      font: {
        size: 14,
        weight: "bold",
      },
      align: "center",
      anchor: "center",
    },
  },
};

const centerTextPlugin = {
  id: "centerTextPlugin",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    const totalValue = "$4,964";

    ctx.save();
    ctx.font = "16px Arial";
    ctx.fillStyle = "#888";
    ctx.textAlign = "center";
    ctx.fillText("Total Expenses", width / 2, height / 2 - 10);

    ctx.font = "bold 28px Arial";
    ctx.fillStyle = "#000";
    ctx.fillText(totalValue, width / 2, height / 2 + 20);
    ctx.restore();
  },
};

const BreakDownDoughnut = (props) => {
  const { data } = props;
  return (
    <>
      <Doughnut
        data={data}
        options={doughnutOptions}
        plugins={[centerTextPlugin]}
      />
    </>
  );
};

export default BreakDownDoughnut;
