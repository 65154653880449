import axios from "axios";
import { toast } from "react-toastify";

const BudgetBuddieAkahuAPI = process.env.REACT_APP_AKAHU_API_URL;

const axiosAkahuService = axios.create({
  baseURL: BudgetBuddieAkahuAPI,
  headers: {
    "Content-Type": "application/json",
  },
});


const getAuthToken = () => {
  return localStorage.getItem("budget-token") || "";
};

// Request interceptor to attach the token
axiosAkahuService.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["authorization"] = `Basic ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle all edge cases
axiosAkahuService.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  async (error) => {
    // Handle errors
    if (!error.response) {
      console.error("Network error or request was canceled:", error.message);
      toast.error("Network error. Please check your connection.");
    } else {
      const { status, data } = error.response;

      switch (status) {
        case 400:
          console.error("Bad Request:", data?.message || "Invalid request.");
          toast.error(data?.message || "Bad request. Please check your input.");
          break;

        case 401:
          console.warn("Unauthorized. Redirecting to login.");
          toast.error("Session expired. Please log in again.");
          window.location.href = "/";
          break;

        case 403:
          console.error("Forbidden:", data?.message || "Access denied.");
          toast.error("You do not have permission to perform this action.");
          break;

        case 404:
          console.error("Not Found:", data?.message || "Resource not found.");
          toast.error("Requested resource not found.");
          break;

        case 500:
          console.error(
            "Internal Server Error:",
            data?.message || "Something went wrong."
          );
          toast.error("Server error. Please try again later.");
          break;

        default:
          console.error(`Unhandled HTTP error (${status}):`, data?.message);
          toast.error(data?.message || "An unexpected error occurred.");
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosAkahuService;
