import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import weburls from "../../Weburls/weburls";
import axiosService from "../../client/AxiosInstance";


const useIncomeCard = ({ selectedIncomeOption, uuid }) => {
  console.log(selectedIncomeOption, uuid, "data");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getIncomeStats = useCallback(async () => {
    try {
      const response = await axiosService.post(
        `${weburls.Get_Income_Total}${uuid}`,
        {
          filter: "custom",
          startDate: "2024-12-01",
          endDate: "2024-12-18",
        }
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getIncomeStats();
  }, [getIncomeStats, selectedIncomeOption]);

  return { data, loading, error };
};

export default useIncomeCard;
