import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer } from "react-toastify";
import "../../ModalForm/AddIncome/addIncome.css";
import moment from "moment";

const PopupManageConnection = ({
  modalOpen,
  closeModal,
  data,
  revokeConnection,
}) => {
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleDisconnect = async (akahuAccountId, akahuAccountName, index) => {
    setLoadingIndex(index);
    try {
      await revokeConnection(akahuAccountId, akahuAccountName);
    } catch (error) {
      console.error("Failed to disconnect account:", error);
    } finally {
      setLoadingIndex(null);
    }
  };

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="connection-modal-content delete-modal-width">
        <div className="mng-titile-pd">
          <button
            onClick={() => closeModal("manageConnections")}
            className="close-pass-button"
          >
            <i class="fa fa-times"></i>
          </button>
          <div className="d-flex justify-content-between">
            <div className="fs-3 connect-title-mb">
              <span className="bold black password-heading">
                Manage connection
              </span>
            </div>
          </div>
        </div>

        <span className="bold black connection-heading">
          Connected accounts
        </span>
        <div className="connection-modal-list">
          {data?.length &&
            data?.map((item, index) => (
              <>
                <div className="your-accounts mt-2" key={index}>
                  <div className="d-flex">
                    <div
                      className="investment-connection-img"
                      style={{ width: "40px" }}
                    >
                      <img
                        className="mt-1"
                        src={item?.img}
                        alt=""
                        style={{ objectFit: "contain", borderRadius: "20px" }}
                      />
                    </div>
                    <div className="ms-3 lcs-inner">
                      <div className="live-connection">{item?.accountName}</div>
                      <div className="setting_text_color-2">
                        Updated:{" "}
                        {moment(item.refreshDate)
                          .utcOffset(-13 * 60)
                          .format("DD/MM/YYYY hh:mm A")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="connection-status"
                    onClick={() =>
                      handleDisconnect(
                        item?.akahuAccountId,
                        item?.akahuAccountName,
                        index
                      )
                    }
                  >
                    {loadingIndex === index ? (
                      <div className="cupertino-loader"></div>
                    ) : (
                      "Disconnect"
                    )}
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className="flex-content-center">
          <div className="connect-btn">Manage Connection</div>
        </div>
      </div>
    </div>
  );
};

export default PopupManageConnection;
