import React from "react";
import { Popover, PopoverBody } from "reactstrap";

const PopupModal = ({
  isOpen,
  onClose,
  targetId,
  title,
  options,
  selectedOption,
  handleOptionClick,
}) => {
  return (
    isOpen && (
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target={targetId}
        toggle={onClose}
        className="custom-popover-w-30"
      >
        <PopoverBody>
          <div className="d-flex justify-content-between align-items-center flex-row">
            <div className="">
              <span className="bold black popup-top-heading">{title}</span>
            </div>
            <button onClick={onClose} className="close-modal-button">
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="group-backgroud-color">
            <div
              className="bold black mb-3"
              style={{ float: "left", fontSize: "14px" }}
            >
              Periods
            </div>
            <div
              className="group-btn green-btn popup-padding-zero"
              style={{ float: "left", paddingBottom: "25px", width:"100%" }}
            >
              {options.length > 0 && options.map((option) => (
                <div key={option} className="button-blck">
                  <a
                    href="#"
                    className={`group-button `}
                    onClick={() => handleOptionClick(option)}
                    style={{
                      backgroundColor:
                        selectedOption === option ? "#469B88" : "",
                      color: selectedOption === option ? "white" : "black",
                      border: "1px solid #E1E1E1",
                      margin: "4px 8px 4px 0px",
                    }}
                  >
                    {option}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    )
  );
};

export default PopupModal;
