import { isEmpty } from "lodash";
import Amount from "../Amount.jsx";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import "./Moneyinsights.css";
import { checkColor, formatNumberWithCommas } from "../../utilities/helper.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

export const AccordionMoneyInsights = ({
  avatar,
  accountName,
  value,
  accountNumber,
  details,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      className={`accordion-item ${isOpen ? "open" : ""}`}
      onClick={onToggle}
    >
      <div className="accordion-header">
        <div>
          <img
            src={!isEmpty(avatar) ? avatar : Logo}
            alt=""
            className="account-img"
          />
        </div>
        <div className="account-text">
          <div className="account-name">{accountName}</div>
          <div className="account-subtext">{accountNumber}</div>
        </div>
        <div className="account-value">
          <Amount amount={value} />
        </div>
      </div>

      {/* Accordion content */}
      <div className={`accordion-content ${isOpen ? "expanded" : ""}`}>
        <div className="accordion-inner">
          {details.map((detail, idx) => (
            <div key={idx} className="detail-item">
              <span>{detail.label}</span>
              <span>${detail.value.toFixed(2)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AmountIncomeExpenses = ({ activeTab, amount }) => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = formatNumberWithCommas(absoluteAmount);
  const displayAmount = isNegative
    ? `-$${formattedAmount}`
    : `$${formattedAmount}`;
  return (
    <span className={activeTab === "Expenses" ? "amount-red" : "amount-green"}>
      {displayAmount}
    </span>
  );
};

const calculatePercentage = ({ month, firstVal, isIncome, secondVal }) => {
  let difference = 0.0;

  // Calculate the difference percentage
  if (secondVal === "0.0" || secondVal === "0") {
    difference = 0.0;
  } else {
    difference =
      ((parseFloat(firstVal) - parseFloat(secondVal)) / parseFloat(secondVal)) *
      100;
  }

  // Determine the comparison period
  let periods = "";
  switch (month) {
    case "This week":
      periods = "last week";
      break;
    case "This month":
      periods = "last month";
      break;
    case "This quarter":
      periods = "last quarter";
      break;
    case "This year":
      periods = "last year";
      break;
    case "Last week":
      periods = "the week prior";
      break;
    case "Last month":
      periods = "the month prior";
      break;
    case "Last quarter":
      periods = "the quarter prior";
      break;
    default:
      periods = ""; // Default empty if no match
      break;
  }

  // Handle return message
  if (secondVal === "0.0" || secondVal === "0") {
    return `No ${isIncome ? "income" : "spend"} ${periods}`;
  } else {
    if (difference > 0) {
      return `Up ${difference.toFixed(0)}% vs ${periods}`;
    } else {
      return `Down ${Math.abs(difference.toFixed(0))}% vs ${periods}`;
    }
  }
};

export const ExpensesCategoryBreakDownList = ({
  item,
  isExpensesBreakDown,
  activeTab,
}) => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    if (activeTab === "Expenses") {
      item.subcategories.sort((a, b) => b.total - a.total);
    }
    navigate(`/CategoryBreakDownDetails/${uuid}`, {
      state: {
        item: item,
        activeTab: activeTab,
        isExpensesBreakDown: isExpensesBreakDown,
      },
    });
  };

  return (
    <div
      className="according-item-money"
      onClick={handleClick}
      style={{
        height: "min-content",
      }}
    >
      <div className="accordion-header">
        <div
          style={{
            backgroundColor: checkColor({
              colorCode: item.master_category_colour || "",
              name: item.master_category_name || "",
            }),
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
            marginRight: "10px",
          }}
        >
          {item.master_category_emoji || "❗️"}
        </div>
        <div className="account-text">
          <div className="account-name">{item.master_category_name}</div>
          <div className="account-subtext">
            {isExpensesBreakDown !== "This year" &&
              !isExpensesBreakDown.includes("-") &&
              item.master_category_name !== "Uncategorised" &&
              calculatePercentage({
                month: isExpensesBreakDown,
                firstVal: item.total || "0",
                isIncome: false,
                secondVal: item.lastTotal || "0",
              })}
            {item.master_category_name === "Uncategorised" ||
            isExpensesBreakDown.includes("-")
              ? `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`
              : (isExpensesBreakDown === "This year" ||
                  isExpensesBreakDown.includes("-")) &&
                (item.transaction_count || 0) !== 0
              ? `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`
              : null}
          </div>
        </div>
        <div className={"account-value"}>
          <AmountIncomeExpenses
            amount={item.total || 0}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export const CategoryBreakDownDetailsSingle = ({
  item,
  isExpensesBreakDown,
  activeTab,
}) => {
  const [openCard, setOpenCard] = useState(false);

  const handleClick = () => {
    setOpenCard(!openCard);
  };

  return (
    <div
      className="according-item-money"
      style={{
        width: "100%",
        marginBottom: "0px",
      }}
      onClick={handleClick}
    >
      <div className="accordion-header">
        <div
          style={{
            backgroundColor: checkColor({
              colorCode: item.master_category_colour || "",
              name: item.master_category_name || "",
            }),
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            color: "#fff",
            marginRight: "10px",
          }}
        >
          {item.master_category_emoji || "❗️"}
        </div>
        <div className="account-text">
          <div className="account-name">{item.master_category_name}</div>
          <div className="account-subtext">
            {isExpensesBreakDown !== "This year" &&
            !isExpensesBreakDown.includes("-")
              ? item.master_category_name !== "Uncategorised"
                ? calculatePercentage({
                    month: isExpensesBreakDown,
                    firstVal: item.total || "0",
                    isIncome: false,
                    secondVal: item.lastTotal || "0",
                  })
                : null
              : null}
            {(() => {
              if (
                item.master_category_name === "Uncategorised" ||
                isExpensesBreakDown.includes("-")
              ) {
                return `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`;
              } else if (
                (isExpensesBreakDown === "This year" ||
                  isExpensesBreakDown.includes("-")) &&
                (item.transaction_count || 0) !== 0
              ) {
                return `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`;
              }
              return null;
            })()}
          </div>
        </div>
        <div className={"account-value"}>
          <AmountIncomeExpenses
            amount={item.total || 0}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className={`accordion-content ${openCard ? "expanded" : ""}`}>
        <div
          className="accordion-inner"
          style={{
            paddingTop: "10px",
          }}
        >
          {activeTab === "Expenses" &&
            item.subcategories.map((detail, idx) => (
              <div key={idx}>
                <div className="detail-item">
                  <span>{detail.name}</span>
                  <span style={{ color: "#d04f4f", fontWeight: "bold" }}>
                    ${detail.total.toFixed(2)}
                  </span>
                </div>
                {idx < item.subcategories.length - 1 && (
                  <div className="divider" />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const MerchantCategoryBreakDownList = ({
  item,
  isMerchantBreakDown,
  activeTab,
  merchatsList,
}) => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/MerchantBreakDownDetails/${uuid}`, {
      state: {
        item: item,
        activeTab: activeTab,
        isMerchantBreakDown: isMerchantBreakDown,
        merchatsList: merchatsList,
      },
    });
  };

  return (
    <div className="merchant-according-item-money" onClick={handleClick}>
      <div className="accordion-header">
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            color: "#fff",
            marginRight: "15px",
          }}
        >
          <img
            src={item.merchant_logo}
            alt=""
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="account-text">
          <div className="account-name">{item.merchant_name}</div>
          <div className="account-subtext">
            {isMerchantBreakDown.includes("-")
              ? `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`
              : calculatePercentage({
                  month: isMerchantBreakDown,
                  firstVal: item.total || "0",
                  isIncome: false,
                  secondVal: item.lastTotal || "0",
                })}
          </div>
        </div>
        <div className={"account-value"}>
          <AmountIncomeExpenses
            amount={item.total_amount || 0}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export const MerchantCategoryBreakDownSingle = ({
  item,
  isMerchantBreakDown,
  activeTab,
}) => {
  return (
    <div
      className="merchant-according-item-money"
      style={{
        width: "100%",
        marginBottom: "0px",
      }}
    >
      <div className="accordion-header">
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            color: "#fff",
            marginRight: "15px",
          }}
        >
          <img
            src={item.merchant_logo}
            alt=""
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="account-text">
          <div className="account-name">{item.merchant_name}</div>
          <div className="account-subtext">
            {isMerchantBreakDown.includes("-")
              ? `${item.transaction_count || "0"} ${
                  (item.transaction_count || 0).toString() === "1"
                    ? "Transaction"
                    : "Transactions"
                }`
              : calculatePercentage({
                  month: isMerchantBreakDown,
                  firstVal: item.total || "0",
                  isIncome: false,
                  secondVal: item.lastTotal || "0",
                })}
          </div>
        </div>
        <div className={"account-value"}>
          <AmountIncomeExpenses
            amount={item.total_amount || 0}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};
