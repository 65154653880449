import React, { useCallback, useEffect, useRef, useState } from "react";
import AddCategoriesModal from "../../../../ModalForm/TransactionModals/AddCategoriesModal";
import IncomeExpenseCategories from "./IncomeExpenseCategories";
import axios from "axios";
import weburls from "../../../../Weburls/weburls";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Wang } from "../../../../assests/images/icons/wand.svg";
import { categoryColorForAll } from "../../../../utilities/Statics";
import { API_ENDPOINTS } from "../../../../client/ApiEndpoints";
import HttpClient from "../../../../client/HttpClient";

const EditDetails = ({
  amount,
  handleAmountChange,
  selectedFrequency,
  toggleFrequencyModal,
  isSpreadToggled,
  getButtonBackgroundColor,
  handleToggleClick,
  needOrWant,
  handleNeedToggleClick,
  incomeExpenseBudgetsData,
  incomeExpenseBudgetsName,
  showCategory,
  expenseOrIncome,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  isFrequencyModalOpen,
  mainHead,
  setIncomeApi,
  setExpenseApi,
  autoExpense,
  autoSingleBudgetExpenses,
  handleNameChange,
  editName,
  setType,
  setChosenEmoji,
  setSelectedColorOption,
  setMainSelectedCategory,
  mainSelectedCategory,
  selectedPeriod,
  setSelectedFrequency,
  EditDetails,
}) => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const endDateRef = useRef(null);
  const [showNeedOrWant, setShowNeedOrWant] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    if (incomeExpenseBudgetsName === "expense") {
      setShowNeedOrWant(true);
    } else {
      setShowNeedOrWant(false);
    }
  }, [incomeExpenseBudgetsName]);

  useEffect(() => {
    if (isStartDateSelected && endDateRef.current) {
      endDateRef.current.focus();
    }
  }, [isStartDateSelected]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setIsStartDateSelected(true);
    setErrorMessage("");
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;

    if (new Date(selectedEndDate) < new Date(startDate)) {
      setErrorMessage("End date cannot be earlier than start date");
    } else {
      setEndDate(selectedEndDate);
      setErrorMessage("");
    }
  };

  const handleDateRangeClick = () => {
    setStartDate("");
    setEndDate("");
    setIsStartDateSelected(false);
    setErrorMessage("");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${day}/${month}`;
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleCategoryClick = (category) => {
    setMainSelectedCategory(category);
    setIncomeApi({
      incomeName: category?.categoryName,
      user_category_id: category?.categoryId,
      isDefaultCategory: category?.defaultCategory || "",
    });
    setExpenseApi({
      expenseName: category?.categoryName,
      user_category_id: category?.categoryId,
      isDefaultCategory: category?.defaultCategory,
    });
    togglePopover();
  };

  const handleAddCategory = () => {
    // setPopoverOpen((prevState) => ({
    //   ...prevState,
    // }));

    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
    togglePopover();
  };

  const defaultCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? {
          expense: [...(categoryData?.expense || [])],
          yourExpense: [...(categoryData?.yourExpense || [])],
        }
      : { income: categoryData?.income || [] };

  const yourCreatedCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? {
          customCategory: [...(categoryData?.customCategory || [])],
          yourCustomCategory: [...(categoryData?.yourCustomCategory || [])],
        }
      : { customCategory: categoryData?.customCategory || [] };

  // API Calls starts
  const incomeExpenseCategory = async () => {
    try {
      const url =
        incomeExpenseBudgetsName === "expense"
          ? `${API_ENDPOINTS.GET_EXPENSE_REMAINING_BY_UUID}${uuid}`
          : `${API_ENDPOINTS.GET_INCOME_REMAINING_BY_UUID}${uuid}`;

      const response = await HttpClient.get(url);

      if (response.status === 200) {
        if (response?.data?.code === 1) {
          setCategoryData(response?.data);
          // toast.success(response?.data?.message);
        } else if (response?.data?.code === 0) {
          toast.error(response?.data?.message);
        } else {
          toast.warn("Unexpected response from server");
        }
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong while fetching data");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };
  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  // API Calls Ends

  useEffect(() => {
    incomeExpenseCategory();
  }, [incomeExpenseBudgetsName]);

  useEffect(() => {
    if (setSelectedFrequency) {
      setSelectedFrequency(getHowOftenName(selectedPeriod));
    }
  }, []);

  const getHowOftenName = (selectPeriod) => {
    switch (selectPeriod) {
      case "thisWeek":
      case "lastWeek":
        return "Weekly";
      case "monthly":
      case "lastMonth":
        return "Monthly";
      case "quarter":
      case "lastQuarter":
        return "Quarterly";
      case "thisYear":
        return "Annual";
      case "thisFortnightly":
      case "lastFortnightly":
        return "Fortnightly";
      case "thisCustom":
      case "lastCustom":
        return "Custom";
      default:
        return "Weekly";
    }
  };

  return (
    <>
      <div className="modal-input-group" style={{ marginBottom: "0px" }}>
        <h2
          style={{ fontSize: "20px", fontWeight: "500", marginBottom: "15px" }}
        >
          {EditDetails ? "Edit details" : "Enter details"}
        </h2>
        {autoExpense && (
          <div
            className=""
            style={{
              cursor: "pointer",
              backgroundColor: "#469B88",
              borderRadius: "360px",
              // padding: "4px",
              // paddingLeft: "8px",
              // paddingRight: "8px",
              marginBottom: "10px",
              width: "130px",
              height: "26px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={autoSingleBudgetExpenses}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginBottom: "0px",
                  color: "white",
                }}
              >
                Auto expense
              </p>
              <Wang />
            </div>
          </div>
        )}
      </div>
      <div className="modal-section-card">
        {showCategory ? (
          <div>
            <div className="modal-input-group">
              <label>Category</label>

              {mainSelectedCategory ? (
                <div
                  className="icon-tag"
                  id="popUpoverTarget"
                  onClick={togglePopover}
                  aria-haspopup="true"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="icon-transaction-img"
                    style={{
                      backgroundColor: categoryColorForAll(
                        mainSelectedCategory?.categoryColour
                      ),
                    }}
                  >
                    {mainSelectedCategory?.categoryEmoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content">
                      {mainSelectedCategory?.categoryName}
                    </h6>
                  </div>
                </div>
              ) : (
                <span
                  aria-haspopup="true"
                  id="popUpoverTarget"
                  onClick={togglePopover}
                  style={{ borderRadius: "360px" }}
                >
                  Select or create a category
                </span>
              )}
            </div>

            <IncomeExpenseCategories
              togglePopover={togglePopover}
              isOpen={popoverOpen}
              onclose={togglePopover}
              mainHead={mainHead}
              handleCategoryClick={handleCategoryClick}
              defaultCategory={defaultCategoriesData}
              yourCreatedCategory={yourCreatedCategoriesData}
              handleChange={handleAddCategory}
              targetId="popUpoverTarget"
              isHowOftenCustom={selectedFrequency}
              expenseOrIncome={expenseOrIncome}
              setType={setType}
              setChosenEmoji={setChosenEmoji}
              setSelectedColorOption={setSelectedColorOption}
            />

            {showAddCatogory && (
              <AddCategoriesModal
                modalOpen={showAddCatogory}
                addNewCategory={addNewCategory}
                transactionType={
                  incomeExpenseBudgetsName.charAt(0).toUpperCase() +
                  incomeExpenseBudgetsName.slice(1)
                }
                closeModal={handleAddCategory}
              />
            )}
          </div>
        ) : expenseOrIncome === "oneoff" ? (
          <div className="modal-input-group">
            <label>Name</label>
            <input
              className="toggle-button"
              type="text"
              style={{
                height: "35px",
                width: "246px",
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "#EFEDED",
                borderRadius: "10px",
              }}
              value={editName}
              onChange={handleNameChange}
            />
          </div>
        ) : (
          <div className="modal-input-group">
            <label>Category</label>
            <div className="button-blck">
              <div className="icon-tag">
                <div
                  className="icon-transaction-img"
                  style={{
                    backgroundColor: categoryColorForAll(
                      incomeExpenseBudgetsData?.category_colour
                    ),
                  }}
                >
                  {incomeExpenseBudgetsData?.category_emoji}
                </div>
                <div className="content">
                  <h6 className="icon-content hello">
                    {incomeExpenseBudgetsData?.category_name}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="modal-input-group"
          style={{
            marginBottom: expenseOrIncome === "oneoff" ? "0px" : "15px",
          }}
        >
          <label>Amount</label>
          <input
            className="toggle-button"
            type="text"
            style={{
              height: "35px",
              width: "175px",
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "#EFEDED",
              borderRadius: "10px",
            }}
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        {expenseOrIncome !== "oneoff" && (
          <div>
            <div
              className="modal-input-group"
              style={{
                marginBottom: expenseOrIncome === "income" ? "15px" : "",
              }}
            >
              <label>How often?</label>
              <button
                className="toggle-button"
                type="button"
                style={{
                  borderRadius: "30px",
                  background: getButtonBackgroundColor(selectedFrequency),
                  color: "white",
                  width: "122px",
                  height: "31px",
                  padding: "0px",
                }}
                onClick={toggleFrequencyModal}
                aria-expanded={isFrequencyModalOpen}
                aria-haspopup="true"
                id="frequencyPickerButton"
              >
                {selectedFrequency}
              </button>
            </div>
            {selectedFrequency === "Custom" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="modal-input-group"
                  style={{
                    marginBottom:
                      expenseOrIncome === "expense" ? "15px" : "0px",
                  }}
                >
                  {!startDate ? (
                    <label>Select start date</label>
                  ) : !endDate ? (
                    <label>Select end date</label>
                  ) : (
                    <label>Date range</label>
                  )}

                  {!startDate || !endDate ? (
                    <>
                      {!isStartDateSelected ? (
                        <div>
                          <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            className="edit-goal-house  py-2 inter add-goal"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          {/* <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginBottom: "0px",
                            }}
                          >
                            Please select the start date
                          </p> */}
                        </div>
                      ) : (
                        <div>
                          <input
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            ref={endDateRef}
                            style={{ borderRadius: "10px" }}
                            className="edit-goal-house  py-2 inter add-goal"
                          />
                          {/* <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginBottom: "0px",
                            }}
                          >
                            Please select the end date
                          </p> */}
                        </div>
                      )}
                    </>
                  ) : null}

                  {startDate && endDate && (
                    <div
                      onClick={handleDateRangeClick}
                      style={{
                        width: "175px",
                        height: "35px",
                        background: errorMessage ? "red" : "#EFEDED",
                        borderRadius: "10px",
                        textAlign: "center",
                        // marginBottom: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {startDate &&
                      endDate &&
                      !isNaN(new Date(startDate)) &&
                      !isNaN(new Date(endDate))
                        ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                        : ""}
                    </div>
                  )}
                </div>
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "90px",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
            )}

            {showNeedOrWant && (
              <div
                className="modal-input-group"
                style={{
                  marginBottom:
                    expenseOrIncome === "expense" &&
                    selectedFrequency === "Custom"
                      ? "0px"
                      : "",
                }}
              >
                <label>Need or want?</label>
                <button
                  className="toggle-button"
                  type="button"
                  style={{
                    borderRadius: "30px",
                    background: needOrWant ? "#C537C8" : "#3d6ae2",
                    color: "white",
                    width: "94px",
                    height: "31px",
                    padding: "0px",
                  }}
                  onClick={handleNeedToggleClick}
                >
                  {needOrWant ? "Need" : "Want"}
                </button>
              </div>
            )}
          </div>
        )}

        {expenseOrIncome !== "oneoff" && selectedFrequency !== "Custom" && (
          <div className="modal-input-group" style={{ marginBottom: "0px" }}>
            <label>Spread across periods</label>
            <button
              className="toggle-button"
              type="button"
              style={{
                borderRadius: "30px",
                background: isSpreadToggled ? "#479985" : "#E0533D",
                color: "white",
                width: "94px",
                height: "31px",
                padding: "0px",
              }}
              onClick={handleToggleClick}
            >
              {isSpreadToggled ? "Yes" : "No"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDetails;
