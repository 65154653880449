import React from "react";
import RemoveSvg from "../../assests/images/icons/remove.svg";

const dummyData = [
  { id: 1, name: "Top 5 expenses" },
  { id: 2, name: "Spend vs budget" },
  { id: 3, name: "Transactions" },
  { id: 4, name: "Income vs expenses graph" },
  { id: 5, name: "Goals" },
  { id: 6, name: "Merchants" },
  { id: 7, name: "Account balances" },
  { id: 8, name: "Income vs expenses" },
];

const RemoveWidgets = (props) => {
  const { isOpen, onClose } = props;

  return (
    <div className={`modal-background ${isOpen ? "open-modal" : ""}`}>
      <div className="remove-modal-content">
        <div className="d-flex justify-content-between align-items-center flex-row mb-2">
          <div className="">
            <span className="bold black lh-20 fs-25 fw-700">
              Remove widgets
            </span>
          </div>
          <div>
            <button onClick={onClose} className="close-modal-button bg-transparent border-0 fs-24 fw-500">
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>

        {dummyData?.map((item) => {
          return (
            <div
              key={item?.id}
              className="bg-white shadow-lg br-10 p-3 d-flex align-items-center mb-2 cursor-pointer"
            >
              <img src={RemoveSvg} alt="" className="w-22" />
              <span className="fs-15 fw-500 text-dark-gray ms-2">
                {item?.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RemoveWidgets;
