import React, { useCallback, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { mapAkahuAccountsData } from "../../../utilities/helper.jsx";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import "../Login.css";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import weburls from "../../../Weburls/weburls.jsx";
import axios from "axios";
import OverlayContainer from "../../OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Loading/Loading.jsx";

const AllConnected = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [trnasLoad, setTrnasLoad] = useState(() => {
    return JSON.parse(localStorage.getItem("trnas-load"));
  });
  const navigate = useNavigate();

  const userUuid = localStorage.getItem("userUuid");
  let tokenUser = localStorage.getItem("budget-token");

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${weburls.Get_Aku_Accounts}${userUuid}`,
        {
          headers: {
            authorization: `Basic ${tokenUser}`,
          },
        }
      );
      if (response.data.code === "0") {
        setData([]);
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data) {
        const mappedData = mapAkahuAccountsData(response?.data?.banks);

        setData(mappedData);
      }
    } catch (error) {
      setData([]);
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  }, [userUuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const firstFiveTransactionLoadAPI = useCallback(async () => {
    toast.info("Loading your transactions, please wait...", {
      position: "top-right",
      autoClose: false,
      toastId: "transactionLoading",
    });
    setLoading(true);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(3000);
    try {
      const responseData = await axios.get(
        `${weburls.Akahu_First_Five_Day_Transactions}${userUuid}`,
        {
          headers: {
            authorization: `Basic ${tokenUser}`,
          },
        }
      );
      if (responseData.data.code === 1) {
        localStorage.setItem("startDate", responseData.data.startDate);
        localStorage.setItem("trnas-load", false);
        setTrnasLoad(false);
        toast.update("transactionLoading", {
          render: "Transactions loaded successfully!",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.update("transactionLoading", {
        render: "Failed to load transactions. Please try again.",
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [userUuid]);

  useEffect(() => {
    if (trnasLoad) {
      firstFiveTransactionLoadAPI();
    }
  }, [trnasLoad]);

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{ borderRadius: "20px", padding: "30px" }}
              >
                <div className="transaction-categories bg-white w-100 top-0 br-10 mt-2">
                  <p
                    className="bold text-dark-gray fs-26 fw-700 "
                    style={{
                      textAlign: "center",
                      fontSize: "26px",
                      marginBottom: "5px",
                      fontWeight: "700",
                      color: "#240F51",
                    }}
                  >
                    You’re all connected ✅
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    View your live connections below, you can manage your
                    connections once you’re all set up.
                  </p>

                  <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "10px",
                      fontWeight: "700",
                      color: "#242424",
                    }}
                  >
                    Live connections
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "50px",
                    }}
                  >
                    {data?.length > 0 &&
                      data?.map((it) => (
                        <div
                          className="merchant-box"
                          // onClick={() => openModal("manageConnections", it?.accounts)}
                          style={{ cursor: "default" }}
                        >
                          <div className="d-flex" style={{ gap: "10px" }}>
                            <div
                              className="investment-connection-img"
                              style={{ width: "40px" }}
                            >
                              <img
                                src={it?.accounts[0]?.img}
                                alt=""
                                style={{
                                  objectFit: "contain",
                                  width: "49px",
                                  borderRadius: "100%",
                                }}
                              />
                            </div>
                            <div className="ms-3 lcs-inner">
                              <div className="live-connection">
                                {it?.akahuAccountName}
                              </div>
                              <div className="setting_text_color-2">
                                Updated:{" "}
                                {moment(it?.accounts[0]?.refreshDate)
                                  .utcOffset(+1)
                                  .format("DD/MM/YYYY hh:mm A")}
                              </div>
                            </div>
                          </div>
                          <div className="account-number">{`${
                            it?.accounts[0]?.status === "ACTIVE"
                              ? "Active"
                              : "Inactive"
                          }`}</div>
                        </div>
                      ))}
                  </div>

                  <div
                    className="modal-popup-btn-wrp"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <button
                      className="change-password-btn cursor-pointer"
                      onClick={() => navigate("/sign-up/all-set-up")}
                      type="button"
                      style={{
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      Finish connecting accounts
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};
export default AllConnected;
