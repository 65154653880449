/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./BudgetSummary.css";
import { ReactComponent as Arrow } from "../../../assests/images/Arrow_right.svg";
import { ReactComponent as Arrow_right } from "../../../assests/images/Arrow_right_.svg";
import BudgetBreakdown from "./BudgetBreakdown";
import BudgetsComponent from "./BudgetsComponent";
import OverlayContainer from "../../OverlayContainer/OverlayContainer";
import { Loading } from "../../Loading/Loading";
import OneOffBudget from "./OneOffBudget";
import UserImageInfoToggle from "../../UserImageInfoToggle";
import PageTitle from "../../PageTitle";
import useBudget from "../../../hooks/Budget/useBudget/useBudget";

const BudgetSummary = () => {
  // Custom hook for business logic
  const {
    uuid,
    handleLeftClick,
    handleRightClick,
    options,
    currentIndex,
    fetchAllBudgetData,
    incomeBudgetsData,
    setTotalAmount,
    setRemainingAmount,
    selectedPeriod,
    expenseBudgetsData,
    isBudgetBreakToggled,
    setIsBudgetBreakToggled,
    totalAmount,
    remainingAmount,
    oneOffBudgetsData,
    isLoading,
  } = useBudget();

  return (
    <>
      <div className="app-main__outer budget-summary-main">
        <div className="row">
          <div className="header-budget-main-div">
            <div
              className="header-budget-text-div"
              style={{ marginTop: "0px" }}
            >
              <PageTitle
                title={"Budget summary"}
                description={
                  "Here`s your summary of your budget and how you`re tracking."
                }
              />
            </div>

            <div
              className="header-budget-sub-div"
              style={{ gap: "30px", alignItems: "center" }}
            >
              <div
                className="edit-btn"
                style={{
                  width: "327px",
                  height: "53px",
                  borderRadius: "360px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#001f3f",
                }}
              >
                <div
                  className="left"
                  onClick={handleLeftClick}
                  style={{ cursor: "pointer", zIndex: 12, marginLeft: "-17px" }}
                >
                  <p>
                    <Arrow_right />
                  </p>
                </div>

                <div className="" style={{ textAlign: "center" }}>
                  <h4>{options[currentIndex].text}</h4>
                  <p style={{ color: "#04baff" }}>
                    <p>{options[currentIndex].date}</p>
                  </p>
                </div>

                <div
                  className="right"
                  onClick={handleRightClick}
                  style={{
                    cursor: "pointer",
                    zIndex: 12,
                    marginRight: "-17px",
                  }}
                >
                  <Arrow />
                </div>
              </div>
              <UserImageInfoToggle customStyle={true} />
            </div>
          </div>
        </div>

        <div className="row " style={{ marginTop: "60px" }}>
          <div className="col">
            {/* Income Budgets Section */}

            <BudgetsComponent
              fetchBudgetData={fetchAllBudgetData}
              mainHeading="Income"
              incomeExpenseBudgetsData={incomeBudgetsData}
              setTotalAmount={setTotalAmount}
              setRemainingAmount={setRemainingAmount}
              isIncome={true}
              selectedPeriod={selectedPeriod}
              showFields={true}
              uuid={uuid}
            />
            <div className="mt-4">
              <BudgetsComponent
                fetchBudgetData={fetchAllBudgetData}
                mainHeading="Expense "
                setRemainingAmount={setRemainingAmount}
                setTotalAmount={setTotalAmount}
                incomeExpenseBudgetsData={expenseBudgetsData}
                isIncome={false}
                showFields={true}
                selectedPeriod={selectedPeriod}
                uuid={uuid}
              />
            </div>
          </div>

          {/* Budget Breakdown Card */}
          <BudgetBreakdown
            handleToggleClick={() =>
              setIsBudgetBreakToggled(!isBudgetBreakToggled)
            }
            isBudgetBreakToggled={isBudgetBreakToggled}
            totalAmount={totalAmount}
            remainingAmount={remainingAmount}
          />
        </div>
        {/* End of Income Budgets Section */}

        <div className="row mt-4">
          <OneOffBudget
            fetchBudgetData={fetchAllBudgetData}
            mainHeading="One off "
            showFields={false}
            oneOffBudgetsData={oneOffBudgetsData.oneOffBudget}
            uuid={uuid}
          />
        </div>
      </div>
      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default BudgetSummary;
