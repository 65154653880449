import React, { useState } from "react";
import NoDataFound from "../NoDataFound";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { isEmpty } from "lodash";

const BreakDownExpensesCard = (props) => {
  const { data } = props;
  const [openCard, setOpenCard] = useState(null);

  const handleCardToggle = (index) => {
    setOpenCard(openCard === index ? null : index);
  };

  return (
    <div
      className="bg-white br-10"
      style={{
        padding: "34px 21px",
        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="row">
        {data.map((item, index) => (
          <div key={index} className="col-sm-6">
            <div
              className={`accordion-item ${openCard === index ? "open" : ""}`}
              onClick={() => handleCardToggle(index)}
              style={{
                marginBottom: "15px",
                border: "1px solid #ddd",
                borderRadius: "10px",
                overflow: "hidden",
                transition: "all 0.3s ease",
                width: "100%",
              }}
            >
              <div className="accordion-header d-flex align-items-center">
                <div
                  className="rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: item?.iconbg,
                    width: "42px",
                    height: "42px",
                  }}
                >
                  <img
                    className="img-fluid"
                    src={!isEmpty(item?.avatar) ? item?.avatar : Logo}
                    alt=""
                    style={{ width: "25px" }}
                  />
                </div>
                <div className="mx-3">
                  <div className="account-name">{item?.accountName}</div>
                  <div className="account-subtext text-muted">
                    {item?.accountNumber}
                  </div>
                </div>
                <div className="ms-auto text-muted-red fw-bold">
                  ${item?.value}
                </div>
              </div>

              {/* Accordion Content */}
              <div
                className={`accordion-content ${
                  openCard === index ? "expanded" : ""
                }`}
                style={{
                  maxHeight: openCard === index ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease",
                }}
              >
                <div className="px-3">
                  {item?.details?.map((detail, idx) => (
                    <div
                      key={idx}
                      className="d-flex justify-content-between my-1"
                    >
                      <span className="text-muted">{detail.label}</span>
                      <span className="text-muted-red">
                        ${detail.value.toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BreakDownExpensesCard;

// import React, { useState } from "react";
// import NoDataFound from "../NoDataFound";
// import Logo from "../../assests/images/icons/Black Logo 1.png";
// import { isEmpty } from "lodash";

// const BreakDownExpensesCard = (props) => {
//   const { data } = props;
//   const [openCard, setOpenCard] = useState(null);

//   const handleCardToggle = (index) => {
//     setOpenCard(openCard === index ? null : index);
//   };

//   return (
//     <>
//       <div
//         className="acccount-balances-wrappper-left-side bg-white br-10 d-flex w-100"
//         style={{
//           padding: "34px 31px",
//           boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
//           flexWrap: "wrap",
//           gap: "13px",
//         }}
//       >
//         {data?.length > 0 ? (
//           data?.map((item, index) => (
//             <div
//               className={`accordion-item ${openCard === index && "open"}`}
//               onClick={() => handleCardToggle(index)}
//               key={index}
//             >
//               <div className="accordion-header">
//                 <div>
//                   <div
//                     className={` d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
//                     style={{
//                       backgroundColor: item?.iconbg,
//                       borderRadius: "50px",
//                       padding: "8px",
//                     }}
//                   >
//                     <img
//                       className="img-fluid"
//                       style={{
//                         width: "25px",
//                       }}
//                       src={!isEmpty(item?.avatar) ? item?.avatar : Logo}
//                       alt=""
//                     />
//                   </div>
//                 </div>
//                 <div className="account-text mx-2">
//                   <div className="account-name">{item?.accountName}</div>
//                   <div className="account-subtext">{item?.accountNumber}</div>
//                 </div>
//                 <div className="account-value">
//                   <span className="text-muted-red fs-17 fw-700">
//                     ${item?.value}
//                   </span>
//                 </div>
//               </div>

//               {/* Accordion content */}
//               <div
//                 className={`accordion-content ${
//                   openCard === index ? "expanded" : ""
//                 }`}
//                 style={{
//                   maxHeight: openCard === index ? "200px" : "0",
//                   overflow: "hidden",
//                   transition: "max-height 0.3s ease",
//                 }}
//               >
//                 <div className="accordion-inner">
//                   {item?.details?.map((detail, idx) => (
//                     <div key={idx} className="detail-item">
//                       <span>{detail.label}</span>
//                       <span>${detail.value.toFixed(2)}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <NoDataFound />
//         )}
//       </div>
//     </>
//   );
// };

// export default BreakDownExpensesCard;
