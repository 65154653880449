import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { ReactComponent as Akahu } from "../../../assests/images/icons/akahuIcon.svg";
import "../Login.css";
import { motion } from "framer-motion";
import {
  iconCategories,
  iconCategoriesWithHeadings,
} from "../../../utilities/Statics/index.jsx";
import { BankConnectionAccounts } from "./BankConnectionAccounts.jsx";
import { useNavigate } from "react-router-dom";
import { BankConnectionList } from "../../BankConnectionList/index.jsx";
import SectionTitle from "../../SectionTitle.jsx/index.jsx";

const ConnectYourAccounts = () => {
  const navigate = useNavigate();
  const handleAkahuModal = () => {
    navigate("/sign-up/connect-akahu");
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-2">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{
                  width: "1178px",
                  //   height: "710px",
                  borderRadius: "20px",
                  padding: "30px",
                }}
              >
                <div className="d-flex flex-row align-content-center justify-content-between">
                  <div>
                    <p
                      className="bold text-deep-violet fs-26 fw-700 "
                      style={{
                        marginBottom: "3px",
                      }}
                    >
                      Connect your accounts
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                      }}
                    >
                      Connect to all of your bank, kiwisaver and investment
                      accounts to bring everything into one place.
                    </p>
                  </div>

                  <div
                    className="modal-popup-btn-wrp"
                    style={{
                      marginTop: "10px",
                      textAlign: "start",
                    }}
                  >
                    <button
                      className="change-password-btn cursor-pointer"
                      type="button"
                      style={{
                        marginTop: "0px",
                        marginBottom: "10px",
                        width: "223px",
                      }}
                      onClick={() => navigate("/sign-up/all-set-up")}
                    >
                      Skip this step
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8 connect-bank-wrp connect-your-account-bank-connection ">
                    <SectionTitle title={"Add a bank connection"} />
                    <BankConnectionList
                      style={{ padding: "15px 20px" }}
                      connections={iconCategories.bankConnections}
                      onIconClick={handleAkahuModal}
                    />
                    <div className="mt-4">
                      <SectionTitle title={"Add a Kiwisaver connection"} />
                      <BankConnectionList
                        style={{ padding: "15px 20px" }}
                        connections={iconCategories.kiwiSaverConnections}
                        onIconClick={handleAkahuModal}
                      />
                    </div>
                    <div className="inves-flex">
                      <div className="add-an-investment-conn-wrapper">
                        <SectionTitle title={"Add an investment connection"} />
                        <BankConnectionList
                          style={{ padding: "15px 20px" }}
                          connections={iconCategories.addInvestmentConnections}
                          onIconClick={handleAkahuModal}
                        />
                      </div>
                      <div className="add-an-investment-conn-wrapper">
                        <SectionTitle title={"Other Connections"} />
                        <BankConnectionList
                          style={{ padding: "15px 20px" }}
                          connections={iconCategories.otherConnections}
                          onIconClick={handleAkahuModal}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-4 connect-account-akahu-box-wrp">
                    <div className="akahu-box">
                      <div className="d-flex align-items-center mb-3 akahu-gap">
                        <div className="bank-connection-img">
                          <Akahu />
                        </div>
                        <div className="bold who-akahu">Who is Akahu?</div>
                      </div>
                      <div className="setting_text_color mt-2">
                        Akahu is New Zealand’s open finance provider.
                      </div>
                      <div className="setting_text_color mt-4">
                        Akahu makes it simple to securely access your financial
                        information and to provide that data with platforms like
                        BudgetBuddie.
                      </div>
                      <div className="setting_text_color mt-3">
                        Akahu uses 256-bit bank-grade encryption to keep your
                        data safe and secure.
                      </div>
                      <div className="setting_text_color mt-3">
                        With your consent, the secure connection with Akahu will
                        pull through your bank balances, transactional
                        information, and account details.
                      </div>
                      <div className="setting_text_color mt-3">
                        You’ll be redirected to Akahu’s website to establish the
                        connection.
                      </div>
                      <div className="setting_text_color mt-3">
                        To connect your accounts, tap the below button.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center gap-1 mt-3">
                <p
                  style={{
                    fontSize: "14px",
                    color: "#B7B8C1",
                    fontWeight: "500",
                    margin: "0px",
                  }}
                >
                  Missed something?
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#469B88",
                    fontWeight: "700",
                    cursor: "pointer",
                    margin: "0px",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Go back a step
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ConnectYourAccounts;
