import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useOutsideClick } from "../../useOutsideClick";
import axios from "axios";
import weburls from "../../../Weburls/weburls";
import { parseYourAccountBalancesData } from "../../../utilities/helper";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const schema = yup.object().shape({
  goalName: yup
    .string()
    .min(3, "*goalName is Required")
    .required("*Name is Required"),
  value: yup.string().required("*Goal dollar value  is Required"),
  contribution: yup.string().required("*Contribution is Required"),
  currentBalance: yup.string().required("*Balance is Required"),
});

const useAddGoal = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [selectedTab, setSelectedTab] = useState("Weekly");
  const [accountData, setAccountData] = useState([]);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [showPicker, setShowPicker] = useState(false);
  const navigate = useNavigate();
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const pickerRef = useRef(null);
  const [goalData, setGoalData] = useState({
    goalName: "",
    value: "",
    contribution: "",
    howOften: "",
    currentBalance: "",
    reference: "",
    colour: "Red",
    icon: "😁",
    startDate: null,
    endDate: null,
  });

  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  useOutsideClick(pickerRef, () => setShowPicker(false));

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);

    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_CASH_ACCOUNT}${uuid}`
      );
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("error", error);

      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
    setGoalData({
      ...goalData,
      icon: emoji,
    });
  };

  const handleTabChange = (tabName) => {
    const { value } = tabName.target;
    setSelectedTab(value);
    setGoalData({ ...goalData, howOften: value });
  };
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  const handleOptionColorClick = (option) => {
    setGoalData({ ...goalData, colour: option });
    setPopupOpenColor(false);
  };
  const addGoal = async (data) => {
    try {
      const response = await HttpClient.post(API_ENDPOINTS.ADD_GOALS + uuid, {
        ...goalData,
        ...data,
        userUuid: uuid,
        reference: goalData.goalName.slice(0, 12),
      });
      if (response.status === 200) {
        toast.success("Goal successfully..", {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/goals/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Goal details not saved. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleToggleSwitch = (data) => {
    const { accountId } = data;

    setSelectedAccounts((prevAccounts) => {
      const newAccounts = prevAccounts.includes(accountId)
        ? prevAccounts.filter((id) => id !== accountId)
        : [...prevAccounts, accountId];

      const currentBalance = accountData
        .filter((acc) => newAccounts.includes(acc.accountId))
        .reduce((total, acc) => total + acc.value, 0);

      setGoalData((prevGoalData) => ({
        ...prevGoalData,
        accounts: newAccounts,
        isConnected: true,
        currentBalance: currentBalance,
      }));
      setValue("currentBalance", currentBalance);
      return newAccounts;
    });
  };

  return {
    isPopupOpenInfo,
    togglePopupInfo,
    handleSubmit,
    addGoal,
    control,
    errors,
    goalData,
    handleChange,
    handleTabChange,
    isLinkedToAccount,
    setIsLinkedToAccount,
    selectedTab,
    isPopupOpenColor,
    selectedAccounts,
    togglePopupColor,
    handleOptionColorClick,
    showPicker,
    setShowPicker,
    chosenEmoji,
    pickerRef,
    handleEmojiClick,
    accountData,
    handleToggleSwitch,
    loading,
  };
};

export default useAddGoal;
