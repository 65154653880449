import axiosService from "./AxiosInstance";

export class HttpClient {
  static  get(url, params = {}) {
    try {
      const response = axiosService.get(url, { params });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static post(url, data, options = {}) {
    try {
      const response = axiosService.post(url, data, options);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static put(url, data) {
    try {
      const response = axiosService.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static delete(url, data = null) {
    try {
      const config = data ?  data  : {};
      const response = axiosService.delete(url, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default HttpClient;
