import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentFortnight,
  getCurrentMonth,
  getCurrentQuarter,
  getCurrentWeek,
  getCurrentYear,
  getLastFortnight,
  getLastMonth,
  getLastQuarter,
  getLastWeek,
} from "../../../utilities/helper";
import { toast } from "react-toastify";
import axiosService from "../../../client/AxiosInstance";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useBudget = () => {
  const { uuid } = useParams();
  const [isBudgetBreakToggled, setIsBudgetBreakToggled] = useState(false);
  const [incomeBudgetsData, setIncomeBudgetsData] = useState([]);
  const [expenseBudgetsData, setExpenseBudgetsData] = useState([]);
  const [oneOffBudgetsData, setOneOffBudgetsData] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [completeBudgetDataIncome, setCompleteBudgetDataIncome] = useState({});
  const [completeBudgetDataExpense, setCompleteBudgetDataExpense] = useState(
    {}
  );
  const [remainingAmount, setRemainingAmount] = useState({
    income: 0,
    expense: 0,
  });
  const [totalAmount, setTotalAmount] = useState({
    income: 0,
    expense: 0,
  });
  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const updateLoadingState = (isLoading) => {
    setLoadingCount((prevCount) =>
      isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0)
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatPeriod = (start, end) =>
    `${formatDate(start)} - ${formatDate(end)}`;

  const formatCustomLastPeriod = function (startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const duration = end - start;

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const days = Math.floor(duration / millisecondsPerDay);

    let newEndDate = new Date(start);
    newEndDate.setDate(newEndDate.getDate() - 1);

    let newStartDate = new Date(newEndDate);
    newStartDate.setDate(newStartDate.getDate() - days);

    return `${formatDate(newStartDate)} - ${formatDate(newEndDate)}`;
  };
  const options = [
    { label: "lastWeek", text: "Last week", date: getLastWeek() },
    { label: "thisWeek", text: "This week", date: getCurrentWeek() },
    {
      label: "lastFortnightly",
      text: "Last fortnight",
      date: getLastFortnight(),
    },
    {
      label: "thisFortnightly",
      text: "This fortnight",
      date: getCurrentFortnight(),
    },
    { label: "lastMonth", text: "Last month", date: getLastMonth() },
    { label: "monthly", text: "This month", date: getCurrentMonth() },
    { label: "lastQuarter", text: "Last quarter", date: getLastQuarter() },
    { label: "quarter", text: "This quarter", date: getCurrentQuarter() },
    { label: "thisYear", text: "This year", date: getCurrentYear() },

    {
      label: "lastCustom",
      text: "Custom - last period",
      date: completeBudgetDataExpense?.thisCustom?.length
        ? formatCustomLastPeriod(
            completeBudgetDataExpense.thisCustom[0].start_date,
            completeBudgetDataExpense.thisCustom[0].end_date
          )
        : "No data available",
    },
    {
      label: "thisCustom",
      text: "Custom - this period",
      date: completeBudgetDataExpense?.thisCustom?.length
        ? formatPeriod(
            completeBudgetDataExpense.thisCustom[0].start_date,
            completeBudgetDataExpense.thisCustom[0].end_date
          )
        : "No data available",
    },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem("selectedPeriod") || "monthly"
  );
  const initialIndex = options.findIndex(
    (option) => option.label === selectedPeriod
  );
  const [currentIndex, setCurrentIndex] = useState(
    initialIndex !== -1 ? initialIndex : 5
  );

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  useEffect(() => {
    localStorage.setItem("selectedPeriod", selectedPeriod);
  }, [selectedPeriod]);

  const fetchAllBudgetData = async () => {
    updateLoadingState(true);
    try {
      // Fetch income budget data
      const incomeResponse = await HttpClient.get(
        `${API_ENDPOINTS.GET_INCOME_BUDGET}${uuid}`
      );

      if (incomeResponse?.data) {
        setCompleteBudgetDataIncome(incomeResponse.data.income);
        setIncomeBudgetsData(incomeResponse.data.income[selectedPeriod]);
      }

      // Fetch expense budget data
      const expenseResponse = await HttpClient.get(
        `${API_ENDPOINTS.GET_EXPENSE_BUDGET}${uuid}`
      );

      if (expenseResponse?.data) {
        setCompleteBudgetDataExpense(expenseResponse.data.expenses);
        setExpenseBudgetsData(expenseResponse.data.expenses[selectedPeriod]);
      }

      // Fetch one-off budget data
      const oneOffResponse = await HttpClient.get(
        `${API_ENDPOINTS.GET_ONE_OFF_BUDGET}${uuid}`
      );

      if (oneOffResponse?.data) {
        setOneOffBudgetsData(oneOffResponse.data);
      }
    } catch (error) {
      console.error("Error fetching budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchAllBudgetData();
    }
  }, [uuid]);

  const handleLeftClick = () => {
    const newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense[newLabel]);
  };

  const handleRightClick = () => {
    const newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense[newLabel]);
  };

  const isLoading = loadingCount > 0;

  return {
    uuid,
    handleLeftClick,
    handleRightClick,
    options,
    currentIndex,
    fetchAllBudgetData,
    incomeBudgetsData,
    setTotalAmount,
    setRemainingAmount,
    selectedPeriod,
    expenseBudgetsData,
    isBudgetBreakToggled,
    setIsBudgetBreakToggled,
    totalAmount,
    remainingAmount,
    oneOffBudgetsData,
    isLoading,
  };
};

export default useBudget;
