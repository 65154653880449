import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import weburls from "../../Weburls/weburls";
import "../AddIncome/addIncome.css";
import EmojiPicker from "emoji-picker-react";
import { ConnectedAccounts } from "../../Components/ConnectedAccountsCard";
import ConfirmationModal from "../../ModalForm/Popup/ConfirmationModal";
import { isEmpty } from "lodash";
const schema = yup.object().shape({
  assetsName: yup.string().required("*Please enter assets name"),
  value: yup
    .string()
    .required("*Please enter asset value")
    .matches(/^[0-9]+$/, "*Please enter number only"),
});

const EditLiabilityOrAsset = ({
  modalOpen,
  closeModal,
  fetchAssets,
  uuid,
  modalTitle,
  modalName,
  fetchLiability,
  modalData,
  accountData,
}) => {
  const [assetData, setAssetData] = useState({
    userUuid: "",
    assetsName: "",
    value: "",
    amount: "",
    assetImage: "",
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(true);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState({ assetsName: "", value: "" });
  useEffect(() => {
    if (modalData) {
      setAssetData({
        userUuid: uuid,
        assetsName: modalData.name || "",
        value: modalData.value || "",
        amount: modalData.amount || "",
        assetImage: "",
        assetID: modalData.id,
      });
      setChosenEmoji(modalData.emoji);
      setSelectedAccounts(modalData.accounts);
    }
  }, [modalData, uuid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssetData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      validateForm();
      return updatedData;
    });
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowEmojiPicker(false);
  };

  const handleToggleSwitch = (data) => {
    const { accountId } = data;
    setSelectedAccounts((prevAccounts) => {
      if (prevAccounts.includes(accountId)) {
        return prevAccounts.filter((id) => id !== accountId);
      } else {
        return [...prevAccounts, accountId];
      }
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (modalTitle === "asset") {
      updateAsset();
    } else {
      updateLiability();
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = { assetsName: "", value: "" };
    const assetName = String(assetData.assetsName || "").trim();
    const assetValue = String(assetData.value || "").trim();
    if (!assetName) {
      errors.assetsName = "*Please enter assets name";
      valid = false;
    }
    if (!assetValue) {
      errors.value = "*Please enter asset value";
      valid = false;
    } else if (!/^[0-9]+$/.test(assetValue)) {
      errors.value = "*Please enter number only";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };

  const updateAsset = async () => {
    try {
      const data = {
        userUuid: uuid,
        assetsUuid: assetData.assetID,
        assetsName: assetData.assetsName,
        value: assetData.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.put(weburls.Update_Asset, data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        closeModal(modalName);

        fetchAssets();
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      closeModal(modalName);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  const updateLiability = async () => {
    try {
      const data = {
        userUuid: uuid,
        liabilityUuid: assetData.assetID,
        liabilityName: assetData.assetsName,
        value: assetData.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.put(weburls.Update_Liability, data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        closeModal(modalName);

        fetchLiability();
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      closeModal(modalName);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const deleteAssetOrLiability = async () => {
    try {
      const response = await axios.delete(
        `${
          modalTitle === "asset"
            ? weburls.Delete_Asset
            : weburls.Delete_Liability
        }${uuid}/${modalData.id}`
      );

      if (response?.data?.code === 1) {
        toast.success(`${response.data.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response.data.message}`);
      }
      setShowDeleteModal(!showDeleteModal);
      closeModal(modalName);
      fetchAssets();
      fetchLiability();
    } catch (error) {
      toast.error("Somthing went wrong while deleting...");
    }
  };
  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content networth-modal-width">
        <button
          onClick={() => closeModal(modalName)}
          className="close-pass-button"
        >
          <i className="fa fa-times"></i>
        </button>
        {/* <form onSubmit={onSubmitHandler}> */}
        <>
          <div className="asset-detail">
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                {modalName === "editAsset" ? (
                  <span className="bold black password-heading">
                    Edit an {modalTitle}
                  </span>
                ) : (
                  <span className="bold black password-heading">
                    Edit a {modalTitle}
                  </span>
                )}
                <p className="trans-modal-para">
                  Enter in all your {modalTitle} details below.
                </p>
              </div>
              <div className="d-flex">
                <button
                  type="submit"
                  className="add-transaction-btn cursor-pointer"
                  onClick={onSubmitHandler}
                >
                  Save&nbsp;changes
                </button>
                <button
                  type="submit"
                  className="delete-asset-btn cursor-pointer me-4"
                  onClick={() => setShowDeleteModal(!showDeleteModal)}
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="black account-bal-title mt-4">Enter Details</div>
            <div className="edit-goal-box d-flex justify-content-between align-items-center">
              <div className="fw-bold inter">Link to an account</div>
              <div className="account-input">
                {isLinkedToAccount ? (
                  <div
                    className="link-account cursor-pointer"
                    onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                  >
                    Yes
                  </div>
                ) : (
                  <div
                    className="not-link-account cursor-pointer"
                    onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                  >
                    No
                  </div>
                )}
              </div>
            </div>
            <div className="edit-goal-box">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-bold inter">Name</div>
                <input
                  type="text"
                  name="assetsName"
                  value={assetData.assetsName}
                  onChange={handleChange}
                  className="edit-goal-house py-2 inter add-goal"
                  style={{ border: "none", width: "266px" }}
                />
              </div>
              <p className="error-message">{errors.assetsName}</p>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div className="fw-bold inter mt-1">Value</div>
                <input
                  type="text"
                  name="value"
                  value={assetData.value}
                  onChange={handleChange}
                  className="edit-goal-house py-2 inter add-goal"
                  style={{ border: "none", width: "266px" }}
                />
              </div>
              <p className="error-message">{errors.value}</p>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div className="fw-bold inter mt-1">Emoji</div>
                <div
                  className="py-2 inter add-goal w-50 show-emoji"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                >
                  {chosenEmoji}
                </div>
              </div>
              {showEmojiPicker && (
                <div className="emoji-container">
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    style={{ height: "340px", width: "369px" }}
                  />
                </div>
              )}
            </div>
          </div>
          {isLinkedToAccount && (
            <div>
              <div
                className="bold black sub-head-text inter mt-3"
                style={{ maginTop: "10px", paddingLeft: "33px" }}
              >
                Link to an account
              </div>
              <div className="asset-accounts-container">
                <div className="d-flex flex-wrap link-account-gap">
                  {accountData?.map((ele, index) => (
                    <ConnectedAccounts
                      key={index}
                      avatar={ele?.avatar}
                      accountName={ele?.accountName}
                      value={ele?.value}
                      accountId={ele?.accountId}
                      handleToggleSwitch={handleToggleSwitch}
                      id={ele?.accountId}
                      selectedAccount={selectedAccounts}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
        {showDeleteModal && (
          <ConfirmationModal
            isOpen={showDeleteModal}
            onConfirm={deleteAssetOrLiability}
            onCancel={() => setShowDeleteModal(!showDeleteModal)}
            textDesc1={"Are you sure?"}
            textDesc2={"This cannot be un-done."}
            buttonText1={"Go back"}
            buttonText2={"Delete"}
          />
        )}
        {/* </form> */}
      </div>
    </div>
  );
};

export default EditLiabilityOrAsset;