import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { planType } from "../../../utilities/helper";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useGoals = () => {
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const { uuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const navigate = useNavigate();
  const [whoopsPopUp, setWhoopsPopUp] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.username;

  const fetchGoalDetails = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_GOALS}${uuid}`
      );
      if (response?.data) {
        setGoals(response?.data?.goals);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchedPlanData = async () => {
    const body = { email: userEmail };
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.GET_PLAN_DETAILS,
        body
      );
      setPlanDetails(response.data);
    } catch (error) {
      console.error("Error fetching plan details:", error);
    }
  };

  useEffect(() => {
    fetchedPlanData();
  }, []);

  useEffect(() => {
    fetchGoalDetails();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };

  function shouldNavigateToWhoopsScreen(plan, goalsCount) {
    if (plan === "Standard") {
      return goalsCount >= 4;
    } else if (plan === "Basic") {
      return goalsCount >= 2;
    } else if (plan === "Freemium") {
      return goalsCount > 0;
    } else {
      return false;
    }
  }

  const handleNavigate = () => {
    if (planDetails) {
      const plan = planType(planDetails.planType);
      const goalLength = goals.length;
      if (shouldNavigateToWhoopsScreen(plan, goalLength)) {
        setWhoopsPopUp(true);
      } else {
        navigate(`/goals/addnewgoal/${uuid}`);
      }
    }
  };
  return {
    handleNavigate,
    isPopupOpenInfo,
    togglePopupInfo,
    goals,
    uuid,
    handleEditGoal,
    whoopsPopUp,
    planDetails,
    setWhoopsPopUp,
  };
};

export default useGoals;
