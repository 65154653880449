import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Editnw } from "../../assests/images/icons/edit.svg";
import { ReactComponent as Edit } from "../../assests/images/icons/main-plus.svg";
import MainSidebar from "../../Components/MainSidebar";
import weburls from "../../Weburls/weburls";
import PageTitle from "../../Components/PageTitle";
import { useNavigate } from "react-router-dom";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import {
  emojiBackgroundColor,
  lightBackgroundColor,
  moduleInfoPopUpData,
} from "../../utilities/Statics";
import { planType } from "../../utilities/helper.jsx";
import { PopUpWhoops } from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";
import {
  adjustLightness,
  pickColorItemList,
} from "../../utilities/color-generator.jsx";
import useGoals from "../../hooks/Goals/useGoals/useGoals.jsx";

export const colorCss = (color) => {
  switch (color) {
    case "Dark green":
      return "dark-green-color";
    case "Red":
      return "red-color";
    case "Yellow":
      return "yellow-color";
    case "Blue":
      return "blue-color";
    case "Purple":
      return "purple-color-color";
    case "Orange":
      return "orange-color";
    case "Pink":
      return "pink-color";
    case "Light blue":
      return "light-blue-color";
    case "Lime green":
      return "lime-green-color";
    case "Dark red":
      return "dark-red-color";
    case "Green":
      return "green-color";
    case "Dark blue":
      return "dark-blue-color";
    default:
      return "green";
  }
};

export const progressbarColor = (color) => {
  switch (color) {
    case "Dark green":
      return "progress-dark-green-color";
    case "Red":
      return "progress-red-color";
    case "Yellow":
      return "progress-yellow-color";
    case "Blue":
      return "progress-blue-color";
    case "Purple":
      return "progress-purple-color-color";
    case "Orange":
      return "progress-orange-color";
    case "Pink":
      return "progress-pink-color";
    case "Light blue":
      return "progress-light-blue-color";
    case "Lime green":
      return "progress-lime-green-color";
    case "Dark red":
      return "progress-dark-red-color";
    case "Green":
      return "progress-green-color";
    case "Dark blue":
      return "progress-dark-blue-color";
    default:
      return "progress-green";
  }
};

const Financial = () => {
  const {
    handleNavigate,
    isPopupOpenInfo,
    togglePopupInfo,
    goals,
    uuid,
    handleEditGoal,
    whoopsPopUp,
    planDetails,
    setWhoopsPopUp,
  } = useGoals();

  return (
    <>
      <div className="app-main__outer">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="d-flex  justify-content-between mt-2">
          <div>
            <div className="bold big-head-new">Your Goals</div>
            <div className="fst-normal setting_text_color">
              Let's set some goals, it’s always good having goals and something
              to aim for.
            </div>
          </div>

          <div className="row mt-5">
            <div className="">
              <div className="row">
                {goals && goals?.length > 0 ? (
                  goals?.map((it) => (
                    <div className="col-lg-6 col-md-12 mt-4" key={it.goalUuid}>
                      <Link
                        to={`/goals/${uuid}/GoalDetails/${it?.goalUuid}`}
                        style={{
                          backgroundColor: pickColorItemList.hasOwnProperty(
                            it?.colour
                          )
                            ? pickColorItemList[it?.colour]
                            : it?.colour,
                        }}
                      >
                        <div
                          className={`financial-holiday financial cursor-pointer`}
                          style={{
                            backgroundColor: pickColorItemList.hasOwnProperty(
                              it?.colour
                            )
                              ? pickColorItemList[it?.colour]
                              : it?.colour,
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
                                  style={{
                                    backgroundColor:
                                      pickColorItemList.hasOwnProperty(
                                        it?.colour
                                      )
                                        ? adjustLightness(
                                            pickColorItemList[it?.colour],
                                            10
                                          )
                                        : adjustLightness(it?.colour, 14),
                                    borderRadius: "50px",
                                  }}
                                >
                                  {it?.icon}
                                </div>
                                <div className="ms-2 fw-600 fs-18 lh-24 inter text-white">
                                  {it.goalName}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
                                style={{
                                  backgroundColor:
                                    pickColorItemList.hasOwnProperty(it?.colour)
                                      ? adjustLightness(
                                          pickColorItemList[it?.colour],
                                          10
                                        )
                                      : adjustLightness(it?.colour, 14),
                                  borderRadius: "50px",
                                }}
                              >
                                {it?.icon}
                              </div>
                              <div className="ms-2 fw-600 fs-18 lh-24 inter text-white">
                                {it.goalName}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="fs-12 text-white fw-400">
                              Balance
                            </div>
                            <div className="fs-12 text-white fw-400">
                              {(
                                (it.currentBalance / it.value).toFixed(2) * 100
                              ).toFixed(0)}
                              %
                            </div>
                          </div>
                          <div
                            className={`progress`}
                            style={{
                              height: "7px",
                              backgroundColor: "rgba(255, 255, 255, 0.4)",
                            }}
                          >
                            <div
                              className={`progress-bar`}
                              role="progressbar"
                              aria-valuenow={(
                                (it.currentBalance / it.value) *
                                100
                              ).toFixed(2)}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${(
                                  (it.currentBalance / it.value) *
                                  100
                                ).toFixed(2)}%`,
                                opacity: "2 !important",
                                backgroundColor:
                                  pickColorItemList.hasOwnProperty(it?.colour)
                                    ? adjustLightness(
                                        pickColorItemList[it?.colour],
                                        10
                                      )
                                    : adjustLightness(it?.colour, -16),
                              }}
                            ></div>
                          </div>
                          <div className="mt-2 d-flex justify-content-between align-items-start">
                            <div>
                              <span className="goal-amount fs-16 fw-600 fst-normal text-white">
                                ${it?.currentBalance.toFixed(2)}
                              </span>
                              <span className="fs-12 text-white fw-400">
                                &nbsp; of &nbsp;${it.value}
                              </span>

                              <div className="mt-3 fs-12 fw-600 text-white fs-12">
                                See detail
                              </div>
                            </div>
                            <div className="fs-12 text-white fw-400">
                              {it?.endDate
                                ? moment(it?.endDate).format("Do MMMM YYYY")
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                          <div className="financial-text">Balance</div>
                          <div className="financial-text">
                            {(
                              (it.currentBalance / it.value).toFixed(2) * 100
                            ).toFixed(0)}
                            %
                          </div>
                        </div>
                        <div
                          className={`progress`}
                          style={{
                            height: "7px",
                            backgroundColor: "rgba(255, 255, 255, 0.4)",
                          }}
                        >
                          <div
                            className={`progress-bar`}
                            role="progressbar"
                            aria-valuenow={(
                              (it.currentBalance / it.value) *
                              100
                            ).toFixed(2)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: `${(
                                (it.currentBalance / it.value) *
                                100
                              ).toFixed(2)}%`,
                              opacity: "2 !important",
                              backgroundColor: pickColorItemList.hasOwnProperty(
                                it?.colour
                              )
                                ? adjustLightness(
                                    pickColorItemList[it?.colour],
                                    10
                                  )
                                : adjustLightness(it?.colour, -16),
                            }}
                          ></div>
                        </div>
                        <div className="amount-top d-flex justify-content-between align-items-start">
                          <div>
                            <span className="goal-amount text-white">
                              ${it?.currentBalance.toFixed(2)}
                            </span>
                            <span className="financial-text">
                              &nbsp; of &nbsp;${it.value}
                            </span>

                            <div className="mt-3 see-detail text-white fs-12">
                              See detail
                            </div>
                          </div>
                          <div className="financial-text">
                            {it?.endDate
                              ? moment(it?.endDate).format("Do MMMM YYYY")
                              : ""}
                          </div>
                        </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="col-lg-6 col-md-12 mt-4">
                  <div className="text-danger">
                    No goal, please add your goal
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
      {whoopsPopUp && (
        <PopUpWhoops
          planDetails={planDetails}
          onClose={() => {
            setWhoopsPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default Financial;
