import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "Please enter a valid email"
    ),
});

const useEmailTwoFactorVerification = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.EMAIL_2FA_VERIFICATION,
        formData
      );
      setLoading(false);
      if (response.data.code == 1) {
        setTimeout(() => {
          navigate(`/Email2FAVerifactionOTP/${uuid}`);
        }, 1000);
        toast.success("Reset Password link send into email successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      if (response.data.code == 0) {
        toast.error("Your email address in-vaild. Please try again", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Please try again.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    togglePopupInfo,
    isPopupOpenInfo,
    handleSubmit,
    onSubmitHandler,
    register,
    handleChange,
    formData,
    errors,
    loading,
  };
};

export default useEmailTwoFactorVerification;
