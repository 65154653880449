import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosService from "../../../client/AxiosInstance";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../../utilities/helper";

const useBudgetDetails = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const period = searchParams.get("period");
  const [incomeState, setIncomeState] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [showPicker, setShowPicker] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [needOrWant, setNeedOrWant] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(undefined);
  const [editAmount, setEditAmount] = useState(undefined);
  const [editName, setEditName] = useState(undefined);
  const [selectedFrequency, setSelectedFrequency] = useState(undefined);
  const [isSpreadToggled, setIsSpreadToggled] = useState(false);
  const [search, setSearch] = useState("");
  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] =
    useState(period);

  const [isFrequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const [modals, setModals] = useState({
    merchantDetails: false,
    viewTransaction: false,
    addCategory: false,
  });
  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);
  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };
  const updateLoadingState = (isLoading) => {
    setLoadingCount((prevCount) =>
      isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0)
    );
  };

  const [hasMore, setHasMore] = useState(true);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isCashflowDelete, setIsCashflowDelete] = useState(false);

  const scrollContainerRef = useRef(null);
  const [page, setPage] = useState(1);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.SPLIT_TRANSACTION}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_CATEGORIES}${uuid}`
      );
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const currentUrl = window.location.href;

  const urlSegments = currentUrl.split("/");

  const expenseOrIncome = urlSegments[urlSegments.length - 2];

  const budgetId = urlSegments[urlSegments.length - 1].split("?")[0];

  const findBudgetData = (data, budgetId) => {
    const matchingItems = {};

    for (const key in data) {
      if (Array.isArray(data[key])) {
        const matched = data[key].filter(
          (item) =>
            item.income_uuid === budgetId ||
            item.expense_uuid === budgetId ||
            item.uuid === budgetId
        );

        if (matched.length > 0) {
          matchingItems[key] = matched;
        }
      }
    }

    return matchingItems;
  };

  const fetchIncomeBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await axiosService.get(
        `${API_ENDPOINTS.GET_INCOME_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(response?.data?.income, budgetId);
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching income budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  const fetchExpenseBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await axiosService.get(
        `${API_ENDPOINTS.GET_EXPENSE_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(response?.data?.expenses, budgetId);
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  const fetchOneOffBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ONE_OFF_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(
          response?.data?.oneOffBudget,
          budgetId
        );
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  useEffect(() => {
    if (uuid) {
      if (expenseOrIncome === "expense") {
        fetchExpenseBudgetData();
      } else if (expenseOrIncome === "income") {
        fetchIncomeBudgetData();
      } else {
        fetchOneOffBudgetData();
      }
    }
  }, [
    uuid,
    fetchExpenseBudgetData,
    fetchIncomeBudgetData,
    fetchOneOffBudgetData,
  ]);

  let progress;

  const categoryType = filteredData[0]?.category_type?.toLowerCase();

  const adjustedAmount =
    categoryType === "income"
      ? (filteredData[0]?.adjusted_income_amount ?? 0) -
        (filteredData[0]?.total_transaction_amount ?? 0)
      : categoryType === "expense"
      ? (filteredData[0]?.adjusted_expense_amount ?? 0) -
        (filteredData[0]?.total_transaction_amount ?? 0)
      : (filteredData[0]?.amount ?? 0) -
        (filteredData[0]?.total_transaction_amount ?? 0);

  const totalAmount = filteredData[0]?.total_transaction_amount ?? 0;

  if (categoryType === "income") {
    if ((filteredData[0]?.adjusted_income_amount ?? 0) === 0) {
      progress = totalAmount === 0 ? 0.0 : 100;
    } else {
      progress =
        (totalAmount / (filteredData[0]?.adjusted_income_amount ?? 1)) * 100;
    }
  } else if (categoryType === "expense") {
    if ((filteredData[0]?.adjusted_expense_amount ?? 0) === 0) {
      progress = totalAmount === 0 ? 0.0 : 100;
    } else {
      progress =
        (totalAmount / (filteredData[0]?.adjusted_expense_amount ?? 1)) * 100;
    }
  } else {
    if (categoryType === "oneoff" && (filteredData[0]?.amount ?? 0) === 0) {
      progress = totalAmount === 0 ? 0.0 : 100;
    } else {
      progress = (totalAmount / (filteredData[0]?.amount ?? 1)) * 100;
    }
  }
  progress = progress > 100 ? 100 : progress;

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  const toggleFrequencyModal = () => {
    setFrequencyModalOpen((prev) => !prev);
  };

  const handleFrequencySelection = (frequency) => {
    setSelectedFrequency(frequency);
    setFrequencyModalOpen(false);
  };

  const getButtonBackgroundColor = (frequency) => {
    switch (frequency) {
      case "Weekly":
        return "#479985";
      case "Fortnightly":
        return "#3de1bc";
      case "Monthly":
        return "#3d6ae2";
      case "Quarterly":
        return "#3eb1e1";
      case "Annual":
        return "#39e16f";
      case "Custom":
        return "#8D83FE";
      default:
        return "#479985";
    }
  };

  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  const handleOptionTopExpensesClick = (option) => {
    setSelectedTopExpensesOption(option);

    let data;
    if (filteredData) {
      switch (option) {
        case "This week":
          data = incomeState.thisWeek ?? [];
          break;
        case "Last week":
          data = incomeState.lastWeek ?? [];
          break;
        case "This quarter":
          data = incomeState.quarter ?? [];
          break;
        case "Last quarter":
          data = incomeState.lastQuarter ?? [];
          break;
        case "This month":
          data = incomeState.monthly ?? [];
          break;
        case "Last month":
          data = incomeState.lastMonth ?? [];
          break;
        case "This year":
          data = incomeState.thisYear ?? [];
          break;
        case "Last fortnight":
          data = incomeState.lastFortnightly ?? [];
          break;
        case "This fortnight":
          data = incomeState.thisFortnightly ?? [];
          break;
        case "Custom":
          data = incomeState.thisCustom ?? [];
          break;
        case "Custom - last period":
          data = incomeState.lastCustom ?? [];
          break;
        default:
          data = [];
          break;
      }
    }
    setFilteredData(data);
    setPopupOpenTopExpenses(false);
  };

  const togglePopover = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: !prevState[transactionId],
    }));
  };

  const handleAddCategory = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setShowAddCategory(!showAddCategory);
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.UPDATE_CATEGORY}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactionsData(1, expenseOrIncome);
    } catch (error) {}
  };

  // const handleAmountChange = (e) => {
  //   const value = e.target.value;

  //   const numericValue = value.replace(/^\$/, "");

  //   if (/^\d*\.?\d*$/.test(numericValue)) {
  //     setEditAmount(`$${numericValue}`);
  //   }
  // };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Remove any characters that are not digits or decimal points
    const numericValue = value.replace(/[^0-9.]/g, "");

    // Ensure the value is a valid number or empty string
    if (/^\d*\.?\d*$/.test(numericValue)) {
      // Set the state with the clean numeric value (no commas)
      setEditAmount(numericValue);
    }
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleEditToggleClick = () => {
    setIsSpreadToggled(!isSpreadToggled);
  };

  const handleNeedToggleClick = () => {
    setNeedOrWant(!needOrWant);
  };

  const fetchTransactionsData = async (page, type) => {
    setLoading(true);
    try {
      let response;
      if (type === "income") {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_INCOME_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      } else if (type === "expense") {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_EXPENSE_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      } else {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_ONE_OFF_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      }

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          autoClose: 2000,
        });
      } else if (response?.data) {
        const transactionsThisMonth =
          response?.data?.data?.transactions?.thisMonth || [];
        const transactionsRemainingMonths =
          response?.data?.data?.transactions?.remainingMonths || [];

        // Append the new transactions to the previous ones
        const groupedCurrentMonth = groupTransactionsByDate(
          transactionsThisMonth
        );
        const groupedRemainingMonths = groupTransactionsByMonth(
          transactionsRemainingMonths,
          remainingMonthsGrouped
        );

        // Use functional updates to merge new transactions with previous ones
        setCurrentMonthGrouped((prev) => ({
          ...prev,
          ...groupedCurrentMonth.data,
        }));

        setRemainingMonthsGrouped((prev) => ({
          ...prev,
          ...groupedRemainingMonths.data,
        }));

        // Update totals
        setTotalIncomes(
          (prev) =>
            prev +
            groupedCurrentMonth.incomeCount +
            groupedRemainingMonths.incomeCount
        );
        setTotalExpense(
          (prev) =>
            prev +
            groupedCurrentMonth.expenseCount +
            groupedRemainingMonths.expenseCount
        );

        // Check if more data exists
        setHasMore(
          transactionsThisMonth.length > 0 ||
            transactionsRemainingMonths.length > 0
        );
      }
    } catch (error) {
      toast.error("Error fetching transactions data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uuid && page === 1) {
      fetchTransactionsData(1, expenseOrIncome);
    }
  }, [uuid, expenseOrIncome]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setPage((prev) => prev + 1);
          fetchTransactionsData(page + 1, expenseOrIncome);
        }
      }
    };
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page, expenseOrIncome]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const flattenAndFilterTransactions = (groupedTransactions) => {
    let filteredTransactions = {};

    for (let month in groupedTransactions) {
      if (Array.isArray(groupedTransactions[month])) {
        if (!search.trim()) {
          filteredTransactions[month] = groupedTransactions[month];
        } else {
          const filteredForMonth = groupedTransactions[month].filter(
            (transaction) =>
              transaction.transactionName
                ?.toLowerCase()
                .includes(search.toLowerCase())
          );

          if (filteredForMonth.length > 0) {
            filteredTransactions[month] = filteredForMonth;
          }
        }
      }
    }

    return filteredTransactions;
  };

  const filteredCurrentMonthTransactions =
    flattenAndFilterTransactions(currentMonthGrouped);
  const filteredRemainingMonthTransactions = flattenAndFilterTransactions(
    remainingMonthsGrouped
  );

  useEffect(() => {
    if (incomeState && period) {
      const dataForPeriod = incomeState[selectedTopExpensesOption] || [];
      setFilteredData(dataForPeriod);

      if (dataForPeriod.length > 0) {
        setChosenEmoji(dataForPeriod[0]?.category_emoji);
        setNeedOrWant(dataForPeriod[0]?.need_want === "Need" ? true : false);
        setIsSpreadToggled(dataForPeriod[0]?.is_spread_across_periods);
        const amount =
          dataForPeriod[0]?.category_type?.toLowerCase() === "expense"
            ? dataForPeriod[0]?.expense_amount
            : dataForPeriod[0]?.category_type?.toLowerCase() === "income"
            ? dataForPeriod[0]?.income_amount
            : dataForPeriod[0]?.amount;
        setEditAmount(
          amount !== undefined ? `$${formatAmount(Math.abs(amount))}` : ""
        );

        const startDateData =
          dataForPeriod[0]?.category_type?.toLowerCase() === "expense" ||
          dataForPeriod[0]?.category_type?.toLowerCase() === "income"
            ? dataForPeriod[0]?.start_date && dataForPeriod[0]?.end_date
              ? new Date(dataForPeriod[0].start_date).toLocaleDateString(
                  "en-CA"
                )
              : ""
            : "";
        setStartDate(startDateData);

        const endDateData =
          dataForPeriod[0]?.category_type?.toLowerCase() === "expense" ||
          dataForPeriod[0]?.category_type?.toLowerCase() === "income"
            ? dataForPeriod[0]?.start_date && dataForPeriod[0]?.end_date
              ? new Date(dataForPeriod[0].end_date).toLocaleDateString("en-CA")
              : ""
            : "";
        setEndDate(endDateData);

        const name =
          dataForPeriod[0]?.category_type?.toLowerCase() === "oneoff"
            ? dataForPeriod[0]?.name
            : "";

        setEditName(name);

        setSelectedFrequency(dataForPeriod[0]?.how_often);
        setSelectedColorOption(dataForPeriod[0]?.colour);
      }
    }
  }, [incomeState, period]);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [updateOrDeleteText, setUpdateOrDeleteText] = useState("");
  const [deleteCashFlowPopup, setDeleteCashFlowPopup] = useState(false);
  const [deleteCategoryPopup, setDeleteCategoryPopup] = useState(false);

  const handleSaveChanges = async () => {
    if (expenseOrIncome === "oneoff") {
      await updateIncomeOrExpenseBudget(true);
    } else {
      setUpdateOrDeleteText("Update");
      setShowUpdatePopup(true);
    }
  };

  function formatAmount(amount) {
    if (amount % 1 === 0) {
      return amount.toLocaleString();
    } else {
      let [integerPart, decimalPart] = amount.toFixed(2).split(".");
      return `${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  const handleDeleteBudget = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeletePopup(false);
    if (expenseOrIncome === "income") {
      // setDeleteCashFlowPopup(true);
      const payload = {
        IsDeleteCategory: false,
        isCashflow: true,
      };
      deleteIncome(payload);
    } else if (expenseOrIncome === "expense") {
      // setDeleteCashFlowPopup(true);
      const payload = {
        isCashflow: true,
      };
      deleteExpense(payload);
    } else if (expenseOrIncome === "oneoff") {
      await deleteOneOff();
      setShowUpdatePopup(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleUpdateCashflowYes = async () => {
    setShowUpdatePopup(false);
    if (updateOrDeleteText === "Delete") {
      await deleteOneOff();
    } else {
      if (expenseOrIncome === "income") {
        await updateIncomeOrExpenseBudget();
      } else if (expenseOrIncome === "expense") {
        updateExpenseBudget(true);
      }
    }
  };

  const handleUpdateCashflowNo = async () => {
    if (expenseOrIncome === "income") {
      await updateIncomeOrExpenseBudget();
    } else if (expenseOrIncome === "expense") {
      updateExpenseBudget(false);
    }
    setShowUpdatePopup(false);
  };

  const handleCashflowDeleteNo = (confirm) => {
    setDeleteCashFlowPopup(false);
    setIsCashflowDelete(confirm);
    setDeleteCategoryPopup(true);
  };

  const handleCategoryDeleteNo = (confirm) => {
    setDeleteCategoryPopup(false);
    if (expenseOrIncome === "income") {
      const payload = {
        IsDeleteCategory: confirm,
        isCashflow: isCashflowDelete,
      };
      deleteIncome(payload);
    } else if (expenseOrIncome === "expense") {
      const payload = {
        isCashflow: isCashflowDelete,
      };
      if (confirm) {
        deleteExpenseCategoryAndTransaction(payload);
      } else {
        deleteExpense(payload);
      }
    }
  };

  const updateIncomeOrExpenseBudget = async (isCashflow) => {
    try {
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const payload =
        expenseOrIncome === "income"
          ? {
              userUuid: uuid,
              incomeUuid: budgetId,
              incomeName: filteredData[0]?.income_name,
              emoji: chosenEmoji,
              colour: selectedColorOption,
              incomeAmount: formattedAmount,
              howOften: selectedFrequency,
              isCashflow: isCashflow,
              ...(selectedFrequency === "Custom" && {
                startDate: startDate || "",
                endDate: endDate || "",
              }),
              ...(selectedFrequency !== "Custom" && {
                isSpreadAcrossPeriods: isSpreadToggled ? true : false,
              }),
            }
          : {
              name: editName,
              amount: formattedAmount,
              colour: selectedColorOption,
              emoji: chosenEmoji,
            };

      const url =
        expenseOrIncome === "income"
          ? `${API_ENDPOINTS.UPDATE_INCOME_BUDGET}${uuid}/${budgetId}`
          : `${API_ENDPOINTS.UPDATE_ONE_OFF_BUDGET}${budgetId}/${uuid}`;

      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
        window.location.reload();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while updating ${expenseOrIncome} category`
      );
    }
  };

  const updateExpenseBudget = async (isCashflow) => {
    try {
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const eventIndex = category.findIndex(
        (event) => event.userCategoryId === filteredData[0]?.user_category_id
      );
      let selectedCategory;
      if (eventIndex !== -1) {
        selectedCategory = {
          ...category[eventIndex],
        };
      }

      const payload = {
        userUuid: uuid,
        expenseUuid: budgetId,
        emoji: chosenEmoji,
        colour: selectedColorOption,
        isCashflow: isCashflow,
        expenseAmount: formattedAmount,
        howOften: selectedFrequency,
        user_category_id: selectedCategory.userCategoryId,
        isDefaultCategory: filteredData[0]?.is_default_category,
        needOrWant: needOrWant ? "Need" : "Want",
        isSpreadAcrossPeriods: isSpreadToggled ? true : false,
        expenseName: selectedCategory.name,
        startDate: startDate || "",
        endDate: endDate || "",
      };
      const url = `${API_ENDPOINTS.UPDATE_EXPENSE_BUDGET}`;

      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
        // window.location.reload();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while updating ${expenseOrIncome} category`
      );
    }
  };

  const deleteIncome = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_INCOME_BUDGET}${uuid}/${budgetId}`;

      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        // toast.success(`${response?.data?.message}`);
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteExpense = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_EXPENSE_BUDGET}${uuid}/${budgetId}`;
      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        // toast.success(`${response?.data?.message}`);
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteExpenseCategoryAndTransaction = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_EXPENSE_CATEGORY_TRANSACTION_BUDGET}${uuid}/${budgetId}`;
      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        // toast.success(`${response?.data?.message}`);
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteOneOff = async () => {
    try {
      const url = `${API_ENDPOINTS.DELETE_ONE_OFF_BUDGET}${budgetId}/${uuid}`;

      const response = await HttpClient.delete(url);

      if (response?.data?.code === 1) {
        // toast.success(`${response?.data?.message}`);
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };
  const isLoading = loadingCount > 0;
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  return {
    scrollContainerRef,
    filteredData,
    handleSaveChanges,
    handleDeleteBudget,
    isPopupOpenInfo,
    togglePopupInfo,
    showDeletePopup,
    handleDeleteCancel,
    handleDeleteConfirm,
    showUpdatePopup,
    updateOrDeleteText,
    handleUpdateCashflowNo,
    handleUpdateCashflowYes,
    deleteCashFlowPopup,
    handleCashflowDeleteNo,
    deleteCategoryPopup,
    handleCategoryDeleteNo,
    expenseOrIncome,
    togglePopupTopExpenses,
    isPopupOpenTopExpenses,
    selectedTopExpensesOption,
    handleOptionTopExpensesClick,
    period,
    formatAmount,
    totalAmount,
    adjustedAmount,
    progress,
    search,
    handleSearch,
    currentMonthGrouped,
    filteredCurrentMonthTransactions,
    remainingMonthsGrouped,
    filteredRemainingMonthTransactions,
    openModal,
    category,
    updateTransactionCategory,
    addNewCategory,
    icomecat,
    oneOffCategory,
    setSelectionMode,
    togglePopover,
    popoverOpen,
    showAddCategory,
    handleAddCategory,
    editAmount,
    handleAmountChange,
    selectedFrequency,
    toggleFrequencyModal,
    isSpreadToggled,
    getButtonBackgroundColor,
    handleEditToggleClick,
    handleNeedToggleClick,
    needOrWant,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleNameChange,
    editName,
    isFrequencyModalOpen,
    selectedColorOption,
    togglePopupColor,
    isPopupOpenColor,
    handleOptionColorClick,
    setShowPicker,
    showPicker,
    chosenEmoji,
    handleEmojiClick,
    handleFrequencySelection,
    modals,
    spliModalData,
    closeModal,
    splitTransaction,
    isLoading,
    loading,
  };
};

export default useBudgetDetails;
