import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import CashFlowPopOverModal from "./CashFlowPopOverModal";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [12, 19, 3, 5, 2, 3, 10, 7, 8, 4, 7, 9],
      backgroundColor: "#FF8484",
      borderRadius: 5,
    },
    {
      label: "Dataset 2",
      data: [12, 19, 3, 5, 2, 3, 10, 7, 8, 4, 7, 9],
      backgroundColor: "#469B88",
      borderRadius: 5,
    },
  ],
};

const CashFlowBarChart = () => {
  const [selectedCashFlowOption, setSelectedCashFlowOption] =
    useState("Last 12 months");
  const [cashFlowPopOver, setCashFlowPopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectedCashFlowOption(option);
    setCashFlowPopOver(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <h4 className="fs-20 fw-500 lh-34 text-charcoal">Monthly cashflow</h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ width: "110px", height: "25px" }}
          id="CashFlowPopOver"
          onClick={() => setCashFlowPopOver(!cashFlowPopOver)}
          aria-haspopup="true"
          aria-expanded={cashFlowPopOver}
        >
          {selectedCashFlowOption}
        </span>
        {cashFlowPopOver && (
          <CashFlowPopOverModal
            isOpen={cashFlowPopOver}
            onClose={() => setCashFlowPopOver(!cashFlowPopOver)}
            targetId="CashFlowPopOver"
            title="Date range"
            options={["Last 3 months", "Last 6 months", "Last 12 months"]}
            selectedOption={selectedCashFlowOption}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>
      <div
        className="bg-white br-10 px-3 pt-3 mt-2"
        style={{ height: "415px", paddingBottom: "70px" }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h4 className="fs-16 fw-700 m-0">April 2022</h4>
          </div>

          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div
                className="rounded-circle"
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#469B88",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Money In</span>
            </div>

            <div className="d-flex align-items-center ms-3">
              <div
                className="rounded-circle"
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#FF8585",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Money Out</span>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <h4 className="fs-14 lh-20 fw-500">
            Money in:
            <span className="fs-14 lh-20 fw-400 text-slate-blue ms-1">
              $2567
            </span>
          </h4>
          <h4 className="fs-14 lh-20 fw-500 ms-3">
            Money out:
            <span className="fs-14 lh-20 fw-400 text-bright-red ms-1">
              $1890
            </span>
          </h4>
          <h4 className="fs-14 lh-20 fw-500 ms-3">
            Savings:
            <span className="fs-14 lh-20 fw-400 text-fresh-green ms-1">
              $1890
            </span>
          </h4>
        </div>
        <Bar options={options} data={data} width={577} />
      </div>
    </>
  );
};

export default CashFlowBarChart;
