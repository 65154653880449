import { useEffect, useRef, useState } from "react";

const useEmailVerificationOTP = () => {
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const inputRefs = useRef([]);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (verificationCode.length === 6) {
      // callApi(verificationCode);
      inputRefs.current = [];
    }
  }, [verificationCode]);

  const handleVerificationCodeChange = (index, e) => {
    const value = e.target.value;
    const cursorPosition = e.target.selectionStart;

    if (e.keyCode === 8 && value === "" && cursorPosition === 0) {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
        setVerificationCode((prevCode) => {
          const newCode = prevCode.slice(0, index - 1) + prevCode.slice(index);
          return newCode;
        });
      }
    } else if (/^\d$/.test(value)) {
      setVerificationCode((prevCode) => {
        const newCode =
          prevCode.slice(0, index) + value + prevCode.slice(index + 1);
        return newCode;
      });

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    } else {
      e.target.value = "";
    }
  };

  return {
    togglePopupInfo,
    isPopupOpenInfo,
    inputRefs,
    handleVerificationCodeChange,
  };
};

export default useEmailVerificationOTP;
