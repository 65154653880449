import React from "react";
import { amountMinusWithSign, amountPlusMinus } from "../../utilities/helper";

const AccountBalance = ({
  accountType,
  currency,
  convertedAmount,
  accountBalance,
  userCurrencyCode,
}) => {
  return (
    <div >
      {userCurrencyCode !== currency ? (
        <div
          className="amount-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <span
            className={`account-number ${
              convertedAmount < 0 ? "amount-red" : "amount-green"
            }`}
            style={{
              fontSize: "16px",
            }}
          >
            {accountType === "INVESTMENT" ||
            accountType === "WALLET" ||
            accountType === "FOREIGN"
              ? `${amountMinusWithSign(
                  convertedAmount,
                  userCurrencyCode
                )} ${userCurrencyCode}`
              : `${amountMinusWithSign(convertedAmount, userCurrencyCode)}`}
          </span>
          <span
            className="account-number"
            style={{
              fontSize: "14px",
              color: "#b8b8b8",
            }}
          >{`${amountMinusWithSign(
            accountBalance,
            currency
          )} ${currency}`}</span>
        </div>
      ) : (
        <span
          className={`account-number ${
            accountBalance < 0 ? "amount-red" : "amount-green"
          }`}
        >
          {accountType === "INVESTMENT" ||
          accountType === "WALLET" ||
          accountType === "FOREIGN"
            ? `${amountMinusWithSign(accountBalance, currency)} ${currency}`
            : amountPlusMinus(accountBalance)}
        </span>
      )}
    </div>
  );
};

export default AccountBalance;
