import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics";

const MonryInsightCategoryPopOver = ({
  categories = [],
  togglePopover,
  popoverOpen,
  selectedCategories,
  setSelectedCategories,
  handleTagClick,
  type,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  return (
    <div className={`modal-background ${popoverOpen ? "open-modal" : ""}`}>
      <div
        className="modal-content"
        style={{
          width: "900px",
          height: "240px",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <div className="transaction-categories">
          <div className="bold popover-live"> Transaction Category </div>
          <button
            onClick={() => togglePopover(type)}
            className="category-close-button"
          >
            <i className="fa fa-times"></i>
          </button>
          <div
            className="categories-scroll-container"
            style={{
              maxHeight: "180px",
              overflowY: "auto",
              marginTop: "10px",
              paddingRight: "0px",
            }}
          >
            <div className="categories group-btn green-btn">
              <div className="group-btn green-btn">
                <div className="button-blck" style={{ marginRight: "10px" }}>
                  <button
                    className={`all-btn ${
                      selectedCategories.length === 0 ? "green" : ""
                    }`}
                    onClick={() =>
                      setSelectedCategories(
                        selectedCategories.length === 0 ? [] : []
                      )
                    }
                  >
                    All
                  </button>
                </div>
                {categories.length > 0 &&
                  categories.map((category) => (
                    <div
                      key={category.userCategoryId}
                      className="button-blck"
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className={`icon-tag ${
                          selectedCategories.includes(category.userCategoryId)
                            ? "green"
                            : ""
                        }`}
                        onClick={() =>
                          handleTagClick(category.userCategoryId, type)
                        }
                      >
                        {category.name !== "Uncategorised" && (
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(category.colour)
                                ? ""
                                : categoryBackgroundCss(category.colour)
                            }`}
                            style={{
                              background: categoryColorForAll(category.colour),
                            }}
                          >
                            {category.emoji}
                          </div>
                        )}
                        <div className="content">
                          <h6
                            className={`icon-content ${
                              selectedCategories.includes(
                                category.userCategoryId
                              )
                                ? "icon-white"
                                : ""
                            }`}
                          >
                            {category.name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonryInsightCategoryPopOver;
