import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import Logo from "../../assests/images/dashboard/anz.svg";
import Logo1 from "../../assests/images/dashboard/anz-1.svg";
import { isEmpty } from "lodash";
import NoDataFound from "../NoDataFound";

const dummyBalanceData = [
  {
    id: 1,
    avatar: Logo,
    iconbg: "#EEEFC6",
    accountName: "Groceries",
    accountNumber: "Up 10% vs last week",
    value: 1200.87,
    active: false,
  },
  {
    id: 2,
    avatar: Logo,
    iconbg: "#EFDFC6",
    accountName: "Everyday",
    accountNumber: "Up 10% vs last week",
    value: 480.9,
    active: false,
  },
  {
    id: 3,
    avatar: Logo1,
    iconbg: "#C6D2EF",
    accountName: "Sharesies",
    accountNumber: "Up 10% vs last week",
    value: 480.9,
    active: false,
  },
  {
    id: 4,
    avatar: Logo,
    iconbg: "#C6EFD2",
    accountName: "Power & Wifi",
    accountNumber: "Up 10% vs last week",
    value: 480.9,
    active: false,
  },
  {
    id: 5,
    avatar: Logo,
    iconbg: "#F7D4CE",
    accountName: "Mortgage",
    accountNumber: "Up 10% vs last week",
    value: 480.9,
    active: false,
  },
  {
    id: 6,
    avatar: Logo,
    iconbg: "#F7D4CE",
    accountName: "Kiwisaver",
    accountNumber: "Up 10% vs last week",
    value: 349.58,
    active: false,
  },
];

const MyBalancePopOver = ({ isOpen, onClose }) => {
  const [balanceData, SetBalanceData] = useState(dummyBalanceData);
  const [isChecked, setIsChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const activeAccount = (item) => {
    SetBalanceData((prev) =>
      prev.map((account) =>
        account?.id === item?.id
          ? { ...account, active: !account.active }
          : account
      )
    );
  };

  return (
    <div className={`modal-background ${isOpen ? "open-modal" : ""}`}>
      <div className="modal-content add-modal-padding">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <div className="">
            <span className="bold black fs-26 fw-700 lh-24">
              Select your accounts
            </span>
            <p className="fs-12 fw-400">
              Select which accounts you’d like added to your total balance
            </p>
          </div>
          <div className="ms-5 d-flex align-items-center">
            <button className="border-0 text-white bg-teal-green px-4 py-2 rounded fs-14 fw-500 lh-16 me-3">
              Save changes
            </button>
            <button onClick={onClose} className="close-modal-button bg-transparent border-0 fs-24 fw-500">
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>

        <div className="row">
          {balanceData?.length > 0 ? (
            balanceData?.map((item, index) => (
              <div className={`col-md-4  p-2`}>
                <div className="p-2 w-100 shadow-lg bg-white rounded">
                  <div className="accordion-header">
                    <div>
                      <img
                        className="img-fluid"
                        style={{
                          width: "35px",
                        }}
                        src={!isEmpty(item?.avatar) ? item?.avatar : Logo}
                        alt=""
                      />
                    </div>
                    <div className="account-text mx-2">
                      <div className="account-name">{item?.accountName}</div>
                      <div className="text-fresh-green fs-14 fw-600 inter">
                        ${item?.value}
                      </div>
                    </div>
                    <div className="account-value">
                      <span className="text-muted-red fs-17 fw-700">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            style={{
                              backgroundColor: item?.active
                                ? "rgba(70, 155, 136, 1)"
                                : "rgba(255, 255, 255, 1)",
                              borderColor: item?.active
                                ? "rgba(70, 155, 136, 1)"
                                : "rgba(204, 204, 204, 1)",
                              boxShadow: isFocused
                                ? `0 0 0 0.25rem ${
                                    item?.active
                                      ? "rgba(70, 155, 136, 0.5)"
                                      : "rgba(204, 204, 204, 0.5)"
                                  }`
                                : "none",
                            }}
                            onChange={() => activeAccount(item)}
                            checked={item?.active}
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyBalancePopOver;
