import React from "react";
import "./setting.css";
import Profileimg from "../../assests/images/icons/profile.png";
import Camera from "../../assests/images/icons/cameraImg.svg";
import Profile from "../../assests/images/icons/image.png";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import { ReactComponent as Arrow } from "../../assests/images/icons/accordionArrow.svg";
import ChangePassword from "../../ModalForm/ChangePassword";
import DeleteAccount from "../../ModalForm/DeleteAccount";
import OtpModal from "../../ModalForm/Popup/OtpModal";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import EditCategoriesModal from "../../ModalForm/TransactionModals/EditCategoriesModal";
import SettingCategoryPopover from "../../ModalForm/Popup/SettingCategoryPopover";
import SubmitFeedBackDeleteAccount from "../../ModalForm/SubmitFeedBackDeleteAccount";
import useSettings from "../../hooks/Settings/useSettings/useSettings";
import {
  planMonthYear,
  selectPlanBackground,
  selectPlanBtnColor,
  selectPlanDescription,
  selectPlanNameAmount,
  selectPlanType,
} from "../../utilities/helper";
import weburls from "../../Weburls/weburls";

const ToggleSwitch = ({ value, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className={`switch-slider round`}></span>
    </label>
  );
};

const Setting = () => {
  const {
    updateUser,
    isPopupOpenInfo,
    togglePopupInfo,
    data,
    handleChange,
    fileInputRef,
    handleFileChange,
    handleIconClick,
    userDetails,
    toggle2FAVerify,
    openModal,
    handleSettingToggle,
    planDetails,
    remainingTime,
    oneTimeOTP,
    uuid,
    modals,
    setNewPassword,
    setConfirmPassword,
    setCurrentPassword,
    currentPassword,
    confirmPassword,
    newPassword,
    changePassword,
    closeModal,
    modalActionCompleted,
    revokeConfirmEmail,
    setRevokeConfirmEmail,
    revokeConfirmPassword,
    setRevokeConfirmPassword,
    deleteAccount,
    accountDeleted,
    setAccoundDeleted,
    modalsFeedback,
    closeModalFeedbacks,
    onDeleteAccountWithFeedback,
    handleOtpSubmit,
    submitOtp,
    setModalActionCompleted,
    settingCategory,
    incomeCat,
    expenseCat,
    handleCategoryClick,
    EditAddCatogory,
    editCategory,
    selectedCategory,
    editCategoryOnTap,
    setEditAddCatogory,
    deleteCategory,
    loading,
  } = useSettings();

  return (
    <>
      <div className="app-main__outer your-setting-page-container">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="col-md-6">
            <div className="fs-3 bold big-head-new">Your settings</div>
            <div className="fst-normal setting_text_color">
              Lets get the nuts and bolts sorted to optimise your account
              experience.
            </div>
          </div>
          <div className="col-md-6 right-section your-setting-page-back-portal">
            <div
              className="setting_background_color right-section"
              style={{ padding: "0px" }}
            >
              <button
                className="save-button"
                type="button"
                onClick={updateUser}
              >
                Save changes
              </button>
              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Settings ⚙️"
                  description={[
                    "Manage all your details, preferences, and security settings here.",
                    "Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.",
                    "Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ",
                    "View when your next payment is being processed and what card you’re using.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img
                    src={data?.avatar ? data?.avatar : Profile}
                    alt="profile"
                    style={{
                      height: "59px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row setting-page" style={{ marginTop: "25px" }}>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane col-xl-12 fade show active d-flex"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="col-xl-9 setting-left-section mt-4 your-details-section d-flex flex-column align-items-start">
                <div className="detail-title-flex">
                  <div className="heading bold black">
                    <div className="your-details-title fs-22 fw-500 lh-34">Your details</div>
                  </div>
                </div>
                <div className="main-section-left">
                  <form className="row1">
                    <div className="field-form col-xl-5">
                      <label className="label-text">First name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={data?.firstName}
                        className="edit-settings-input py-2 inter add-goal full-width-transaction"
                        style={{ border: "none", width: "231px" }}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="field-form col-xl-4">
                      <label className="label-text">Last name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={data?.lastName}
                        className="edit-settings-input py-2 inter add-goal full-width-transaction"
                        style={{ border: "none", width: "231px" }}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="field-form col-xl-8">
                      <label className="label-text">Your email</label>
                      <div
                        className="edit-settings-input py-2 inter add-goal"
                        style={{ width: "347px" }}
                      >
                        {data?.email}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div
                  className="profile-img-setting profile-img-margin cursor-pointer"
                  onClick={handleIconClick}
                >
                  <img
                    src={
                      data?.avatar === "" || data?.avatar === null
                        ? Profileimg
                        : data?.avatar
                    }
                    alt="profile imag"
                    style={{
                      width: "133px",
                      height: "133px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <div className="camera">
                      <img src={Camera} alt="camera" />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 section-main">
              <div className="left-flex">
                <div className="heading bold black">
                  <div className="your-details-title fs-22 fw-500 lh-34">Security</div>
                </div>
                <div className="security-main mt-2">
                  <div className="sec-left">
                    <div className="main-sec-section-left">
                      <div className="heading bold black">
                        <h3
                          className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                          style={{ fontWeight: "500" }}
                        >
                          2-Factor Authentication
                        </h3>
                        <h3
                          className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <ToggleSwitch
                                  value={
                                    userDetails?.emailVefried === "true"
                                      ? true
                                      : false
                                  }
                                  onChange={toggle2FAVerify}
                                />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="change-pass"
                      onClick={() => openModal("changePassword")}
                    >
                      <div className="heading bold black">
                        <h3 className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500">
                          Change password
                        </h3>
                        <h3
                          className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <Arrow />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="change-pass"
                      onClick={() => openModal("deleteAccount")}
                    >
                      <div className="heading bold black">
                        <h3 className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500">Delete account</h3>
                        <h3
                          className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <Arrow />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  Preferences section  */}
                <div className="heading bold black">
                  <p
                    className="your-details-title fs-22 fw-500 lh-34 mt-4"
                    style={{ marginBottom: "5px" }}
                  >
                    Preferences
                  </p>
                </div>
                <div className="sec-left " style={{ width: "50%" }}>
                  <div className="main-sec-section-left">
                    <div
                      className="heading bold black"
                      id="setting-category-popover"
                      onClick={handleSettingToggle}
                    >
                      <h3
                        className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                        style={{ fontWeight: "500" }}
                      >
                        Edit categories
                      </h3>
                      <h3
                        className="header-delete-acc-h3 d-inline-flex lh-30 fs-20 fw-500"
                        style={{ float: "right" }}
                      >
                        <p className="header-p">
                          <div className="account-number inter text-16px">
                            <div className="button-blck">
                              <Arrow />
                            </div>
                          </div>
                        </p>
                      </h3>
                    </div>
                  </div>
                </div>
                {/*  Preferences section  */}
              </div>
              <div className="payment-container">
                <div className="heading bold black">
                  <div className="your-details-title fs-22 fw-500 lh-34">Payments & billing</div>
                </div>
                <div className="sec-right d-flex flex-column align-items-center mt-2">
                  {planDetails &&
                    selectPlanType(planDetails.planType) === "Free" &&
                    remainingTime !== "" && (
                      <div className="plan-green d-inline-flex align-items-center">
                        <span className="payment-desc">
                          Free trial remaining
                        </span>
                        <div className="rem-background">
                          <span className="rem-time">{remainingTime}</span>
                        </div>
                      </div>
                    )}
                  <div
                    className={`${
                      planDetails && selectPlanBackground(planDetails.planType)
                    }`}
                  >
                    {/* <div className={`plan-standard`}> */}
                    <span className="payment-desc" style={{ fontSize: "20px" }}>
                      {planDetails &&
                        selectPlanNameAmount(
                          planDetails.planType,
                          planDetails.amount
                        )}
                      <span className="payment-monthYear">
                        {planDetails && planMonthYear(planDetails.planType)}
                      </span>
                    </span>
                    <p className="pur-plan-para">
                      {planDetails &&
                        selectPlanDescription(planDetails.planType)}
                    </p>
                    <div
                      className="update-plan"
                      style={{
                        color: `${
                          planDetails &&
                          selectPlanBtnColor(planDetails.planType)
                        }`,
                      }}
                      onClick={() =>
                        window.open(
                          `https://account.budgetbuddie.com/manageAccountLogin?userToken=${oneTimeOTP}&userUuid=${uuid}`,
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Update plan
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {modals.changePassword && (
              <ChangePassword
                modalOpen={modals.changePassword}
                setModalOpen={openModal}
                setNewPassword={setNewPassword}
                setConfirmPassword={setConfirmPassword}
                setCurrentPassword={setCurrentPassword}
                currentPassword={currentPassword}
                confirmPassword={confirmPassword}
                newPassword={newPassword}
                changePassword={changePassword}
                closeModal={closeModal}
                modalActionCompleted={modalActionCompleted}
              />
            )}
            {modals.deleteAccount && (
              <DeleteAccount
                modalOpen={modals.deleteAccount}
                setModalOpen={openModal}
                closeModal={closeModal}
                revokeConfirmEmail={revokeConfirmEmail}
                setRevokeConfirmEmail={setRevokeConfirmEmail}
                revokeConfirmPassword={revokeConfirmPassword}
                setRevokeConfirmPassword={setRevokeConfirmPassword}
                deleteAccount={deleteAccount}
                accountDeleted={accountDeleted}
                setAccoundDeleted={setAccoundDeleted}
                modalActionCompleted={modalActionCompleted}
              />
            )}
            {modalsFeedback.submitFeedBack && (
              <SubmitFeedBackDeleteAccount
                modalOpen={modalsFeedback.submitFeedBack}
                closeModal={closeModalFeedbacks}
                onDeleteAccountWithFeedback={onDeleteAccountWithFeedback}
              />
            )}
            {modals.Otp && (
              <OtpModal
                modalOpen={modals.Otp}
                setModalOpen={openModal}
                closeModal={closeModal}
                revokeConfirmEmail={revokeConfirmEmail}
                setRevokeConfirmEmail={setRevokeConfirmEmail}
                revokeConfirmPassword={revokeConfirmPassword}
                setRevokeConfirmPassword={setRevokeConfirmPassword}
                deleteAccount={deleteAccount}
                accountDeleted={accountDeleted}
                setAccoundDeleted={setAccoundDeleted}
                handleOtpSubmit={handleOtpSubmit}
                submitOtp={submitOtp}
                setModalActionCompleted={setModalActionCompleted}
                modalActionCompleted={modalActionCompleted}
              />
            )}
          </div>

          {settingCategory && (
            <SettingCategoryPopover
              incomeCat={incomeCat}
              expenseCat={expenseCat}
              targetId="setting-category-popover"
              onClose={handleSettingToggle}
              isOpen={settingCategory}
              togglePopover={handleSettingToggle}
              handleCategoryClick={handleCategoryClick}
            />
          )}

          {EditAddCatogory && (
            <EditCategoriesModal
              modalOpen={EditAddCatogory}
              editCategory={editCategory}
              transactionType={selectedCategory?.type}
              closeModal={editCategoryOnTap}
              selectedCategory={selectedCategory}
              setEditAddCatogory={setEditAddCatogory}
              deleteCategoryConfirm={deleteCategory}
            />
          )}
        </div>
        {loading && (
          <OverlayContainer>
            <div>
              <Loading />
            </div>
          </OverlayContainer>
        )}
      </div>
    </>
  );
};
export default Setting;
