import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import PopupModal from "../../ModalForm/Popup/PopupModel";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [12, 19, 3, 5, 2, 3, 10, 7, 8, 4, 7, 9],
      backgroundColor: "#FF8484",
      borderRadius: 5,
    },
    {
      label: "Dataset 2",
      data: [12, 19, 3, 5, 2, 3, 10, 7, 8, 4, 7, 9],
      backgroundColor: "#469B88",
      borderRadius: 5,
    },
  ],
};

const IncomeBarChart = () => {
  const [selectedIncomeOption, setSelectedIncomeOption] =
    useState("Last 12 months");
  const [incomePopOver, setIncomePopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectedIncomeOption(option);
    setIncomePopOver(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal">Income vs Expenses</h4>
        <span
          className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ width: "120px", height: "25px", borderRadius: "20px" }}
          id="IncomeExpenses"
          onClick={() => setIncomePopOver(!incomePopOver)}
          aria-haspopup="true"
          aria-expanded={incomePopOver}
        >
          {selectedIncomeOption}
        </span>
        {incomePopOver && (
          <PopupModal
            isOpen={incomePopOver}
            onClose={() => setIncomePopOver(!incomePopOver)}
            targetId="IncomeExpenses"
            title="Date range"
            options={["Last 3 months", "Last 6 months", "Last 12 months"]}
            selectedOption={selectedIncomeOption}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>
      <div className="bg-white br-10 px-3 pt-3 pb-5" style={{ height: "340px" }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex">
            <h4 className="fs-20 lh-20 fw-500">
              Income:
              <span className="fs-20 lh-20 fw-400 text-slate-blue">$2567</span>
            </h4>
            <h4 className="fs-20 lh-20 fw-500 ms-3">
              Expenses:
              <span className="fs-20 lh-20 fw-400 text-bright-red">$1890</span>
            </h4>
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#469B88",
                  borderRadius: "50%",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Income</span>
            </div>

            <div className="d-flex align-items-center ms-2">
              <div
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#FF8585",
                  borderRadius: "50%",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Expenses</span>
            </div>
          </div>
        </div>
        <Bar options={options} data={data} width={577} />
      </div>
    </>
  );
};

export default IncomeBarChart;
