import React from "react";
import { ToastContainer } from "react-toastify";
import Profile from "../../assests/images/icons/image.png";
import Shape from "../../assests/images/icons/shape.png";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import "./setting.css";
import useEmailVerificationOTP from "../../hooks/Settings/useEmailVerificationOTP/useEmailVerificationOTP";

const Email2FAVerifactionOTP = () => {
  const {
    togglePopupInfo,
    isPopupOpenInfo,
    inputRefs,
    handleVerificationCodeChange,
  } = useEmailVerificationOTP();

  return (
    <>
      <div className="app-main__outer your-setting-page-container">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="row">
          <div className="col-md-6">
            <div className="fs-3 bold big-head-new">
              Set up 2-factor authentication
            </div>
            <div className="fst-normal setting_text_color mt-2">
              2FA adds an extra wall of defence, ensuring that only you can
              access your BudgetBuddie account, even if someone gets hold of
              your password.
            </div>
          </div>
          <div className="col-md-6 right-section your-setting-page-back-portal">
            <div className="setting_background_color right-section">
              <div className="profile-dashboard">
                <img
                  src={Shape}
                  alt="Mortage_img"
                  onClick={togglePopupInfo}
                  style={{ width: "20px", marginLeft: "20px" }}
                />
              </div>

              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Settings ⚙️"
                  description={[
                    "Manage all your details, preferences, and security settings here.",
                    "Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.",
                    "Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ",
                    "View when your next payment is being processed and what card you’re using.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img src={Profile} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row setting-page mt-5">
          <div className="row d-flex justify-content-center align-items-center h-100 mt-5">
            <div className="col-xl-3 setting-left-section mt-4">
              <div className="main-2fa-left">
                <div className="heading-2fa bold black mt-5">
                  <h3>Enter your code</h3>
                </div>
                <div className="fst-normal setting_2fa_text_color mb-4">
                  Enter the verification code below from your authenticator app.
                </div>
                <form className="row">
                  <div className="verification-code-input no-padding">
                    {[1, 2, 3, 4, 5, 6].map((index) => (
                      <input
                        key={index}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        type="text"
                        maxLength="1"
                        className="verification-code-box"
                        placeholder=""
                        onChange={(e) => handleVerificationCodeChange(index, e)}
                        onKeyDown={(e) =>
                          handleVerificationCodeChange(index, e)
                        }
                      />
                    ))}
                  </div>
                  <div class="modal-popup-btn-wrp verification-code-input mt-5 no-padding mb-5">
                    <button
                      className="goback-btn"
                      type="button"
                      onClick={() => {}}
                    >
                      Go back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Email2FAVerifactionOTP;
