import React from "react";
import ANZICON from "../../assests/images/dashboard/anz.svg";
import ANZICON1 from "../../assests/images/dashboard/anz-1.svg";
import ANZICON2 from "../../assests/images/dashboard/juno.svg";

const dummyData = [
  {
    id: 1,
    value: "$12,000.45",
    icon: ANZICON,
    name: "Savings Account",
    color: "#022F4C",
  },
  {
    id: 2,
    value: "$350.50",
    icon: ANZICON,
    name: "Spendings Account",
    color: "#022F4C",
  },
  {
    id: 3,
    value: "$890.45",
    icon: ANZICON1,
    name: "Sharesies",
    color: "#EF496F",
  },
  {
    id: 4,
    value: "$15,657.34",
    icon: ANZICON2,
    name: "Savings Account",
    color: "#6D3C92",
  },
  {
    id: 5,
    value: "$350.50",
    icon: ANZICON,
    name: "Spendings Account",
    color: "#022F4C",
  },
  {
    id: 6,
    value: "$890.45",
    icon: ANZICON1,
    name: "Sharesies",
    color: "#EF496F",
  },
];

const AccountBalancesCards = () => {
  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <h4 className="fs-18 fw-500 lh-34 text-charcoal">
              Account balances
            </h4>
            <span
              className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
              style={{ width: "93px", height: "25px" }}
            >
              See all
            </span>
          </div>
        </div>

        {dummyData?.map((item) => {
          return (
            <>
              <div className="col-md-3 mb-4">
                <div
                  className="bg-white"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      padding: "8px 18px",
                    }}
                  >
                    <div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-1 fs-12 fw-400 inter text-dark-gray">
                            {item?.name}
                          </p>
                          <h1
                            className="fs-22 fw-700 lh-30 "
                            style={{ color: item?.color }}
                          >
                            {item?.value}
                          </h1>
                        </div>
                        <div className="">
                          <img
                            src={item?.icon}
                            alt="ANZ Logo"
                            className="account-logo"
                          />
                        </div>
                      </div>
                      <p className="fs-10 fw-400 inter lh-15 text-dark-gray">
                        Account number
                      </p>
                      <p className="fs-10 fw-400 inter lh-15 text-dark-gray">
                        1234 3049 4567 3456
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      backgroundColor: item?.color,
                      padding: "8px 18px",
                    }}
                  >
                    <p className="text-light-gray fs-10 fw-400 inter m-0">
                      Name
                    </p>
                    <p className="white-text fs-12 fw-600 inter m-0">
                      Tim Preston
                    </p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default AccountBalancesCards;
