import React from "react";
import "./BudgetDetails.css";
import { ReactComponent as OverBudget } from "../../assests/images/over_budget.svg";
import PersonalisedColorEmoji from "./PersonalisedColorEmoji";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import CategoryBreakdownSection from "../Moneyinsights/CategoryBreakdownSection";
import EditDetails from "../Innerpanel/BudgetSummery/IncomeExpenseBudget/EditDetails.jsx";
import SectionTitle from "../SectionTitle.jsx";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { TransactionListing } from "../../Views/Transactions/TransactionListing.jsx";
import OverlayContainer from "../OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../Loading/Loading.jsx";
import { isEmpty } from "lodash";
import { motion } from "framer-motion";
import NoDataFound from "../NoDataFound/index.jsx";
import {
  getCustomCodeToName,
  lightBackgroundColor,
  moduleInfoPopUpData,
  progressbarColor,
} from "../../utilities/Statics/index.jsx";
import {
  PopUpDelete,
  PopUpForUpdateDelete,
  PopUpNoYes,
  PopUpYesNoRow,
} from "./PopUpForUpdateDelete.jsx";
import HowOften from "../Innerpanel/BudgetSummery/IncomeExpenseBudget/HowOften.jsx";
import UserImageInfoToggle from "../UserImageInfoToggle/index.jsx";
import { ReactComponent as DoneWithBackground } from "../../assests/images/doneWithBackground.svg";
import useBudgetDetails from "../../hooks/Budget/useBudgetDetails/useBudgetDetails.jsx";

const BudgetDetails = () => {
  const {
    scrollContainerRef,
    filteredData,
    handleSaveChanges,
    handleDeleteBudget,
    isPopupOpenInfo,
    togglePopupInfo,
    showDeletePopup,
    handleDeleteCancel,
    handleDeleteConfirm,
    showUpdatePopup,
    updateOrDeleteText,
    handleUpdateCashflowNo,
    handleUpdateCashflowYes,
    deleteCashFlowPopup,
    handleCashflowDeleteNo,
    deleteCategoryPopup,
    handleCategoryDeleteNo,
    expenseOrIncome,
    togglePopupTopExpenses,
    isPopupOpenTopExpenses,
    selectedTopExpensesOption,
    handleOptionTopExpensesClick,
    period,
    formatAmount,
    totalAmount,
    adjustedAmount,
    progress,
    search,
    handleSearch,
    currentMonthGrouped,
    filteredCurrentMonthTransactions,
    remainingMonthsGrouped,
    filteredRemainingMonthTransactions,
    openModal,
    category,
    updateTransactionCategory,
    addNewCategory,
    icomecat,
    oneOffCategory,
    setSelectionMode,
    togglePopover,
    popoverOpen,
    showAddCategory,
    handleAddCategory,
    editAmount,
    handleAmountChange,
    selectedFrequency,
    toggleFrequencyModal,
    isSpreadToggled,
    getButtonBackgroundColor,
    handleEditToggleClick,
    handleNeedToggleClick,
    needOrWant,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleNameChange,
    editName,
    isFrequencyModalOpen,
    selectedColorOption,
    togglePopupColor,
    isPopupOpenColor,
    handleOptionColorClick,
    setShowPicker,
    showPicker,
    chosenEmoji,
    handleEmojiClick,
    handleFrequencySelection,
    modals,
    spliModalData,
    closeModal,
    splitTransaction,
    isLoading,
    loading,
  } = useBudgetDetails();

  return (
    <div
      className="app-main__outer edit-cashflow-container-main"
      ref={scrollContainerRef}
    >
      <div className="row top-nav">
        <div className="col-md-4 edit-cashflow-title-heading">
          <div className="bold big-head-new">
            {filteredData[0]?.category_name} budget
          </div>
          <div className="fst-normal setting_text_color mt-1">
            Make any changes to personalise your budget.
          </div>
        </div>
        <div className="col-md-8 right-section back-portal-edit-cashflow">
          <div className="right-section" style={{ gap: "30px" }}>
            <div className="modal-actions" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="save-button-budget-detail"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
              <button
                type="button"
                className="next-button-budget-detail"
                onClick={handleDeleteBudget}
              >
                Delete budget
              </button>
            </div>
            <div className="">
              <UserImageInfoToggle
                isPopupOpenInfo={isPopupOpenInfo}
                togglePopupInfo={togglePopupInfo}
                title={moduleInfoPopUpData.connectAccounts.title}
                description={moduleInfoPopUpData.connectAccounts.description}
                customStyle={true}
              />
            </div>
          </div>
        </div>
        {showDeletePopup && (
          <PopUpDelete
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        )}

        {showUpdatePopup && (
          <PopUpForUpdateDelete
            updateOrDeleteText={updateOrDeleteText}
            handleUpdateCashflowNo={handleUpdateCashflowNo}
            handleUpdateCashflowYes={handleUpdateCashflowYes}
          />
        )}

        {deleteCashFlowPopup && (
          <PopUpNoYes
            title={"Delete from cashflow?"}
            subtitle={"Would you like to delete this from your cashflow too?"}
            handleChange={handleCashflowDeleteNo}
          />
        )}

        {deleteCategoryPopup && (
          <PopUpYesNoRow
            title={"Delete category?"}
            subtitle={
              "Along with deleting the budget, would you like to delete the category used for this budget which will remove it from all merchants and transactions?"
            }
            handleChange={handleCategoryDeleteNo}
          />
        )}
      </div>

      {/* Expense Form */}

      <div className="row mt-4">
        {/* Income Budgets Section */}
        <div className="col-md-8">
          <div className="col-md-11" style={{ width: "97%" }}>
            {expenseOrIncome === "oneoff" ? (
              <div className="">
                <h2
                  style={{
                    marginBottom: "15px",
                    fontSize: "20px",
                    fontweight: "500",
                  }}
                >
                  One off budget
                </h2>
              </div>
            ) : (
              <CategoryBreakdownSection
                togglePopupTopExpenses={togglePopupTopExpenses}
                isPopupOpenTopExpenses={isPopupOpenTopExpenses}
                selectedTopExpensesOption={selectedTopExpensesOption}
                handleOptionTopExpensesClick={handleOptionTopExpensesClick}
                heading={
                  expenseOrIncome === "expense"
                    ? "Spend vs budget"
                    : "Income vs budget"
                }
                period={period}
                howOften={filteredData && filteredData[0]?.how_often}
                isSpreadAcrossPeriods={
                  filteredData && filteredData[0]?.is_spread_across_periods
                }
              />
            )}
            <div className="budget-item-card">
              <div className="budget-item">
                <div className="budget-label">
                  <div className="pay-budget-sec">
                    <div
                      className={`budget-icon }`}
                      style={{
                        backgroundColor: lightBackgroundColor(
                          getCustomCodeToName(filteredData[0]?.colour ?? "#FFF")
                        ),
                      }}
                    >
                      {filteredData[0]?.category_emoji}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "7px",
                        marginLeft: "5px",
                      }}
                    >
                      <h6>{filteredData[0]?.category_name}</h6>
                      <h4 style={{ marginTop: "-5px" }}>
                        ${formatAmount(Math.abs(totalAmount))}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="budget-remaining">
                  {expenseOrIncome === "oneoff" ? (
                    `$${formatAmount(Math.abs(adjustedAmount))} remaining`
                  ) : (
                    <>
                      {adjustedAmount < 0 ? (
                        filteredData[0]?.category_type === "INCOME" ? (
                          <>
                            {`You've earned $${formatAmount(
                              Math.abs(adjustedAmount)
                            )} more`}
                            <DoneWithBackground
                              style={{
                                marginTop: "4px",
                                marginLeft: "3px",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {`$${formatAmount(
                              Math.abs(adjustedAmount)
                            )} over budget`}
                            <OverBudget
                              style={{
                                marginLeft: "1px",

                                marginTop: "2px",
                              }}
                            />
                            {/* Display the icon */}
                          </>
                        )
                      ) : (
                        `$${formatAmount(Math.abs(adjustedAmount))} remaining`
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`budget-progress-container`}
                  style={{
                    backgroundColor: lightBackgroundColor(
                      getCustomCodeToName(filteredData[0]?.colour ?? "#FFF")
                    ),
                  }}
                >
                  <motion.div
                    className={`budget-progress`}
                    style={{
                      width: `${progress}%`,
                      backgroundColor: progressbarColor(
                        getCustomCodeToName(filteredData[0]?.colour)
                      ),
                    }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></motion.div>
                </div>
              </div>
            </div>
            <div className="search-spacing filter-merchants-wrp-side mt-4">
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Transactions"} />
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search search-color-white"
                          onChange={(e) => handleSearch(e)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="account-detail-left-wrapper mt-3">
                {!isEmpty(currentMonthGrouped) ||
                !isEmpty(remainingMonthsGrouped) ? (
                  <TransactionListing
                    data={{
                      ...filteredCurrentMonthTransactions,
                      ...filteredRemainingMonthTransactions,
                    }}
                    modalOpen={openModal}
                    expenseCategories={category}
                    updateTransactionCategory={updateTransactionCategory}
                    addNewCategory={addNewCategory}
                    incomeCategories={icomecat}
                    oneOffCategory={oneOffCategory}
                    setSelectionMode={setSelectionMode}
                    isAccountModule={true}
                    // isSelectionMode={isSelectionMode}
                    // setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  />
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <EditDetails
            togglePopover={togglePopover}
            updateTransactionCategory={updateTransactionCategory}
            popoverOpen={popoverOpen}
            showAddCategory={showAddCategory}
            handleAddCategory={handleAddCategory}
            amount={editAmount}
            handleAmountChange={handleAmountChange}
            selectedFrequency={selectedFrequency}
            toggleFrequencyModal={toggleFrequencyModal}
            isSpreadToggled={isSpreadToggled}
            getButtonBackgroundColor={getButtonBackgroundColor}
            handleToggleClick={handleEditToggleClick}
            handleNeedToggleClick={handleNeedToggleClick}
            needOrWant={needOrWant}
            incomeExpenseBudgetsName={filteredData[0]?.category_type
              ?.trim()
              .toLowerCase()}
            incomeExpenseBudgetsData={filteredData[0]}
            showCategory={false}
            expenseOrIncome={expenseOrIncome}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            autoExpense={false}
            handleNameChange={handleNameChange}
            editName={editName}
            isFrequencyModalOpen={isFrequencyModalOpen}
            EditDetails={true}
          />

          <PersonalisedColorEmoji
            selectedColorOption={selectedColorOption}
            togglePopupColor={togglePopupColor}
            isPopupOpenColor={isPopupOpenColor}
            handleOptionColorClick={handleOptionColorClick}
            setShowPicker={setShowPicker}
            showPicker={showPicker}
            chosenEmoji={chosenEmoji}
            handleEmojiClick={handleEmojiClick}
          />
        </div>
      </div>

      {/* Frequency Selection Modal */}

      {isFrequencyModalOpen && (
        <HowOften
          isOpen={isFrequencyModalOpen}
          targetId="frequencyPickerButton"
          onClose={toggleFrequencyModal}
          toggleFrequencyModal={toggleFrequencyModal}
          handleFrequencySelection={handleFrequencySelection}
        />
      )}
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
        />
      )}
      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </div>
  );
};

export default BudgetDetails;
