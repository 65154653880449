import React, { useState } from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";

const topExpenses = [
  {
    id: 1,
    name: "Groceries",
    price: "$3,000",
    bg: "#DD7802",
  },
  {
    id: 2,
    name: "Car",
    price: "$2,000",
    bg: "#469B88",
  },
  {
    id: 3,
    name: "Power",
    price: "$1,000",
    bg: "#377CC8",
  },
  {
    id: 4,
    name: "Power",
    price: "$1,000",
    bg: "#EED868",
  },
  {
    id: 5,
    name: "Clothes",
    price: "$1,000",
    bg: "#E78C9D",
  },
];

const TopExpenses = () => {
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] =
    useState("This Week");

  const [expensesPopOver, setExpensesPopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectedTopExpensesOption(option);
    setExpensesPopOver(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal">Top 5 expenses</h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ width: "93px", height: "25px", borderRadius: "20px" }}
          id="TopExpensesPopover"
          onClick={() => setExpensesPopOver(!expensesPopOver)}
          aria-haspopup="true"
          aria-expanded={expensesPopOver}
        >
          {selectedTopExpensesOption}
        </span>

        {expensesPopOver && (
          <PopupModal
            isOpen={expensesPopOver}
            onClose={() => setExpensesPopOver(!expensesPopOver)}
            targetId="TopExpensesPopover"
            title="Date range"
            options={[
              "This week",
              "This month",
              "This year",
              "Last week",
              "Last month",
            ]}
            selectedOption={expensesPopOver}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>
      <div className="row g-2">
        {topExpenses?.map((item) => {
          return (
            <div className="col-md-6">
              <div
                className="br-10 d-flex justify-content-center align-items-center flex-column p-2 "
                style={{ backgroundColor: item?.bg, height: "107px" }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span className="d-flex justify-content-center align-items-center w-32 h-32 bg-white rounded-circle p-2 border-none mx-2 text-dark-gray">
                    {item?.id}
                  </span>
                  <span className="fs-12 fw-400 lh-18 white-text">
                    Groceries
                  </span>
                  <h3 className="fs-18 fw-700 lh-25 white-text">$3,000</h3>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TopExpenses;
