import React, { Fragment } from "react";

export const BankConnectionList = ({
  title,
  connections,
  onIconClick,
  style,
}) => {
  return (
    <Fragment>
      {title && <div className="bold black connection-header">{title}</div>}
      <div className="bank-connection" style={style} onClick={onIconClick}>
        {connections?.map((src, index) => (
          <div key={index} className="bank-connection-img">
            {src}
          </div>
        ))}
      </div>
    </Fragment>
  );
};
