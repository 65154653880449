import React, { useState } from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";
import { useParams } from "react-router-dom";
import useExpenseCard from "../../hooks/DashboardCards/useExpenseCard";
import useIncomeCard from "../../hooks/DashboardCards/useIncomeCard";

const IncomeExpenses = () => {
  // states
  const [selectedExpensesOption, setSelectedExpensesOption] =
    useState("This Week");
  const [selectedIncomeOption, setSelectedIncomeOption] = useState("This Week");
  const [incomePopOver, setIncomePopOver] = useState(false);
  const [expensesPopOver, setExpensesPopOver] = useState(false);

  //params
  const { uuid } = useParams();

  // hooks
  const { data, loading, error } = useIncomeCard({
    selectedIncomeOption,
    uuid,
  });
  const {
    data: expenseData,
    loading: loadingExpense,
    error: errorExpense,
  } = useExpenseCard({
    selectedExpensesOption,
    uuid,
  });

  console.log(expenseData, "expenseData");

  const handleIncomeClick = (option) => {
    setSelectedIncomeOption(option);
    setIncomePopOver(false);
  };
  const handleExpensesClick = (option) => {
    setSelectedExpensesOption(option);
    setExpensesPopOver(false);
  };

  return (
    <>
      <div class="col-md-3">
        <div className="br-10 bg-white h-100 d-flex align-content-between flex-wrap px-3 py-2 ">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <h4 className="fs-20 fw-500 lh-34 text-charcoal">Income</h4>
            <div className="dropdown">
              <span
                className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                style={{ width: "93px", height: "25px", borderRadius: "20px" }}
                id="Popover1"
                onClick={() => setIncomePopOver(!incomePopOver)}
                aria-haspopup="true"
                aria-expanded={incomePopOver}
              >
                {selectedIncomeOption}
              </span>
              {incomePopOver && (
                <PopupModal
                  isOpen={incomePopOver}
                  onClose={() => setIncomePopOver(!incomePopOver)}
                  targetId="Popover1"
                  title="Date range"
                  options={[
                    "This week",
                    "This month",
                    "This year",
                    "Last weak",
                    "Last month",
                  ]}
                  selectedOption={selectedIncomeOption}
                  handleOptionClick={handleIncomeClick}
                />
              )}
            </div>
          </div>

          <div className="">
            <h3 className="fs-32 fw-500 lh-34">
              ${data?.data ? data?.data : 0}
            </h3>
            <span className="fs-15 fw-400 lh-34 text-medium-gray">
              This weeks income
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div className="br-10 bg-white h-100 d-flex align-content-between flex-wrap px-3 py-2 ">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <h4 className="fs-20 fw-500 lh-34 text-charcoal">Expenses</h4>
            <span
              id="Popover2"
              className="d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
              style={{ width: "93px", height: "25px", borderRadius: "20px" }}
              onClick={() => setExpensesPopOver(!expensesPopOver)}
              aria-haspopup="true"
              aria-expanded={expensesPopOver}
            >
              {selectedExpensesOption}
            </span>
            {expensesPopOver && (
              <PopupModal
                isOpen={expensesPopOver}
                onClose={() => setExpensesPopOver(!expensesPopOver)}
                targetId="Popover2"
                title="Date range"
                options={[
                  "This week",
                  "This month",
                  "This year",
                  "Last weak",
                  "Last month",
                ]}
                selectedOption={selectedExpensesOption}
                handleOptionClick={handleExpensesClick}
              />
            )}
          </div>

          <div className="">
            <h3 className="fs-32 fw-500 lh-34">
              ${expenseData?.data ? expenseData?.data : 0}
            </h3>
            <span className="fs-15 fw-400 lh-34 text-medium-gray">
              This weeks expenses
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeExpenses;
