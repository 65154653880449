import React from "react";
import { ReactComponent as Logo } from "../../../assests/images/icons/akahulogo.svg";

const BodyAkahuConnection = ({ formRef, counter }) => {
  return (
    <div className="not-found-container">
      <div className="container-fluid h-custom">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "90%" }}
        >
          <div
            className="col-md-8 col-lg-6 col-xl-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loginFormWrp" ref={formRef}>
              <p className="text-center mb-1 mt-2 LoginText">
                We’re loading in your accounts & transactions 💸
              </p>
              <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                <span className="notFoundContainer">
                  Won’t be long, we’re just tidying up your loose change &
                  counting your pennies.
                </span>
              </div>
              <Logo />
              <form>
                <div className="text-center pt-2">
                  <button
                    type="submit"
                    className="comn-btn akahu-model-btn"
                    style={{ marginBottom: "20px" }}
                  >
                    {`Finishing in ${counter} seconds`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyAkahuConnection;
