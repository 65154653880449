import React from "react";
import "./Moneyinsights.css";
import { getDisplayOption } from "../../utilities/Statics";
import PopupModalDialog from "./PopupModalDialog";

const CategoryBreakdownSection = ({
  togglePopupTopExpenses,
  isPopupOpenTopExpenses,
  selectedTopExpensesOption,
  handleOptionTopExpensesClick,
  heading,
}) => {
  const options = [
    "This week",
    "This month",
    "This quarter",
    "This year",
    "Last week",
    "Last month",
    "Last quarter",
    "Custom date range"
  ];

  return (
    <div className="row">
      <div
        className="expense-summary-section mt-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "2px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              fontSize: "22px",
              fontweight: "500",
              marginBottom: "4px",
            }}
          >
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="">
            <button
              className="group-button green"
              onClick={togglePopupTopExpenses}
              aria-haspopup="true"
              aria-expanded={isPopupOpenTopExpenses}
              id="Open_Popup_Modal"
            >
              {getDisplayOption(selectedTopExpensesOption)}
            </button>
            {isPopupOpenTopExpenses && (
              <PopupModalDialog
                targetId="Open_Popup_Modal"
                isOpen={isPopupOpenTopExpenses}
                onClose={togglePopupTopExpenses}
                title="Date Range"
                options={options}
                selectedOption={getDisplayOption(selectedTopExpensesOption)}
                handleOptionClick={handleOptionTopExpensesClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBreakdownSection;
