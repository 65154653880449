import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import weburls from "../../Weburls/weburls.jsx";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../utilities/helper.jsx";
import { TransactionListing } from "../Transactions/TransactionListing.jsx";

import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal.jsx";
import useDebounce from "../../hooks/useDebouce.jsx";
import {
  categoryBackgroundCss,
  categoryColorForAll,
  dateOptions,
} from "../../utilities/Statics/index.jsx";
import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";
import ListingCategories from "../Transactions/ListingCategories.jsx";
const formatAmount = (amount) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
const MerchantDetail = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const location = useLocation();
  const {
    merchantName,
    merchantLogo,
    merchantUuid,
    merchantCategoryName,
    merchantCategoryEmoji,
    merchantCategoryColour,
    merchantAmount,
    merchantCount,
  } = location.state || {};
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [categoryName, setCategoryName] = useState(merchantCategoryName);
  const [categoryEmoji, setCategoryEmoji] = useState(merchantCategoryEmoji);
  const [categoryColor, setCategoryColor] = useState(merchantCategoryColour);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [selectAll, setSelectAll] = useState(false);
  const [modals, setModals] = useState({
    viewTransaction: false,
  });
  const [popoverOpen, setPopoverOpen] = useState({});

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*"); 
    }
  }, [uuid, userId, navigate]); 

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const togglePopover = (transactionId, amount) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
      setLoading(false);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const updateCategoriesInBulk = async (categoryId, type) => {
    setLoading(true);
    const data = {
      expenseTransactionUuids: [...selectedForBulkUpdate.Expense],
      incomeTransactionUuids: [...selectedForBulkUpdate.Income],
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category_In_Bulk}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      setSelectionMode(false);
      setSelectAll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTransactions = useCallback(async () => {
    // setLoading(true);

    try {
      const response = await axios.get(
        `${weburls.Get_Transaction_By_Merchants_Id}${uuid}/${merchantUuid}/1000/1`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );

      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
    }
  }, [
    uuid,
    selectedExpenseCategories,
    selectedIncomeCategories,
    debouncedSearch,
    filterByDate,
  ]);

  useEffect(() => {
    getCategory();
    fetchTransactions();
  }, [getCategory]);

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await axios.post(`${weburls.Add_Transaction}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactions();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      // toast.success(`${response?.data?.message}`);
      fetchTransactions();
    } catch (error) {}
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchTransactions();
    } catch (error) {}
  };

  const updateMerchantCategory = async (transactionId, categoryId, type) => {
    const data = {
      merchantUuid: transactionId,
      categoryId: categoryId,
    };

    try {
      const response = await axios.post(
        `${weburls.Update_Merchant_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      const filtered = category.filter(
        (ct) => ct.userCategoryId === categoryId
      );
      if (filtered.length > 0) {
        setCategoryName(filtered[0].name);
        setCategoryEmoji(filtered[0].emoji);
        setCategoryColor(filtered[0].colour);
      }
      fetchTransactions();
    } catch (error) {}
  };

  const splitbackToMainTransaction = async (transactionUUid) => {
    
    try {
      const response = await axios.post(
        `${weburls.Split_Back_To_Main_Transaction}${uuid}/${transactionUUid}`, {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction")
      fetchTransactions();
    } catch (error) {}
  };

  const splitSingleBackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Single_Back_To_Main_Transaction}${uuid}/${transactionUUid}`, {},
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction")
      fetchTransactions();
    } catch (error) {}
  };

  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  return (
    <>
      <div className="app-main__outer your-merchants-main-wrapper">
        <div className="row">
          <PageTitle
            title={merchantName}
            description={
              "Here’s your merchants details, including all the transactions for your merchant."
            }
          />
          <UserImageInfoToggle />
        </div>
        <div className="d-flex gap-4 mt-5">
          <div className="account-detail-left">
            <div className="d-flex align-items-center justify-content-between">
              <SectionTitle title={"Transactions"} />
            </div>
            <div className="account-detail-left-wrapper">
              {!isEmpty(currentMonthGrouped) ||
              !isEmpty(remainingMonthsGrouped) ? (
                <TransactionListing
                  data={{ ...currentMonthGrouped, ...remainingMonthsGrouped }}
                  modalOpen={openModal}
                  expenseCategories={category}
                  updateTransactionCategory={updateTransactionCategory}
                  addNewCategory={addNewCategory}
                  incomeCategories={icomecat}
                  oneOffCategory={oneOffCategory}
                  setSelectionMode={setSelectionMode}
                  isSelectionMode={isSelectionMode}
                  setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  selectedForBulkUpdate={selectedForBulkUpdate}
                  updateCategoriesInBulk={updateCategoriesInBulk}
                  categoryTitle={'Merchant categories'}
                  categorySubTitle={'Category'}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
          <div className="account-detail-right sticky-sidebar">
            <div className="search-spacing filter-merchants-wrp-side">
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Merchant"} />
                  <div>
                    <div className="selected-account ">
                      <div className="merchants-inner-wrp"></div>
                      <div className="merchant-box ">
                        <div className="d-flex w-60">
                          <div
                            className="img-box"
                            style={{ marginRight: "5px" }}
                          >
                            <img
                              src={merchantLogo}
                              alt=""
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div className="ms-3">
                            <div
                              className="text-merchant bold black"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                marginBottom: "0px",
                              }}
                            >
                              {merchantName}
                            </div>
                            {/* Updated Amount and Transaction Count Section */}
                            <div
                              className="small-text-merchant"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                fontSize: "14px",
                              }}
                            >
                              <span
                                className={
                                  merchantAmount < 0
                                    ? "number-merchant"
                                    : "expense-amount"
                                }
                                style={{
                                  color:
                                    merchantAmount < 0 ? "#D04F4F" : "#40AF66",
                                  marginRight: "0px",
                                }}
                              >
                                {formatAmount(merchantAmount)}
                              </span>
                              <span className="black">|</span>
                              <span
                                className="setting_text_color-2"
                                style={{
                                  color: "#9B9B9B",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                {merchantCount}{" "}
                                {Number(merchantCount) === 1
                                  ? "Transaction"
                                  : "Transactions"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="button-blck">
                          <div
                            className="icon-tag"
                            id={`Popover-${merchantUuid}`}
                            style={{ marginBottom: "0px" }}
                          >
                            {categoryName !== "Uncategorised" && (
                              <div
                                className={`icon-transaction-img ${
                                  isHexColor(categoryColor)
                                    ? ""
                                    : categoryBackgroundCss(categoryColor)
                                }`}
                                style={{
                                  background: categoryColorForAll(categoryColor),
                                }}
                              >
                                {categoryEmoji}
                              </div>
                            )}
                            <div className="content">
                              <h6 className="icon-content">{categoryName}</h6>
                            </div>
                          </div>
                          <ListingCategories
                            categories={category}
                            updateTransactionCategory={updateMerchantCategory}
                            transactionId={merchantUuid}
                            togglePopover={togglePopover}
                            popoverOpen={popoverOpen}
                            transactionType={"Merchant"}
                            isNewCategoryPopup={false}
                            isEditButton = {false}
                            categoryPopoverHeading={"Merchant categories"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          // accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          updateSingleCategory={updateSingleCategory}
          splitbackToMainTransaction={splitbackToMainTransaction}
          splitSingleBackToMainTransaction={splitSingleBackToMainTransaction}
          categoryTitle={'Merchant categories'}
          categorySubTitle={'Category'}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default MerchantDetail;
